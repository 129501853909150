import React from "react";
import { getCalendar } from "../../actions/divers";
import { connect } from "react-redux";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import allLocales from '@fullcalendar/core/locales-all';
import Alert from "../../components/Alert";

class Calendar extends React.Component {
    state = {
        alert: {
            active: true,
            type: 'warning',
            title: 'Recherche de données...',
            msg: 'Recherche des prochains évènements en cours...'
        },
        events: []
    }

    componentDidMount() {
        document.title = 'Tof La Win - Calendrier';
        this.props.getCalendar();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.divcal != undefined && 
            this.props.divcal != prevProps.divcal
        ) {
            if (this.props.divcal.alert != this.state.alert) {
                this.setState({alert: this.props.divcal.alert});
            }

            let events = [];
            if (
                this.props.divcal != undefined &&
                this.props.divcal.datas != undefined
            ) {
                this.props.divcal.datas.forEach(ev => {
                    let date;
                    let tempDate;
                    let dateActu = new Date();
                    if (ev.color != "#DB544F") {
                        tempDate  = new Date(ev.start_at?.date);
                        let tempMonth = tempDate.getMonth() + 1;
    
                        date = tempDate.getFullYear();
                        date += "-";
                        date += (tempMonth < 10 ? "0" : "") + tempMonth;
                        date += "-";
                        date += (tempDate.getDate() < 10 ? "0" : "") + tempDate.getDate();
                    } else {
                        tempDate  = new Date(ev.start_at);
                        let tempMonth = tempDate.getMonth() + 1;
    
                        date = dateActu.getFullYear();
                        date += "-";
                        date += (tempMonth < 10 ? "0" : "") + tempMonth;
                        date += "-";
                        date += (tempDate.getDate() < 10 ? "0" : "") + tempDate.getDate();
                    }
                    events.push({
                        title: ev.titre,
                        date: date,
                        color: ev.color
                    })
                })
            }

            this.setState({events: events})
        }
    }

    render() {
        return <main className="page page--calendar">
            <h2 className="page__ttl">Évènements</h2>
            {
                this.state.alert.active ?
                    <Alert class={`alert--${this.state.alert.type}`} ttl={this.state.alert.title}>{this.state.alert.msg}</Alert> : ""
            }
            <section className="page__calendar">
                <FullCalendar
                    plugins={[ dayGridPlugin, timeGridPlugin ]}
                    initialView="dayGridMonth"
                    weekends={false}
                    events={this.state.events}
                    headerToolbar={{
                        start: "today prev,next",
                        center: "title",
                        end: "dayGridMonth,timeGridWeek,timeGridDay"
                    }}
                    locale='fr'
                    locales={allLocales}
                    />
            </section>
        </main>
    }
}

const mapStateToProps = state => {
    return {
        divcal: state.divcal
    }
}

const mapDispatchToProps = {
    getCalendar
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);