import React from "react";
import iconSet from "../../selection.json";
import IcomoonReact from "icomoon-react";
import Credit from "../Credits";
import { getLwGrilles } from "../../actions/lotowin";
import { connect } from "react-redux";

class LWGrilles extends React.Component {
    state = {
        grilles: [],
        selType: "tous",
        selDate: "tous",
        dates: []
    }

    componentDidMount() {
        // Création des 20 dernières dates
        let date = new Date();
        let dates = [];

        let year = date.getFullYear();
        let month = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let theDate = day + "/" + month + "/" + year;
        dates.push(theDate)
        for (let i = 0; i < 19; i++) {
            theDate = this.removeOneDay(theDate);
            year = theDate.getFullYear();
            month = (theDate.getMonth() + 1) < 10 ? "0" + (theDate.getMonth() + 1) : (theDate.getMonth() + 1);
            day = theDate.getDate() < 10 ? "0" + theDate.getDate() : theDate.getDate();
            theDate = day + "/" + month + "/" + year;
            dates.push(theDate);
        }
        this.setState({dates: dates})

        // Obtenir les grilles
        this.props.getLwGrilles();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.grilles.grilles != this.state.grilles && this.props.grilles != undefined) {
            this.setState({ grilles: this.props.grilles.grilles });
        }
    } 

    handleChangeSelec(value, type) {
        if (type == "type") {
            this.setState({selType: value.target.value});
        }
        if (type == "date") {
            this.setState({selDate: value.target.value});
        }
    }

    removeOneDay(date) {
        let dateArray = date.split('/');
        let dateObject = new Date(parseInt(dateArray[2]), parseInt(dateArray[1]) - 1, parseInt(dateArray[0] - 1));

        return dateObject;
    }

    render() {
        return <section className="lw__gr lw__mod">
            <h2 className={`lw__fg__ttl`}>
                Vos grilles
            </h2>
            <header className="lw__gr__header">
                <select value={this.state.selType} onChange={el => this.handleChangeSelec(el, "type")} className="lw__gr__sel">
                    <option value="tous">Tout les types</option>
                    <option value="super">Super</option>
                    <option value="maxi">Maxi</option>
                    <option value="mega">Mega</option>
                    <option value="extra">Extra</option>
                </select>
                <select value={this.state.selDate} onChange={el => this.handleChangeSelec(el, "date")} className="lw__gr__sel">
                    <option value="tous">Toutes les dates</option>
                    {
                        this.state.dates.map(date => <option value={date}>
                            {date}
                        </option>)
                    }
                </select>
            </header>
            <ul className="lw__gr__list">
                {
                    // todo ajouter filtre
                    this.state.grilles.length > 0 ?
                        (this.state.grilles
                            .filter(grille => {
                                let pass = true;
                                // Filtre date
                                if (this.state.selDate != "tous") {
                                    pass = false;
                                    let dateSelecSep = this.state.selDate.split('/');
                                    let newDate = dateSelecSep[2] + "-" + dateSelecSep[1] + "-" + dateSelecSep[0];
        
                                    if (grille.date == newDate) {
                                        pass = true;
                                    }
                                }
                                if (pass != false && this.state.selType != "tous") {
                                    pass = false;
                                    if (grille.type == this.state.selType) {
                                        pass = true;
                                    }
                                }

                                return pass;
                            }).length > 0 ?
                                this.state.grilles
                                .filter(grille => {
                                    let pass = true;
                                    // Filtre date
                                    if (this.state.selDate != "tous") {
                                        pass = false;
                                        let dateSelecSep = this.state.selDate.split('/');
                                        let newDate = dateSelecSep[2] + "-" + dateSelecSep[1] + "-" + dateSelecSep[0];
            
                                        if (grille.date == newDate) {
                                            pass = true;
                                        }
                                    }
                                    if (pass != false && this.state.selType != "tous") {
                                        pass = false;
                                        if (grille.type == this.state.selType) {
                                            pass = true;
                                        }
                                    }

                                    return pass;
                                })
                                .map(grille => {
                                return <li className="lw__gr__item">
                                    <span className="lw__gr__date">
                                        {
                                            grille.tirage == null ?
                                                "Grilles non associées à un tirage :" :
                                                `Tirage du ${grille.dateFormat}`
                                        }
                                        </span>
                                    {
                                        grille.tirage != null &&
                                        <div className="lw__gr__gains">
                                        {
                                            grille.gains.length > 0 ?
                                                <ul className="lw__gr__gainsliste">
                                                    <h3 className="lw__gr__subttl">Vos gains&nbsp;:</h3>
                                                    {
                                                        grille.gains.map(gain => {
                                                            return <li className="lw__gr__gainsitem">
                                                                {
                                                                    gain.symb == null ?
                                                                        <span className="lw__gr__gaintxt">
                                                                            {gain.txt }
                                                                        </span> :
                                                                        (
                                                                            gain.symb == "credits" ?
                                                                                    <Credit nb={gain.value} class="lw__gr__gainscred"/>
                                                                                    : <span className={`lw__gr__gaintxt lw__gr__gaintxt--${gain.type}`}>
                                                                                        {gain.value}
                                                                                        <IcomoonReact iconSet={iconSet} icon={gain.symb} />
                                                                                    </span>
                                                                        )
                                                                }
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                                : <h3 className="lw__gr__empty lw__gr__empty--largewidth">Vous n'avez rien gagné lors de ce tirage</h3>
                                            }
                                        </div>
                                    }
                                    {
                                        grille.tirage != null &&
                                        <div className="lw__gr__tirage">
                                            <h2 className="lw__gr__subttl">Le tirage :</h2>
                                            {
                                                grille.tirage.numeros.length == 0 ?
                                                    <h3 className="lw__gr__empty lw__gr__empty--largewidth">Le tirage ne s'est pas encore déroulé</h3> :
                                                    <div className="lw__gr__tiragee">
                                                        {
                                                            grille.tirage.numeros.map(num => {
                                                                return <span className="lw__gr__num lw__gr__num--tirage">
                                                                    {num}
                                                                </span>
                                                            })
                                                        }
                                                        <br />
                                                        {
                                                            grille.tirage.bonus.map(num => {
                                                                return <span className="lw__gr__num lw__gr__num--tiragebonus">
                                                                    {num}
                                                                </span>
                                                            })
                                                        }
                                                    </div>
                                            }
                                        </div>
                                    }
                                    <div className="lw__gr__nums">
                                        {console.log(grille)}
                                        {
                                            grille.grilles.map((tpGrille) => {
                                                return tpGrille.map((num, index) => {
                                                    if (index < 5) {
                                                        return <span
                                                            className={`lw__gr__num lw__gr__num--${grille.type} ${grille.tirage != null && grille.tirage.numeros.includes(num) && `lw__gr__num--${grille.type}--win`}`}>
                                                            {num}
                                                        </span>
                                                    } else {  
                                                        return <span
                                                            className={`lw__gr__num lw__gr__num--${grille.type}--bonus ${grille.tirage != null && grille.tirage.bonus.includes(num) && `lw__gr__num--${grille.type}--bonus--win`}`}>
                                                            {num}
                                                        </span>
                                                    }
                                                })
                                            })
                                        }
                                    </div>
                                </li>
                            }): <h3 className="lw__gr__empty">Vous n'avez enregistrer aucune grille pour cette sélection</h3>) :
                        <h3 className="lw__gr__empty">Vous n'avez enregistrer aucune grilles</h3>
                }
            </ul>
        </section>
    }
}

const mapStateToProps = state => {
    return {
        grilles: state.lwgrilles
    }
}

const mapDispatchToProps = {
    getLwGrilles
}

export default connect(mapStateToProps, mapDispatchToProps)(LWGrilles);