import React from "react";
import Card from "../../components/Card";
import { connect } from "react-redux";
import { getVideos } from "../../actions/videos";
import Alert from "../../components/Alert";

class Videos extends React.Component {
    state = {
        alert: {
            active: false,
            type: "",
            title: "",
            msg: ""
        },
        videos: []
    }

    componentDidMount() {
        document.title = 'Tof La Win - Les vidéos';
        this.props.getVideos();
        this.setState({
            alert: {
                active: true,
                type: "warning",
                title: "Réception des vidéos...",
                msg: "Réception des vidéos en cours..."
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.videos != undefined &&
            this.props.videos != prevProps.videos
        ) {
            if (this.props.videos.alert != this.state.alert) {
                this.setState({alert: this.props.videos.alert});
            }
        }
    }

    render() {
        return <main className="page page--video">
            <h2 className="page__ttl">Vidéos</h2>
            {/** todo ajouter système de filtre */}
            {
                this.state.alert.active &&
                <Alert
                    class={`alert--${this.state.alert.type}`}
                    ttl={this.state.alert.title}
                >
                    {this.state.alert.msg}
                </Alert>
                }
            {
                this.props.videos.videos.length > 0 ?
                    // todo mettre les props dans card
                    this.props.videos.videos.map((video, index) => <Card
                        class="card--video"
                        title={video.titre}
                        subttl={null}
                        date={video.date?.date}
                        linkimg={null}
                        otherlink={`https://www.youtube.com/watch?v=${video.code}`}
                        imglink={video.miniature}
                        img={null}
                        link={null}
                        desc={video.description}
                        
                    />)
                    : <h3 className="page__empty">Aucune vidéo n'est disponible pour le moment.</h3>
            }
        </main>
    }
}

const mapStateToProps = state => {
    return {
        videos: state.videos
    }
}

const mapDispatchToProps = {
    getVideos
}

export default connect(mapStateToProps, mapDispatchToProps)(Videos);