import { GET_VIDEOS } from "../actions/action-types";

const initialState = {
    alert: {
        active: false,
        type: "",
        title: "",
        msg: ""
    },
    isGetting: false,
    videos: []
};

export function GetVideosReducer(state = initialState, action) {
    switch (action.type) {
        case GET_VIDEOS:
            return {
                alert: action.payload.alert,
                isGetting: action.payload.isGetting,
                videos: action.payload.videos
            }
        default:
            return state;
    }
}