import React from "react";
import iconSet from "../selection.json";
import IcomoonReact from "icomoon-react";
import logo from "../images/logo.svg";
import Credit from "./Credits";
import { Link } from "react-router-dom";
import { setLastco, getDatasNbJeux } from "../actions/profil";
import { formatedCredit } from "../helpers";
import { connect } from "react-redux";
import Avatar from "./Avatar";

class Header extends React.Component {
    state = {
        alert: {
            active: false,
            type: '',
            title: '',
            msg: ''
        },
        openMenu: false,
        credits: 0,
        points: 0,
        openJeu: false,
        openConcours: false,
        openDivers: false
    }

    componentDidMount() {
        if (localStorage.userId) {
            this.props.getDatasNbJeux();
            this.props.setLastco();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.redlastco.alert != this.props.redlastco.alert &&
            this.state.alert != this.props.redlastco.alert 
        ) {
            this.setState({
                alert: this.props.redlastco.alert
            })
        }

        if (
            prevProps.reddatasnbjeu.alert != this.props.reddatasnbjeu.alert &&
            this.state.alert != this.props.reddatasnbjeu.alert 
        ) {
            this.setState({
                alert: this.props.reddatasnbjeu.alert
            })
        }

        if (
            this.props.reddatasnbjeu.infos != prevProps.reddatasnbjeu.infos &&
            (this.state.credits != this.props.reddatasnbjeu.credits ||
            this.state.points != this.props.reddatasnbjeu.points)
        ) {
            this.setState({
                credits: this.props.reddatasnbjeu.infos.credits,
                points: this.props.reddatasnbjeu.infos.points,
            })
        }
    }

    changeStateMenu = () => {
        this.setState({openMenu: !this.state.openMenu});
    }

    render() {
        return <header className="header">
        {/** Logo */}
        <Link to="/">
            <img src={logo} alt="Logo de Tof La Win, chaine YouTube de grattage de tickets FDJ" className="header__logo" />
        </Link>
        {
            localStorage.userId != undefined ?
                <div className="header__user">
                    {/** Avatar */}
                    <Link to="/profil" className="header__linkavatar">
                        <Avatar
                            avatar={localStorage.avatar}
                            class="header__avatar"
                        />
                    </Link>
                    {/** Crédits */}
                    <div className="header__credpts">
                        <Credit nb={formatedCredit(this.state.credits)} class="header__credit"/>
                        <span className="header__points">
                            {formatedCredit(this.state.points)} pts
                        </span>
                    </div>
                </div> :
                <div className="header__user">
                    <Link to="/login" className="btn">Se connecter</Link>
                </div>
        }
        {/** Bouton menu */}
        <button className="header__btnh" onClick={() => this.changeStateMenu()}>
            <IcomoonReact iconSet={iconSet} icon="menu" />
            {/* <svg className="icon icon-menu"><use xlink:href="#icon-menu"></use></svg> */}
        </button>

        {/** Menu */}
        <nav className={`menu header__menu ${this.state.openMenu && "menu--active"}`}>
            <header className="menu__header">
                {
                    localStorage.userId != undefined ?
                        <Link to="/profil" className="menu__linkavatar">
                            <Avatar
                                avatar={localStorage.avatar}
                                class="menu__avatar"
                            />
                        </Link> :
                        <Link to="/login" className="btn">Se connecter</Link>
                }

                {/** Bouton fermer menu */}
                <button className="menu__close" onClick={() => this.changeStateMenu()}>
                    <IcomoonReact iconSet={iconSet} icon="cross" />
                    {/* <svg className="icon icon-cross"><use xlink:href="#icon-cross"></use></svg> */}
                </button>
            </header>
            <ul className="menu__list">
                {/** Jeux */}
                <li className="menu__item">
                    <button className="menu__link" onClick={() => this.setState({openJeu: !this.state.openJeu})}>
                        <IcomoonReact iconSet={iconSet} icon="gamepad" />
                        Jeux
                        <IcomoonReact iconSet={iconSet} icon="arrow-down2" />
                    </button>

                    {/** Sous menu */}
                    <ul className={`menu__submenu submenu ${this.state.openJeu && "submenu--active"}`}>
                        <li className="submenu__item">
                            <Link onClick={() => this.setState({openMenu: false})} to="/jeux" className="submenu__link">Les jeux</Link>
                        </li>
                        <li className="submenu__item">
                            <Link onClick={() => this.setState({openMenu: false})} to="/classement" className="submenu__link">Classement</Link>
                        </li>
                        <li className="submenu__item">
                            <Link onClick={() => this.setState({openMenu: false})} to="/vos-statistiques" className="submenu__link">Vos statistiques</Link>
                        </li>
                    </ul>
                </li>
                {/** Concours */}
                <li className="menu__item">
                    <button className="menu__link" onClick={() => this.setState({openConcours: !this.state.openConcours})}>
                        <IcomoonReact iconSet={iconSet} icon="trophy" />
                        Concours
                        <IcomoonReact iconSet={iconSet} icon="arrow-down2" />
                    </button>

                    {/** Sous menu */}
                    <ul className={`menu__submenu submenu ${this.state.openConcours && "submenu--active"}`}>
                        <li className="submenu__item">
                            <Link onClick={() => this.setState({openMenu: false})} to="/loading" className="submenu__link">Les concours</Link>
                        </li>
                        {/** 
                        <li className="submenu__item">
                            <Link onClick={() => this.setState({openMenu: false})} to="/loading" className="submenu__link">Lotof</Link>
                        </li>*/}
                        <li className="submenu__item">
                            <Link onClick={() => this.setState({openMenu: false})} to="/poker" className="submenu__link">Poker</Link>
                        </li>
                        {/**
                        <li className="submenu__item">
                            <Link onClick={() => this.setState({openMenu: false})} to="/loading" className="submenu__link">Bingo</Link>
                        </li> */}
                    </ul>
                </li>
                {/** Vidéos */}
                <li className="menu__item">
                    <Link onClick={() => this.setState({openMenu: false})} to="/videos" className="menu__link">
                        <IcomoonReact iconSet={iconSet} icon="youtube" />
                        Vidéos
                    </Link>
                </li>
                {/** Évènements */}
                <li className="menu__item">
                    <Link onClick={() => this.setState({openMenu: false})} to="/calendrier" className="menu__link">
                        <IcomoonReact iconSet={iconSet} icon="calendar" />
                        Évènements
                    </Link>
                </li>
                {/** Divers */}
                <li className="menu__item">
                    <button className="menu__link" onClick={() => this.setState({openDivers: !this.state.openDivers})}>
                        <IcomoonReact iconSet={iconSet} icon="plus" />
                        Divers
                        <IcomoonReact iconSet={iconSet} icon="arrow-down2" />
                    </button>

                    {/** Sous menu */}
                    <ul className={`menu__submenu submenu ${this.state.openDivers && "submenu--active"}`}>
                        <li className="submenu__item">
                            <Link onClick={() => this.setState({openMenu: false})} to="/loading" className="submenu__link">Sondages</Link>
                        </li>
                        <li className="submenu__item">
                            <Link onClick={() => this.setState({openMenu: false})} to="/tof-statistiques" className="submenu__link">Statistiques</Link>
                        </li>
                        <li className="submenu__item">
                            <Link onClick={() => this.setState({openMenu: false})} to="/actualites" className="submenu__link">Actualités</Link>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </header>
    }
}

const mapStateToProps = state => {
    return {
        redlastco: state.lastco,
        reddatasnbjeu: state.datasnbjeu
    }
};

const mapDispatchToProps = {
    setLastco,
    getDatasNbJeux
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);