import { NEW_USER, MDP_NEW_MDP, MDP_SEND_CODE, MDP_VERIF_CODE } from "../actions/action-types";

const initialState = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
    next: false
};

export function MdpSendCodeReducer(state = initialState, action) {
    switch (action.type) {
        case MDP_SEND_CODE:
            return {
                alert: action.payload.alert,
                next: action.payload.next
            }
        default:
            return state;
    }
}

export function MdpNewMdpReducer(state = initialState, action) {
    switch (action.type) {
        case MDP_NEW_MDP:
            return {
                alert: action.payload.alert,
                next: action.payload.next
            }
        default:
            return state;
    }
}

export function MdpVerifCodeReducer(state = initialState, action) {
    switch (action.type) {
        case MDP_VERIF_CODE:
            return {
                alert: action.payload.alert,
                next: action.payload.next
            }
        default:
            return state;
    }
}

export function SetNewUserReducer(state = initialState, action) {
    switch (action.type) {
        case NEW_USER:
            return {
                alert: action.payload.alert,
                next: action.payload.next
            }
        default:
            return state;
    }
}