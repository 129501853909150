import { getDomain, config } from "../helpers";
import axios from "axios";
import { GET_PROFIL, EDIT_PROFIL, GET_INFO_JEU, REMOVE_CREDITS, CONVERT_POINT, LAST_CO, VERIF_ROUE, ADD_ROUE, VERIF_CREDITS } from "./action-types";

// ======= Profil =================================
// Obtenir données profil
export function getProfil() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Réception de données',
            msg: "Recherche des données en cours..."
        };
        let profil = null;

        axios.post(`${getDomain()}user/byid/${localStorage.userId}`, {
            credentials: localStorage.credentials
        }, config)
            .then(res => {
                if (res.data[0] == "error") {
                    alert = {
                        active: true,
                        type: 'error',
                        title: 'Une erreur est survenue',
                        msg: "Une erreur est survenue lors de la récupération de vos informations. Veuillez contacter Matt (Code : Pro001)"
                    };
                } else {
                    if (res.data['code'] == "inexist") {
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue',
                            msg: "Une erreur est survenue lors de la récupération de vos informations. Veuillez contacter Matt (Code : Pro002)"
                        }
                    } else {
                        alert = {
                            active: false,
                            type: '',
                            title: '',
                            msg: ''
                        };
                        profil = res.data;
                    }
                }

                dispatch({
                    type: GET_PROFIL,
                    payload: {
                        alert: alert,
                        profil: profil
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Vous n'avez pas pu recevoir vos informatiions. Veuillez contacter Matt (Code : Pro003)"
                };
                dispatch({
                    type: GET_PROFIL,
                    payload: {
                        alert: alert,
                        profil: profil
                    }
                })
            })
    }
}

// Edit profil
export function editProfil({
    email,
    pseudo,
    anniv,
    avatar,
    newsletter,
    mdp,
    mdpconfirm
}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Enregistrement en cours...',
            msg: 'Enregistrement des données en cours'
        };
        let next = false;

        axios.post(`${getDomain()}user/edit/${localStorage.userId}`, {
            credentials: localStorage.credentials,
            username: email,
            pseudo: pseudo,
            anniv: anniv,
            avatar: avatar,
            newsletter: newsletter,
            mdp: mdp,
            mdpconfirm: mdpconfirm
        }, config)  
            .then(res => {
                switch (res.data['code']) {
                    case "error":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue',
                            msg: "Vous n'avez pas pu modifier vos données. Tentez de vous connecter et de vous reconnecter."
                        };
                        break;
                    case "inexist":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue',
                            msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Pro004)"
                        };
                        break;
                    case "field_empty":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Informations manquantes',
                            msg: "Veuillez remplir tous les champs obligatoires"
                        };
                        break;
                    case "success":
                        alert = {
                            active: true,
                            type: 'success',
                            title: 'Informations enregistrées',
                            msg: "Vos informations ont bien été modifiées"
                        };
                        next = true;
                        break;
                    case "mdp_empty":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Informations manquantes',
                            msg: "Veuillez confirmer votre mot de passe"
                        };
                        break;
                    case "mdp_notequals":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Saisie incorrect',
                            msg: "Vos mots de passe ne correspondent pas"
                        };
                        break;
                    case "email_exist":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Saisie incorrect',
                            msg: 'Cette adresse mail est déjà prise'
                        };
                        break;
                    case "pseudo_exist":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Saisie incorrect',
                            msg: 'Cette pseudo est déjà utilisé'
                        };
                        break;
                }

                dispatch({
                    type: EDIT_PROFIL,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Vous n'avez pas pu enregistrer vos informatiions. Veuillez contacter Matt (Code : Pro005)"
                };
                dispatch({
                    type: EDIT_PROFIL,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
    }
}

// Dernière connexion
export function setLastco() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Enregistrement de données...',
            msg: 'Enregistrement de données en cours...'
        };

        axios.post(`${getDomain()}user/lastco`, {
            credentials: localStorage.credentials,
            user: localStorage.userId
        }, config)
            .then(res => {
                switch (res.data[0]) {
                    case "ok":
                        alert = {
                            active: false,
                            type: '',
                            title: '',
                            msg: ''
                        };
                        break;
                    case "user_inexist":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue',
                            msg: 'Une erreur est survenue. Veuillez contacter Matt (Code : Pro006)'   
                        };
                        break;
                    case "error":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue',
                            msg: 'Une erreur est survenue. Veuillez contacter Matt (Code : Pro007)'
                        };
                        break;
                }

                dispatch({
                    type: LAST_CO,
                    payload: {
                        alert: alert
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Pro008)"
                };
                dispatch({
                    type: LAST_CO,
                    payload: {
                        alert: alert,
                    }
                })
            })
    }
}

// ======= Jeux ===================================
// Obtenir les nombres pour jouer (crédits, points...)
export function getDatasNbJeux() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Recherche en cours...',
            msg: 'Recherche de vos données en cours...'
        };
        let infos = {
            besace: 0,
            credits: 0,
            points: 0,
            roue: 0
        };

        if (localStorage.userId != undefined) {
            axios.post(`${getDomain()}user/infos/jeux`, {
                credentials: localStorage.credentials,
                user: localStorage.userId
            }, config)
                .then(res => {
                    if (res.data[0] == "error") {
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue',
                            msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Pro009)"
                        };
                    } else {
                        if (res.data[0] == "user_inexist") {
                            alert = {
                                active: true,
                                type: 'error',
                                title: 'Une erreur est survenue',
                                msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Pro010)"
                            };
                        } else {
                            alert = {
                                active: false,
                                type: '',
                                title: '',
                                msg: ''
                            }
                            infos = res.data
                        }
                    }
    
                    dispatch({
                        type: GET_INFO_JEU,
                        payload: {
                            alert: alert,
                            infos: infos
                        }
                    })
                })
                .catch(error => {
                    console.log(error);
                    alert = {
                        active: true,
                        type: 'error',
                        title: 'Une erreur est survenue',
                        msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Pro011)"
                    };
                    dispatch({
                        type: GET_INFO_JEU,
                        payload: {
                            alert: alert,
                            infos: infos
                        }
                    })
                })
        } else {
            alert = {
                active: false,
                type: '',
                title: '',
                msg: ''
            }
            dispatch({
                type: GET_INFO_JEU,
                payload: {
                    alert: alert,
                    infos: infos
                }
            })
        }
    }
}

// Retirer crédits
export function setRemoveCredits({type, nbcredits}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Mis à jour en cours...',
            msg: 'Vos données se mettent à jour...'
        };
        let next = false;

        axios.post(`${getDomain()}user/credits/remove`, {
            type: type,
            nbcredits: nbcredits,
            user: localStorage.userId,
            credentials: localStorage.credentials
        }, config)
            .then(res => {
                if (res.data == "ok") {
                    alert = {
                        active: false,
                        type: '',
                        title: '',
                        msg: ''
                    };
                    next = true;
                } else {
                    if (res.data[0] == "user_inexist") {
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue',
                            msg: 'Une erreur est survenue. Veuillez contacter Matt (Code : Pro011)'
                        };
                    } else {
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue',
                            msg: 'Une erreur est survenue. Veuillez contacter Matt (Code : Pro012)'
                        };
                    }
                }

                dispatch({
                    type: REMOVE_CREDITS,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Pro013)"
                };
                dispatch({
                    type: REMOVE_CREDITS,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
    }
}

// Convertir point
export function setConvertPoint({points}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Conversion en cours...',
            msg: 'Conversion de vos points en cours...'
        };
        let next = false;

        axios.post(`${getDomain()}user/convertpoints`, {
            credentials: localStorage.credentials,
            user: localStorage.userId,
            points: points
        }, config)
            .then(res => {
                if (res.data === true) {
                    alert = {
                        active: true,
                        type: 'success',
                        title: 'Points convertis',
                        msg: 'Vos points ont bien été convertis'
                    };
                    next = true;
                } else {
                    if (res.data === false) {
                        alert = {
                            active: true,
                            type: 'error',
                            title: "Vous n'avez pas assez de points",
                            msg: "Vous n'avez pas assez de points pour les convertir en crédits."
                        }
                    } else {
                        if (res.data[0] == "user_inexist") {
                            alert = {
                                active: true,
                                type: 'error',
                                title: 'Une erreur est survenue',
                                msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Pro014)"
                            };
                        } else {
                            alert = {
                                active: true,
                                type: 'error',
                                title: 'Une erreur est survenue',
                                msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Pro015)"
                            };
                        }
                    }
                }

                dispatch({
                    type: CONVERT_POINT,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Pro016)"
                };
                dispatch({
                    type: CONVERT_POINT,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
    }
}

// Verif roue
export function getVerifRoue() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Vérification en cours...',
            msg: 'Vérification de vos nombres de roues en cours...'
        };
        let next = false;

        axios.post(`${getDomain()}user/verif/roue`, {
            credentials: localStorage.credentials,
            user: localStorage.userId
        }, config)
            .then(res => {
                if (res.data) {
                    alert = {
                        active: false,
                        type: '',
                        title: '',
                        msg: ''
                    };
                    next = true;
                } else {
                    alert = {
                        active: true,
                        type: 'error',
                        title: 'Une erreur est survenue',
                        msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Pro017)"
                    };
                }

                dispatch({
                    type: VERIF_ROUE,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Pro018)"
                };
                dispatch({
                    type: VERIF_ROUE,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
    }
}

// Ajout roue
export function addRoue() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: "Ajout d'une roue",
            msg: "Ajout d'une roue en cours..."
        };
        let next = false;

        axios.post(`${getDomain()}user/new/roue`, {
            user: localStorage.userId,
            credentials: localStorage.credentials
        }, config)
            .then (res => {
                if (res.data) {
                    alert = {
                        active: false,
                        type: '',
                        title: '',
                        msg: ''
                    };
                    next = true;
                } else {
                    alert = {
                        active: true,
                        type: 'error',
                        title: "Une erreur est survenue",
                        msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Pro018)"
                    }
                }

                dispatch({
                    type: ADD_ROUE,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Pro019)"
                };
                dispatch({
                    type: ADD_ROUE,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
    }
}

// Vérif crédits
export function verifCredits({type, value}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Vérification en cours...',
            msg: 'Vérification de vos crédits en cours...'
        };
        let next = false;

        axios.post(`${getDomain()}user/creditsverif`, {
            credentials: localStorage.credentials,
            user: localStorage.userId,
            type: type,
            nbcredits: value
        })
        .then(res => {
            switch (res.data[0]) {
                case "ok":
                    alert = {
                        active: false,
                        type: '',
                        title: '',
                        msg: ''
                    };
                    next = true;
                    break;
                case "besace_insuffisant":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Vous ne pouvez pas jouer',
                            msg: "Vous n'avez pas suffisament de ticket 'besace'"
                        };
                        break;
                case "roue_insuffisant":
                    alert = {
                        active: true,
                        type: 'error',
                        title: 'Vous ne pouvez pas jouer',
                        msg: "Vous n'avez pas suffisament de ticket 'roue'"
                    };
                    break;
                case "credits_insuffisant":
                    alert = {
                        active: true,
                        type: 'error',
                        title: 'Vous ne pouvez pas jouer',
                        msg: "Vous n'avez pas suffisament de crédits pour jouer à ce jeu"
                    };
                    break;
                case "user_inexist":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue',
                            msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Pro020)"
                        };
                        break;
                case "error":
                    alert = {
                        active: true,
                        type: 'error',
                        title: 'Une erreur est survenue',
                        msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Pro021)"
                    };
                    break;
            }
            dispatch({
                type: VERIF_CREDITS,
                payload: {
                    alert: alert,
                    next: next
                }
            })
        })
        .catch(error => {
            console.log(error);
            alert = {
                active: true,
                type: 'error',
                title: 'Une erreur est survenue',
                msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Pro022)"
            };
            dispatch({
                type: VERIF_CREDITS,
                payload: {
                    alert: alert,
                    next: next
                }
            })
        })
    }
}