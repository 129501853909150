import React from 'react';
import iconSet from "../../selection.json";
import IcomoonReact from "icomoon-react";
import logoSuperTel from "../../images/jeux/lotowin/logo_super_tel.svg";
import logoMaxiTel from "../../images/jeux/lotowin/logo_maxi_tel.svg";
import logoMegaTel from "../../images/jeux/lotowin/logo_mega_tel.svg";
import logoExtraTel from "../../images/jeux/lotowin/logo_extra_win.svg";
import boulierSuper from "../../images/jeux/lotowin/boulier_super_tel.svg";
import boulierMaxi from "../../images/jeux/lotowin/boulier_maxi_tel.svg";
import boulierMega from "../../images/jeux/lotowin/boulier_mega_tel.svg";
import boulierExtra from "../../images/jeux/lotowin/boulier_extra_tel.svg";
import AccBoulier from './AccBoulier';

class LwAccueil extends React.Component {
    state = {
        boulier: "super"
    }

    handleChangeBoulier = (dir) => {
        let type = null;
        if (dir == "next") {
            if (this.state.boulier == "super") {
                type = "maxi";
            } else {
                if (this.state.boulier == "maxi") {
                    type = "mega";
                } else {
                    if (this.state.boulier == "mega") {
                        type = "extra";
                    } else {
                        if (this.state.boulier == "extra") {
                            type = "super";
                        }
                    }
                }
            }
        } else {
            if (this.state.boulier == "super") {
                type = "extra";
            } else {
                if (this.state.boulier == "maxi") {
                    type = "super";
                } else {
                    if (this.state.boulier == "mega") {
                        type = "maxi";
                    } else {
                        if (this.state.boulier == "extra") {
                            type = "mega";
                        }
                    }
                }
            }
        }

        this.handleChangeType(type);
        this.setState({boulier: type});
    }

    handleChangeType = (type) => {
        this.props.handleChangeType(type);
    }

    handleChangePage = ({type, value}) => {
        this.props.handleChange({type: type, value: value});
    }

    render() {
        return <>
            {/** Information boulier Super Win */}
            <AccBoulier
                logo={logoSuperTel}
                typeb="super"
                boulier={boulierSuper}
                nbgrille={this.props.nbs?.tirSup}
                class={
                    this.state.boulier != "super" ?
                        "lw__boulier--prev" :
                        ""
                }
                tirage="off"
                id="super"
                handleChange={this.handleChangePage}
            />
            {/** Information boulier Maxi Win */}
            <AccBoulier
                logo={logoMaxiTel}
                typeb="maxi"
                boulier={boulierMaxi}
                nbgrille={this.props.nbs?.tirMaxi}
                class={
                    this.state.boulier == "super" ?
                        "lw__boulier--next" :
                        (this.state.boulier == "maxi" ?
                            "" :
                            ((this.state.boulier == "mega" || this.state.boulier == "extra") ? "lw__boulier--prev" : "lw__boulier--prev"))
                }
                tirage="off"
                id="maxi"
                handleChange={this.handleChangePage}
            />
            {/** Information boulier Mega Win */}
            <AccBoulier
                logo={logoMegaTel}
                typeb="mega"
                boulier={boulierMega}
                nbgrille={this.props.nbs?.tirMega}
                class={
                    (this.state.boulier == "super" || this.state.boulier == "maxi") ?
                        "lw__boulier--next" :
                        (this.state.boulier == "mega" ?
                            "" :
                            (this.state.boulier == "extra" ? "lw__boulier--prev" : "lw__boulier--prev"))
                }
                id="mega"
                tirage="on"
                handleChange={this.handleChangePage}
            />
            {/** Information boulier Extra Win */}
            <AccBoulier
                logo={logoExtraTel}
                typeb="extra"
                boulier={boulierExtra}
                nbgrille={this.props.nbs?.tirExtra}
                class={
                    this.state.boulier != "extra" ?
                        "lw__boulier--next" :
                        ""
                }
                tirage="on"
                id="extra"
                handleChange={this.handleChangePage}
            />

            {/** Control boulier */}
            <div class="lw__boulier__control">
                <button class={`lw__boulier__ctrlbtn lw__boulier__ctrlbtn--${this.state.boulier}`} onClick={() => this.handleChangeBoulier('prev')}>
                    <IcomoonReact iconSet={iconSet} icon="circle-left" />
                </button>
                <button class={`lw__boulier__ctrlbtn lw__boulier__ctrlbtn--right lw__boulier__ctrlbtn--${this.state.boulier}`} onClick={() => this.handleChangeBoulier("next")}>
                    <IcomoonReact iconSet={iconSet} icon="circle-right" />
                </button>
            </div>
        </>
    }
}

export default LwAccueil;