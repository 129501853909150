import React from "react";
import { getStatsTof } from "../../actions/divers";
import { connect } from "react-redux";
import Fiche from "../../components/Fiche";
import iconSet from "../../selection.json";
import IcomoonReact from "icomoon-react";

class Stats extends React.Component {
    state = {
        alert: {
            active: true,
            type: 'warning',
            title: 'Recherche de données...',
            msg: 'Recherche des statistiques de Tof en cours...'
        },
        min: 0,
        max: 49,
        tri: 'liste'
    }

    componentDidMount() {
        document.title = 'Tof La Win - Statistiques';
        this.props.getStatsTof();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.divsta != undefined &&
            this.props.divsta != prevProps.divsta &&
            this.props.divsta.alert != this.state.alert
        ) {
            this.setState({alert: this.props.divsta.alert})
        }
    }

    handleChangePageTableau(dir) {
        if (dir == "g") {
            // On va dans les pages précédentes
            if (this.state.min != 0) {
                this.setState({
                    min: this.state.min - 50,
                    max: this.state.max - 50
                })
            }
        } else {
            // On va dans les pages suivantes
            if ((this.state.max + 50) <= this.props.divsta.datas[this.state.tri].length) {
                this.setState({
                    min: this.state.min + 50,
                    max: this.state.max + 50
                })
            }
        }
    }

    render() {
        return <main className="page">
            <h2 className="page__ttl">
                Statistiques
            </h2>
            {
                this.props.divsta.datas.chiffres != undefined ?
                    <section className="page__listfiche">
                        <Fiche
                            nb={this.props.divsta.datas?.chiffres["2024"] == undefined ? 0 : this.props.divsta.datas?.chiffres["2024"].nb}
                            ttl="Tickets grattés"
                            subttl="En 2024"
                        />
                        <Fiche
                            nb={this.props.divsta.datas?.chiffres["2024"] == undefined ? 0 : this.props.divsta.datas?.chiffres["2024"].mise}
                            ttl="De mise"
                            subttl="En 2024"
                        />
                        <Fiche
                            nb={this.props.divsta.datas?.chiffres["2024"] == undefined ? 0 : this.props.divsta.datas?.chiffres["2024"].gains}
                            ttl="De gains"
                            subttl="En 2024"
                        />
                        <Fiche
                            nb={this.props.divsta.datas?.chiffres["all"].nb}
                            ttl="Tickets grattés"
                            subttl="En tout"
                        />
                        <Fiche
                            nb={this.props.divsta.datas?.chiffres["all"].mise}
                            ttl="De mise"
                            subttl="En tout"
                        />
                        <Fiche
                            nb={this.props.divsta.datas?.chiffres["all"].gains}
                            ttl="De gains"
                            subttl="En tout"
                        />
                    </section> : ""
            }
            {
                this.props.divsta.datas.chiffres != undefined ?
                <section className="classement">
                    <header className="classement__header">
                        <div className="classement__trier">
                            <select className="classement__seltri" value={this.state.tri} onChange={(el) => this.setState({tri: el.target.value})}>
                                <option value="liste">Session</option>
                                <option value="liste_ticket">Tickets</option>
                            </select>
                        </div>
                        <div className="classement__dir">
                            <button
                                disabled={this.state.min == 0}
                                onClick={() => this.handleChangePageTableau("g")}
                                className={`classement__dirbtn ${this.state.min == 0 ? 'classement__dirbtn--inactive' : ''}`}><IcomoonReact iconSet={iconSet} icon="circle-left" /></button>
                            <button
                                disabled={(this.state.max + 50) > this.props.divsta.datas[this.state.tri].length}
                                onClick={() => this.handleChangePageTableau("d")}
                                className={`classement__dirbtn ${((this.state.max + 50) > this.props.divsta.datas[this.state.tri].length) ? 'classement__dirbtn--inactive' : ''}`}><IcomoonReact iconSet={iconSet} icon="circle-right" /></button>
                        </div>
                    </header>
                    <table className="classement__table">
                        <thead className="classement__thead">
                            <tr className="classement__tr">
                                <th className="classement__th">
                                    Nb Tickets
                                </th>
                                <th className="classement__th">Mise</th>
                                <th className="classement__th">Gains</th>
                                <th className="classement__th">Total</th>
                            </tr>
                        </thead>
                        <tbody className="classement__tbody">
                            {
                                this.props.divsta.datas[this.state.tri].length > 0 ?
                                    (this.props.divsta.datas[this.state.tri]
                                        .filter((item, index) => {
                                            let pass = false;
                                            if (index >= this.state.min && index <= this.state.max) {
                                                pass = true;
                                            }
                                            return pass;
                                            // todo verif filtre
                                        }).map((stat, index) => {
                                        return <tr className="classement__tr">
                                            <td className="classement__td">{stat.nb}</td>
                                            <td className="classement__td">{stat.mise}</td>
                                            <td className="classement__td">{stat.gains}</td>
                                            <td className={`classement__td ${((stat.gains - stat.mise) < 0) ? 'classement__td--red' : 'classement__td--success'}`}>{(stat.gains - stat.mise)}</td>{/** todo à vérifier le total */}
                                        </tr>
                                    })) : <h2 className="page__empty">Aucune données disponibles</h2>
                            }
                        </tbody>
                    </table>
                </section> : ""
            }
        </main>
    }
}

const mapStateToProps = state => {
    return {
        divsta: state.divsta
    }
}

const mapDispatchToProps = {
    getStatsTof
}

export default connect(mapStateToProps, mapDispatchToProps)(Stats);