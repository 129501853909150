import React from "react";
import logoOrdi from "../../images/jeux/lotowin/logo_ordi.svg";
import logoTel from "../../images/jeux/lotowin/logo_tel.svg";
import iconSet from "../../selection.json";
import IcomoonReact from "icomoon-react";
import Credit from '../Credits';
import AccBoulier from './AccBoulier';
import LwAccueil from "./Accueil";
import LWBoulier from "./Boulier";
import LWModal from "./Modal";
import Tirage from "./Tirage";
import { connect } from "react-redux";
import { getLwNbGrilles, getLwTenSuper } from "../../actions/lotowin";
import { formatedCredit } from "../../helpers";

class LotoWin extends React.Component {
    state = {
        active: false,
        activeModal: false,
        pageActive: "accueil",
        //pageActive: "tirage",
        valueType: "super",
        typeModal: null,
        nbs: {}
    };

    componentDidMount() { 
        this.props.getLwNbGrilles();
        
        localStorage.getLast10 = new Date().getTime();
        
        setInterval(() => {
            this.getNewGrilleSuper();
        }, 1000);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.nbs != this.props.nbs.nbs &&
            this.props.nbs.nbs != undefined
        ) {
            this.setState({nbs: this.props.nbs.nbs});
        }
    } 

    getNewGrilleSuper = () => {
        if (((new Date().getTime() - localStorage.getLast10) / 1000) >= 60) {
            // On appelle la fonction pour obtenir 10 grilles en plus et on met à jour localStorage.getLast10
            this.props.getLwTenSuper();
        }
        if (((new Date().getTime() - localStorage.getLast10) / 1000) < 2) {
            this.props.getLwNbGrilles();
        }
    }

    handleChangePage = (page) => {
        this.setState({
            pageActive: page.type,
            valueType: page.value
        });
    }

    handleCloseModal = (etat) => {
        if (etat) {
            this.setState({activeModal: false});
        }
    }

    handleChangeModal = (type) => {
        this.setState({
            activeModal: true,
            typeModal: type
        })
    }

    handleChangeGrilleHas = (value, type) => {
        let tempGrilles = this.state.nbs;

        tempGrilles[type] = value;

        this.setState({nbs: tempGrilles});
    }

    researchNb = () => {
        this.props.getLwNbGrilles();
    }

    render() {
        return <div className={`bgjeux bgjeux--active ${this.state.active && "bgjeux--active"}`}>
        <main class="lw">
            {/** Logo */}
            <img src={logoOrdi} alt="Logo Lotowin - Loterie quotidienne de Tof La Win" className="lw__logo lw__logo--ordi" />
            <img src={logoTel} alt="Logo Lotowin - Loterie quotidienne de Tof La Win" className="lw__logo lw__logo--tel" />


            {
                (this.state.pageActive == "accueil" ?
                    <LwAccueil active={this.state.pageActive} handleChange={this.handleChangePage} handleChangeType={(type) => this.setState({valueType: type})} nbs={this.state.nbs} /> :
                    (
                        this.state.pageActive == "jeux" ?
                            <LWBoulier
                                active={this.state.pageActive}
                                handleChange={this.handleChangePage}
                                changeModal={this.handleChangeModal}
                                typeb={this.state.valueType}/> :
                            <Tirage
                                typeb={this.state.valueType}
                            />
                    ))
            }

            <LWModal
                nbGrilles={this.state.nbs}
                active={this.state.activeModal}
                typeb={this.state.typeModal}
                typec={this.state.valueType}
                handleCloseModal={this.handleCloseModal}
                handleChangeGrilleHas={this.handleChangeGrilleHas}
                researchNb={this.researchNb}
            />
                        {/** Menu gauche */}
                        <nav class="lw__menu">
                <ul class="lw__menu__list">
                    <li class="lw__menu__item">
                        <button
                            class="lw__menu__link"
                            title="Accueil - Loto win"
                            onClick={() => this.handleChangePage({
                                type: "accueil",
                                value: ""
                            })}
                        >
                            <IcomoonReact iconSet={iconSet} icon="home" />
                        </button>
                    </li>
                    <li class="lw__menu__item">
                        <button onClick={() => this.setState({
                            activeModal: true,
                            typeModal: "grilles"
                        })} class="lw__menu__link" title="Grilles">
                            <IcomoonReact iconSet={iconSet} icon="table2" />
                        </button>
                    </li>
                    <li class="lw__menu__item">
                        <button onClick={() => this.setState({
                            activeModal: true,
                            typeModal: "lots"
                        })} class="lw__menu__link" title="Lots">
                            <IcomoonReact iconSet={iconSet} icon="gift" />
                        </button>
                    </li>
                    <li class="lw__menu__item">
                        <button onClick={() => this.setState({
                            activeModal: true,
                            typeModal: "winner"
                        })} class="lw__menu__link" title="Gagnants">
                            <IcomoonReact iconSet={iconSet} icon="trophy" />
                        </button>
                    </li>
                    <li class="lw__menu__item">
                        <button onClick={() => this.setState({
                            activeModal: true,
                            typeModal: "boutique"
                        })} class="lw__menu__link" title='Boutique'>
                            <IcomoonReact iconSet={iconSet} icon="home3" />
                        </button>
                    </li>
                    <li class="lw__menu__item">
                        <button onClick={() => this.setState({
                            activeModal: true,
                            typeModal: "regles"
                        })} class="lw__menu__link" title='Règlement'>
                            <IcomoonReact iconSet={iconSet} icon="info" />
                        </button>
                    </li>
                </ul>
            </nav>
            {/** Menu droite */}
            <nav class="lw__menu lw__menu--right">
                <ul class="lw__menu__list">
                    <li class="lw__menu__item">
                        <button class="lw__menu__link" onClick={() => window.location.reload()}>
                            {/** Close */}
                            <IcomoonReact iconSet={iconSet} icon="cross" />
                        </button>
                    </li>
                    <li class="lw__menu__item lw__menu__item--credits">
                        <Credit nb={formatedCredit(this.state.nbs?.credits)} class="lw__menu__link lw__menu__link--credits" />
                    </li>
                    <li title={`${this.state.nbs?.super} super`} class="lw__menu__item lw__menu__item--super lw__menu__item--credits">
                        <IcomoonReact iconSet={iconSet} icon="table2" />
                        {this.state.nbs?.super} 
                    </li>
                    <li title={`${this.state.nbs?.maxi} maxi`} class="lw__menu__item lw__menu__item--maxi lw__menu__item--credits">
                        <IcomoonReact iconSet={iconSet} icon="table2" />
                        {this.state.nbs?.maxi} 
                    </li>
                    <li title={`${this.state.nbs?.mega} mega`} class="lw__menu__item lw__menu__item--mega lw__menu__item--credits">
                        <IcomoonReact iconSet={iconSet} icon="table2" />
                        {this.state.nbs?.mega} 
                    </li>
                    <li title={`${this.state.nbs?.extra} extra`} class="lw__menu__item lw__menu__item--extra lw__menu__item--credits">
                        <IcomoonReact iconSet={iconSet} icon="table2" />
                        {this.state.nbs?.extra} 
                    </li>
                </ul>
            </nav>
        </main>    
    </div>
    }
}

const mapStateToProps = state => {
    return {
        nbs: state.lwnbgrille,
        tensuper: state.lwgettensuper
    }
}

const mapDispatchToProps = {
    getLwNbGrilles,
    getLwTenSuper
}

export default connect(mapStateToProps, mapDispatchToProps)(LotoWin);