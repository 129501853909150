import { getDomain, config } from "../helpers";
import { GET_DATAS } from "./action-types";
import axios from "axios";

export function getDatas() {
    return function (dispatch) {
        let isGetting = false;
        let alert = {
            active: true,
            type: 'warning',
            title: 'Attente...',
            msg: 'Réception des données en cours...'
        };
        let datas = {
            analyse: null,
            anniv: null,
            annonce: [],
            event: null,
            lotof: null,
            sondage: null,
            video: [],
            jeu: []
        };

        axios.post(`${getDomain()}api/get-datas`, {
            "nb-annonces": 2,
            "nb-videos": 1,
            "nb-jeux": 1
        }, config)
            .then(res => {
                alert = {
                    active: false,
                    type: 'warning',
                    title: 'Données récupérées',
                    msg: 'Les données ont été récupérées avec succès'
                }
                isGetting = true;
                datas = res.data;
                dispatch({
                    type: GET_DATAS,
                    payload: {
                        alert: alert,
                        isGetting: isGetting,
                        datas: datas
                    }
                })
            })
            .catch(error => {
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Données non reçues',
                    msg: 'Les données n\'ont pas pu être reçues'
                }
                isGetting = true;
                console.log(error)
                dispatch({
                    type: GET_DATAS,
                    payload: {
                        alert: alert,
                        isGetting: isGetting,
                        datas: datas
                    }
                })
            })
    }
}