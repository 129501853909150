import React from "react";
import { Link } from "react-router-dom";
import Credit from "./Credits";

class CardJeu extends React.Component {
    render() {
        return <Link to={`/jeu-${this.props.jeu.id}`} class={`jeux__item ${this.props.classes}`} style={{zIndex: this.props.zindex}}>
            <img src={this.props.jeu.miniature} alt={`Miniature - ${this.props.jeu.title} - jeu de Tof La Win`} class="jeux__img" />
            <div class="jeux__content">
                <span class="jeux__jackpot">{this.props.jeu.jackpot}</span>
                {console.log(this.props.jeu)}
                <Credit nb={this.props.jeu.nbcredits} class="jeux__mise" />
            </div>
            <div class={this.props.bg && "jeux__bg--inactive"}></div>
    </Link>
    }
}

export default CardJeu;