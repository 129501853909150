import React from "react";
import { getPoker } from "../../actions/divers";
import { connect } from "react-redux";

class Poker extends React.Component {
    state = {
        alert: {
            active: true,
            type: 'warning',
            title: 'Recherche de données...',
            msg: 'Recherche du classement de poker en cours...'
        }
    }

    componentDidMount() {
        document.title = 'Tof La Win - Poker';
        this.props.getPoker();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.divpok != undefined &&
            this.props.divpok != prevProps.divpok &&
            this.props.divpok.alert != this.state.alert
        ) {
            this.setState({alert: this.props.divpok.alert});
        }
    }

    render() {
        return <main className="page">
            <h2 className="page__ttl">La Win Poker Cup</h2>
            <section className="classement">
                <ul className="classement__list">
                    {
                        this.props.divpok.datas.sort((a, b) => {
                            return b.points - a.points
                        }).map((item, index) => {
                            return <li className="classement__item classement__item--poker">
                                <span className="classement__num"></span>
                                <span className="classement__pseudo">{item.pseudo}</span>
                                <span className="classement__pts">{item.points} pts</span>
                            </li>
                        })
                    }
                </ul>
            </section>
        </main>
    }
}

const mapStateToProps = state => {
    return {
        divpok: state.divpok
    }
}

const mapDispatchToProps = {
    getPoker
}

export default connect(mapStateToProps, mapDispatchToProps)(Poker); 