import React from "react";
import { getSalons, getSalon } from "../../actions/tchat";
import { connect } from "react-redux";
import Avatar from "../Avatar";

class Salons extends React.Component {
    state = {
        salons: [],
        click: false
    }

    componentDidMount() {
        this.props.getSalons();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.tchsalons != undefined) {
            if (this.props.tchsalons.alert != prevProps.tchsalons.alert) {
                this.props.handleChangeAlert(this.props.tchsalons.alert);
            }
            if (this.props.tchsalons.datas != this.state.salons) {
                this.setState({
                    salons: this.props.tchsalons.datas
                })
            }
        }

        if (this.props.tchsalon != undefined && this.state.click) {
            if (this.props.tchsalon.alert != prevProps.tchsalon.alert) {
                this.props.handleChangeAlert(this.props.tchsalon.alert);
            }
            if (this.props.tchsalon.id != this.props.salon) {
                this.setState({click: false});
                this.props.handleChangeSalon(this.props.tchsalon.id, this.props.tchsalon.notif);
            }
        }
    } 

    render() {
        return this.state.salons.length == 0 ?
            <h3 className="tchat__empty">Aucun salon n'a été trouvé</h3> :
            <ul className="tchat__listusers">
                {
                    this.state.salons.map(salon => {
                        return <button key={`salon-${salon.id}`} className="tchat__listuser" onClick={() => {
                            this.props.handleChangeSalon(salon.id, salon.notif);
                            this.setState({click: true});
                        }}>
                            <Avatar
                                avatar={salon.user.avatar}
                                class="tchat__listuser__avatar"
                            />
                            <span className="tchat__listuser__pseudo">{salon.user.pseudo}</span>
                            {
                                salon.notif > 0 && <span className="tchat__listuser__notifsalon">{salon.notif}</span>
                            }
                        </button>
                    })
                }
            </ul>
    }
}

const mapStateToProps = state => {
    return {
        tchsalons: state.tchsalons,
        tchsalon: state.tchsalon
    }
}

const mapDispatchToProps = {
    getSalons,
    getSalon
}

export default connect(mapStateToProps, mapDispatchToProps)(Salons);