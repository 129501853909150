import React from "react";
import iconSet from "../../selection.json";
import IcomoonReact from "icomoon-react";

class Contact extends React.Component {
    componentDidMount() { 
        document.title = 'Tof La Win - Contacts';
    }
    render() {
        return <main className="page page--contact">
            <a href="mailto:contact@toflawin.fr" className="page__link" id="mailGeneral">
                <IcomoonReact iconSet={iconSet} icon="envelop" />contact@toflawin.fr
            </a>
            <ul className="page__list page__list--first">
                <h3 className="page__subttl">Tof La Win</h3>
                <li className="page__item">
                    <a href="mailto:toflawin@gmail.com" className="page__link">
                        <IcomoonReact iconSet={iconSet} icon="envelop" />toflawin@gmail.com
                    </a>
                </li>
                <li className="page__item">
                    <a href="https://www.youtube.com/channel/UCJK0YNZOMec3zqJjU1Gy8yg" className="page__link">
                        <IcomoonReact iconSet={iconSet} icon="youtube" />Tof La Win
                    </a>
                </li>
                <li className="page__item">
                    <a href="https://www.youtube.com/channel/UCoWWWOG4cHp3ERsBP3OaDUQ" className="page__link">
                        <IcomoonReact iconSet={iconSet} icon="youtube" />Tof 11.0
                    </a>
                </li>
                <li className="page__item">
                    <a href="https://www.youtube.com/channel/UCVqO9Dk78VHpvOIPEQ2pjtA" className="page__link">
                        <IcomoonReact iconSet={iconSet} icon="youtube" />La Win Family
                    </a>
                </li>
                <li className="page__item">
                    <a href="https://www.facebook.com/toflawinofficiel" className="page__link">
                        <IcomoonReact iconSet={iconSet} icon="facebook2" />Tof La Win
                    </a>
                </li>
                <li className="page__item">
                    <a href="https://www.instagram.com/toflawin/?hl=fr" className="page__link">
                        <IcomoonReact iconSet={iconSet} icon="instagram" />toflawin
                    </a>
                </li>
            </ul>
            <ul className="page__list page__list--second">
                <h3 className="page__subttl">Matt (créateur du site)</h3>
                <li className="page__item">
                    <a href="mailto: m.guimbretiere937@gmail.com" className="page__link">
                        <IcomoonReact iconSet={iconSet} icon="envelop" />m.guimbretiere937@gmail.com
                    </a>
                </li>
                <li className="page__item">
                    <a href="https://www.youtube.com/channel/UC8TasNJtTEhTeUxsAN95ezg" className="page__link">
                    <IcomoonReact iconSet={iconSet} icon="youtube" />Matthew</a>
                </li>
                <li className="page__item">
                    <a href="https://www.facebook.com/profile.php?id=100063266558537" className="page__link">
                        <IcomoonReact iconSet={iconSet} icon="facebook2" />Animatt
                    </a>
                </li>
                <li className="page__item">
                    <a href="https://www.instagram.com/matthew_guimb/" className="page__link">
                        <IcomoonReact iconSet={iconSet} icon="instagram" />matthew_guimb
                    </a>
                </li>
            </ul>
        </main>
    }
}

export default Contact;