import { getDomain, config } from "../helpers";
import {
    GET_VIDEOS
} from "./action-types";
import axios from "axios";

// Obtenir toutes les vidéos
export function getVideos() {
    return function (dispatch) {
        let isGetting = false;
        let alert = {
            active: true,
            type: 'warning',
            title: 'Réception des vidéos...',
            msg: "Réception des vidéos en cours..."
        }
        let videos = [];

        axios.get(`${getDomain()}videos/all`, config)
            .then(res => {
                alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                isGetting = true;
                dispatch({
                    type: GET_VIDEOS,
                    payload: {
                        alert: alert,
                        isGetting: isGetting,
                        videos: res.data.videos
                    }
                })
            })
            .catch(error => {
                alert = {
                    active: true,
                    type: 'error',
                    title: "Une erreur est survenue",
                    msg: "Veuillez contacter Matt pour signaler cette erreur (Code: V001)"
                }
                console.log(error);
                dispatch({
                    type: GET_VIDEOS,
                    payload: {
                        alert: alert,
                        isGetting: isGetting,
                        videos: videos
                    }
                })
            })
    }
}