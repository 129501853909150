import { getDomain, config } from "../helpers";
import axios from "axios";
import { GET_ANNONCES, GET_CALENDAR, GET_POKER, GET_STAT_TOF } from "./action-types";

// Obtenir les données du poker
export function getPoker() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Recherche de données...',
            msg: 'Recherche du classement de poker en cours...'
        };
        let datas = [];

        axios.post(`${getDomain()}poker/classement`, {}, config)
            .then(res => {
                alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                datas = res.data['classement'];

                dispatch({
                    type: GET_POKER,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Div001)"
                };
                dispatch({
                    type: GET_POKER,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
    }
}

// Obtenir les stats de Tof
export function getStatsTof() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Recherche de données...',
            msg: 'Recherche des statistiques de Tof en cours...'
        };
        let datas = [];

        axios.post(`${getDomain()}statistiques/tof`, {}, config)
            .then(res => {
                alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                datas = res.data;

                dispatch({
                    type: GET_STAT_TOF,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Div002)"
                };
                dispatch({
                    type: GET_STAT_TOF,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
    }
}

// Obtenir les annonces
export function getAnnonces() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Recherche de données...',
            msg: 'Recherche des annonces en cours...'
        };
        let datas = [];

        axios.post(`${getDomain()}annonces/all`, {}, config)
            .then(res => {
                alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                datas = res.data['annonces'];

                dispatch({
                    type: GET_ANNONCES,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Div003)"
                };
                dispatch({
                    type: GET_ANNONCES,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
    }
}

// Obtenir le calendrier
export function getCalendar() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Recherche de données...',
            msg: 'Recherche des données du calendrier en cours...'
        };
        let datas = [];

        axios.post(`${getDomain()}calendar`, {}, config)
            .then(res => {
                alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                datas = res.data['evs'];

                dispatch({
                    type: GET_CALENDAR,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Div004)"
                };
                dispatch({
                    type: GET_CALENDAR,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
    }
}