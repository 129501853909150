import React from "react";
import iconSet from "../../selection.json";
import IcomoonReact from "icomoon-react";
import CardJeu from "../../components/CardJeu";
import { getJeux } from "../../actions/jeux";
import { getDatasNbJeux } from "../../actions/profil";
import { connect } from "react-redux";
import Alert from "../../components/Alert";
import { Link } from "react-router-dom";

class Jeux extends React.Component {
    state = {
        alert: {
            active: false,
            type: '',
            title: '',
            msg: ''
        },
        jeux: [],
        jeuActif: 0
    }

    componentDidMount() {
        document.title = 'Tof La Win - Les jeux';
        this.setState({
            alert: {
                active: true,
                type: 'warning',
                title: 'Recherche des jeux...',
                msg: 'Recherche des jeux en cours...'
            }
        })
        this.props.getJeux();
        this.props.getDatasNbJeux();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.jeux.jeux != this.props.jeux.jeux &&
            this.props.jeux.jeux != this.state.jeux
        ) {
            this.setState({
                jeux: this.props.jeux.jeux
            })
        }

        if (
            this.props.jeux.alert != prevProps.jeux.alert &&
            this.props.jeux.alert != this.state.alert
        ) {
            this.setState({
                alert: this.props.jeux.alert
            })
        }

        if (
            this.props.datasnbjeu.alert != prevProps.datasnbjeu.alert &&
            this.props.datasnbjeu.alert != this.state.alert
        ) {
            this.setState({
                alert: this.props.datasnbjeu.alert
            })
        }
    } 

    handleChangeJeu(dir) {
        let newActu = this.state.jeuActif;

        if (dir == "g") {
            if (newActu == 0) {
                newActu = this.state.jeux.length - 1;
            } else {
                newActu -= 1;
            }
        }
        if (dir == "d") {
            if (newActu == this.state.jeux.length - 1) {
                newActu = 0;
            } else {
                newActu += 1;
            }
        }
        this.setState({jeuActif: newActu});
    }

    render() {
        return <section class="jeux page page--jeux">
            <h2 className="jeux__ttl">Les jeux</h2>
            <header className="page page--jeux__header">
                <Link to="/plus-de-credits">
                    Plus de crédits
                </Link>
                <hr />
                <span>Crédits : <b>{this.props.datasnbjeu.infos.credits != undefined ? this.props.datasnbjeu.infos.credits : 0}</b></span>
                <hr />
                <span>Points : <b>{this.props.datasnbjeu.infos.points != undefined ? this.props.datasnbjeu.infos.points : 0}</b></span>
                <hr />
                <span>Besace : <b>{this.props.datasnbjeu.infos.besace != undefined ? this.props.datasnbjeu.infos.besace : 0}</b></span>
            </header>
            {
                this.state.alert.active ?
                    <Alert class={`alert--${this.state.alert.type}`} ttl={this.state.alert.title}>{this.state.alert.msg}</Alert> : ""
            }
            {
                localStorage.userId == undefined ?
                <h3 className="jeux__empty">Vous ne pouvez pas jouer aux jeux si vous n'êtes pas connectés</h3> :
                <div className="jeux__content">
                {
                    this.state.jeux.length > 0 ?
                        this.state.jeux.map((jeu, index) => {
                            let tempClass = "";
                            let act = this.state.jeuActif;
                            let last = this.state.jeux.length - 1;
                            
                            // Test si c'est le premier jeu
                            if (index == 0) {
                                // Si actif == dernier, on le met juste après
                                if (act == last) {
                                    tempClass = "jeux__item--4";
                                } else {
                                    // Si actif == avant dernier, on le met à droite
                                    if (act == (last - 1)) {
                                        tempClass = "jeux__item--right";
                                    } else {
                                        // Si actif == 0, on le met au milieu
                                        if (act == 0) {
                                            tempClass = "jeux__item--3";
                                        } else {
                                            // Si actif == 1, on le met juste avant
                                            if (act == 1) {
                                                tempClass = "jeux__item--2";
                                            } else {
                                                // Si actif > 1, on le met à gauche
                                                if (act > 1) {
                                                    tempClass = "jeux__item--left";
                                                }
                                            }
                                        }
                                    }
                                }
                            } else {
                                // Test si c'est le second
                                if (index == 1) {
                                    // Si actif == dernier, on le met à droite
                                    if (act == last) {
                                        tempClass = "jeux__item--right";
                                    } else {
                                        // (Si actif == avant dernier ou si actif > 2), on le met à gauche
                                        if ((act == last - 1) || (act > 2)) {
                                            tempClass = "jeux__item--left";
                                        } else {
                                            // Si actif == 0, on le met juste après
                                            if (act == 0) {
                                                tempClass = "jeux__item--4";
                                            } else {
                                                // Si actif == 1, on le met au centre
                                                if (act == 1) {
                                                    tempClass = "jeux__item--3";
                                                } else {
                                                    // Si actif == 2, on le met juste avant
                                                    if (act == 2) {
                                                        tempClass = "jeux__item--2";
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    // Test si c'est l'avant dernier
                                    if ((this.state.jeux.length - 2) == index) {
                                        // Si actif == dernier, on la met juste avant
                                        if (act == last) {
                                            tempClass = "jeux__item--2";
                                        } else {
                                            // Si actif == avant dernier, on la met au milieu
                                            if (act == (last - 1)) {
                                                tempClass = "jeux__item--3";
                                            } else {
                                                // Si actif == 0, on la met à gauche
                                                if (act == 0) {
                                                    tempClass = "jeux__item--left";
                                                } else {
                                                    // (Si actif == 1 ou si actif < length - 2), on la met à droite
                                                    if ((act == 1) || (act < last - 2)) {
                                                        tempClass = "jeux__item--right";
                                                    } else {
                                                        // Si actif == length - 2, on la met juste après
                                                        if (act == last - 2) {
                                                            tempClass = "jeux__item--4";
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    } else {
                                        // Test si c'est le dernier
                                        if ((this.state.jeux.length - 1) == index) {
                                            // Si actif == dernier, on la met au milieu
                                            if (act == last) {
                                                tempClass = "jeux__item--3";
                                            } else {
                                                // Si actif == avant dernier, on la met juste après
                                                if (act == last - 1) {
                                                    tempClass = "jeux__item--4";
                                                } else {
                                                    // Si actif == 0, on la met juste avant
                                                    if (act == 0) {
                                                        tempClass = "jeux__item--2";
                                                    } else {
                                                        // Si actif == 1, on la met à gauche
                                                        if (act == 1) {
                                                            tempClass = "jeux__item--left";
                                                        } else {
                                                            // Si actif > avant dernier, on la met à droite
                                                            if (act < last - 1) {
                                                                tempClass = "jeux__item--right";
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        } else {
                                            // Sinon, traitement normal
                                            // Si actif == index, on met au milieu
                                            if (act == index) {
                                                tempClass = "jeux__item--3";
                                            } else {
                                                // Si actif == index - 1, on la met juste avant
                                                if (act == index - 1) {
                                                    tempClass = "jeux__item--4";
                                                } else {
                                                    // Si actif == index + 1, on la met juste après
                                                    if (act == index + 1) {
                                                        tempClass = "jeux__item--2";
                                                    } else {
                                                        // Si actif > index - 1, on la met à droite
                                                        if (act < index - 1) {
                                                            tempClass = "jeux__item--right";
                                                        } else {
                                                            // Si actif < index + 1, on la met à gauche
                                                            if (act > index + 1) {
                                                                tempClass = "jeux__item--left";
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            // 

                            // =================== VIEILLE version ========
                            /*if (
                                // Si le jeu est le premier ou second et que l'actu est dernier ou avant dernier
                                index <= 1 &&
                                (
                                    this.state.jeuActif ==  this.state.jeux.length ||
                                    this.state.jeuActif ==  this.state.jeux.length - 1
                                )
                            ) {
                                // Si le jeu est le premier et que l'actu est le dernier
                                if (
                                    index == 0 &&
                                    this.state.jeuActif ==  this.state.jeux.length
                                ) {
                                    tempClass = "jeux__item--4";
                                }
                                // Si c'est le premier jeu et que l'actu est l'avant dernier
                                if (
                                    index == 0 &&
                                    this.state.jeuActif ==  this.state.jeux.length - 1
                                ) {
                                    tempClass = "jeux__item--right";
                                }
                                // Si c'est le second jeu et que l'actu est le dernier
                                if (
                                    index == 1 &&
                                    this.state.jeuActif ==  this.state.jeux.length
                                ) {
                                    tempClass = "jeux__item--right";
                                }
                                // Si c'est le second jeu et que l'actu est l'avant dernier, on le laisse là où il devrait être
                                if (
                                    index == 1 &&
                                    this.state.jeuActif ==  this.state.jeux.length - 1
                                ) {
                                    tempClass = "jeux__item--left";
                                }
                            } else {
                                // Si le jeu est le dernier ou avant dernier et que l'actu est le premier ou second
                                if (
                                    index >=  this.state.jeux.length - 1 &&
                                    this.state.jeuActif <= 1
                                ) {
                                    // Si le jeu est le dernier et que l'actu est le premier
                                    if(
                                        index ==  this.state.jeux.length &&
                                        this.state.jeuActif == 0
                                    ) {
                                        tempClass = "jeux__item--2";
                                    }
                                    // Si le jeu est le dernier et que l'actu est le second
                                    if (
                                        index ==  this.state.jeux.length &&
                                        this.state.jeuActif == 1
                                    ) {
                                        tempClass = "jeux__item--left";
                                    }
                                    // Si le jeu est l'avant dernier et que l'actu est le premier
                                    if (
                                        index ==  this.state.jeux.length - 1 &&
                                        this.state.jeuActif == 0
                                    ) {
                                        tempClass = "jeux__item--left";
                                    }
                                    // Si le jeu est l'avant dernier et que l'actu est le second
                                    if (
                                        index ==  this.state.jeux.length  - 1 &&
                                        this.state.jeuActif == 1
                                    ) {
                                        tempClass = "jeux__item--right";
                                    }
                                }
    
                                // Si c'est lui actu
                                if (index == this.state.jeuActif) {
                                    tempClass = "jeux__item--3";
                                }
                                // Si le jeu est avant l'actu
                                if (index < this.state.jeuActif) {
                                    // Si y a un écart de 1
                                    if (this.state.jeuActif - index) {
                                        tempClass = "jeux__item--2";
                                    } else {
                                        tempClass = "jeux__item--left";
                                    }                            
                                }
                                // Si le jeu est avant l'actu
                                if (index > this.state.jeuActif) {
                                    // Si y a un écart de 1
                                    if (index - this.state.jeuActif) {
                                        tempClass = "jeux__item--4";
                                    } else {
                                        tempClass = "jeux__item--right";
                                    }                            
                                }
                            }
                            */
                            return <CardJeu
                                classes={tempClass}
                                jeu={jeu}
                                bg={this.state.jeuActif == index}
                            />})
                        : <h3 className="jeux__empty">Aucun jeu n'est disponible pour le moment</h3>
                }
                <div class="jeux__control">
                    <button onClick={() => this.handleChangeJeu("g")} class="jeux__btnctrl"><IcomoonReact iconSet={iconSet} icon="circle-left" /></button>
                    <button onClick={() => this.handleChangeJeu("d")} class="jeux__btnctrl"><IcomoonReact iconSet={iconSet} icon="circle-right" /></button>
                </div>
                </div>
            }
            <div className="jeux__space"></div>
        </section>
    }
}

const mapStateToProps = state => {
    return {
        jeux: state.jeux,
        datasnbjeu: state.datasnbjeu
    }
}

const mapDispatchToProps = {
    getJeux,
    getDatasNbJeux
}

export default connect(mapStateToProps, mapDispatchToProps)(Jeux);