import React from "react";

class Alert extends React.Component {
    // class, ttl, children
    render() {
        return <section className={`alert ${this.props.class}`}>
            <h2 className="alert__ttl">{this.props.ttl}</h2>
            <p className="alert__desc">
                {this.props.children}
            </p>
        </section>
    }
}

export default Alert;