import { getDomain, config } from "../helpers";
import { GET_JEU, GET_JEUX, SET_RES_JEU, GET_JEU_CLASSEMENT, GET_JEU_STATS, GET_JEU_GRAPH } from "./action-types";
import axios from "axios";

// ============ Jeux =============================
// Obtention de la liste des jeux
export function getJeux() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Recherche en cours...',
            msg: "Recherche des jeux en cours..."
        };
        let jeux = [];

        axios.post(`${getDomain()}jeux/all`, {}, config)
            .then(res => {
                if (res.data.jeux.length == 0) {
                    alert = {
                        active: true,
                        type: 'info',
                        title: 'Aucun jeux',
                        msg: "Aucun jeu n'est disponible pour le moment."
                    };
                } else {
                    alert = {
                        active: false,
                        type: '',
                        title: '',
                        msg: ''
                    };
                    jeux = res.data.jeux;
                }
                dispatch({
                    type: GET_JEUX,
                    payload: {
                        alert: alert,
                        jeux: jeux
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Vous n'avez pas pu recevir la liste des jeux. Veuillez contacter Matt (Code : Jeu001)"
                };
                dispatch({
                    type: GET_JEUX,
                    payload: {
                        alert: alert,
                        jeux: jeux
                    }
                })
            })
    }
}

// Jeu unique (todo à vérifier)
export function getJeu({ jeu }) {
    return function (dispatch) {
        let isGetting = false;
        let alert = {
            active: true,
            type: 'warning',
            title: 'Attente...',
            msg: "Réception des données du jeu en cours..."
        };

        axios.post(`${getDomain()}jeux/unique/${jeu}`, config)
            .then(temp => {
                let res = temp.data;
                console.log(res);
                alert = {
                    active: false,
                    type: null,
                    title: null,
                    msg: null
                }
                isGetting = true;
                dispatch({
                    type: GET_JEU,
                    payload: {
                        alert: alert,
                        isGetting: isGetting,
                        datas: res.jeu
                    }
                });
            })
            .catch(error => {
                isGetting = false;
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Jeu non reçu',
                    msg: "Les informations de ce jeu n'ont pas été réçue. Veuillez retentez ou contactez Matt."
                }
                dispatch({
                    type: GET_JEU,
                    payload: {
                        alert: alert,
                        isGetting: isGetting,
                        datas: null
                    }
                });
                console.log(error);
            })
    }
}

// Mis à jour des gains
export function setGains({type, value, nbcreditsmise, jeu}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Enregistrement des gains',
            msg: "Enregistrement des gains en cours..."
        };
        let next = false;
        let gains = {
            credits: 0,
            points: 0,
            besace: 0,
            roue: 0
        };
        let bigWin = false;

        axios.post(`${getDomain()}classement/set`, {
            credentials: localStorage.credentials,
            user: localStorage.userId,
            type: type,
            value: value,
            nbcreditsmise: nbcreditsmise,
            jeu: jeu
        }, config)
            .then(res => {
                if (res.data[0] == undefined) {
                    if (res.data.grosLot) {
                        alert = {
                            active: false,
                            type: 'success',
                            title: 'Gros gains !!',
                            msg: "Vous venez de gagner le gros lot de ce jeu ! Félicitation ! Veuillez contacter Matt pour le recevoir en utilisant le code " + res.data.codegroslot
                        };
                        next = true;
                        bigWin = true;
                    } else {
                        alert = {
                            active: false,
                            type: 'win',
                            title: '',
                            msg: ''
                        };
                        next = true;
                        gains = {
                            credits: res.data.credits,
                            points: res.data.points,
                            roue: res.data.roue,
                            besace: res.data.besace
                        }
                    }
                } else {
                    switch (res.data[0]) {
                        case "user_inexist":
                            alert = {
                                active: true,
                                type: 'error',
                                title: 'Une erreur est survenue',
                                msg: "Une erreur est survenue sur votre compte. Veuillez contacter Matt (Code : Jeu003)"
                            };
                            next = false;
                            break;
                        case "error":
                            alert = {
                                active: true,
                                type: 'error',
                                title: 'Données manquantes',
                                msg: "Certaines données sont manquantes. Veuillez contacter Matt (Code : Jeu004)"
                            }
                            next = false;
                            break;
                    }
                }
                
                dispatch({
                    type: SET_RES_JEU,
                    payload: {
                        alert: alert,
                        next: next,
                        gains: gains,
                        bigWin: bigWin
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Vous n'avez pas pu recevoir vos gains. Veuillez contacter Matt (Code : Jeu005)"
                };
                dispatch({
                    type: SET_RES_JEU,
                    payload: {
                        alert: alert,
                        next: next,
                        gains: gains
                    }
                })
            })
    }
}

// Obtenir classement de joueurs
export function getJeuClassement() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Recherche des données...',
            msg: 'Recherche du classement en cours...'
        };
        let datas = [];

        axios.post(`${getDomain()}classement/joueurs`, {}, config)
            .then(res => {
                alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                datas = res.data['statistiques'];

                dispatch({
                    type: GET_JEU_CLASSEMENT,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Vous n'avez pas pu recevoir le classement. Veuillez contacter Matt (Code : Jeu006)"
                };
                dispatch({
                    type: GET_JEU_CLASSEMENT,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
    }
}

// Obtenir les statistiques d'un joueur
export function getJeuStatsChiffres() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Recherche des données...',
            msg: 'Recherche de vos statistiques en cours...'
        };
        let datas = [];

        axios.post(`${getDomain()}statistiques/chiffres/${localStorage.userId}`, {}, config)
            .then(res => {
                alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                datas = res.data['statistiques'];

                dispatch({
                    type: GET_JEU_STATS,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Vous n'avez pas pu recevoir vos statistiques. Veuillez contacter Matt (Code : Jeu007)"
                };
                dispatch({
                    type: GET_JEU_STATS,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
    }
}

// Obtenir les stats graphique
export function getJeuStatsGraph() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Recherche de données...',
            msg: 'Recherche des statistiques en cours...'
        };
        let datas = [];

        axios.post(`${getDomain()}statistiques/graph/${localStorage.userId}`, {}, config)
            .then(res => {
                alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                datas = res.data;

                dispatch({
                    type: GET_JEU_GRAPH,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Vous n'avez pas pu recevoir vos statistiques. Veuillez contacter Matt (Code : Jeu008)"
                };
                dispatch({
                    type: GET_JEU_GRAPH,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
            
    }
}