import React from "react";
import { getProfil, editProfil } from "../../actions/profil";
import Alert from "../../components/Alert";
import Avatar from "../../components/Avatar";
import iconSet from "../../selection.json";
import IcomoonReact from "icomoon-react";
import { connect } from "react-redux";

class Profil extends React.Component {
    state = {
        alert: {
            active: false,
            type: '',
            title: '',
            msg: ''
        },
        updateAvatar: false,
        avatar: '',
        pseudo: '',
        mail: '',
        anniv: null,
        mdp: '',
        mdpconf: '',
        newsletter: false,
        nextedit: false
    }

    componentDidMount() {
        document.title = 'Tof La Win - Mon profil';
        this.props.getProfil();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.alert != this.props.redprofil.alert &&
            this.props.redprofil.alert != undefined &&
            this.props.redprofil.alert.active
        ) {
            this.setState({
                alert: this.props.redprofil.alert
            })
        }

        if (
            this.state.alert != this.props.rededitprofil.alert &&
            this.props.rededitprofil.alert != undefined &&
            this.props.rededitprofil.alert.active
        ) {
            this.setState({
                alert: this.props.rededitprofil.alert
            })
        }
        
        if (
            prevProps.redprofil != this.props.redprofil
        ) {
            this.setState({
                avatar: this.props.redprofil.profil.avatar,
                pseudo: this.props.redprofil.profil.pseudo,
                mail: this.props.redprofil.profil.email,
                anniv: this.props.redprofil.profil.anniv,
                newsletter: this.props.redprofil.profil.newsletter,
            })
        }

        if (
            this.props.rededitprofil.next &&
            this.props.rededitprofil.next != this.state.nextedit
        ) {
            this.props.getProfil();
            this.setState({
                nextedit: this.props.rededitprofil.next
            })
        }
    } 

    handleChange = (e) => {
        if (e.target.type == "checkbox") {
            this.setState({
                [e.target.name]: !this.state[e.target.name]
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    }

    getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            console.log("Called", reader);
            baseURL = reader.result;
            console.log(baseURL);
            resolve(baseURL);
          };
          console.log(fileInfo);
        });
    };

    handleEdit = (e) => {
        e.preventDefault();

        this.setState({
            alert: {
                active: true,
                type: 'warning',
                title: 'Enregistrement en cours...',
                msg: "Modification de vos données en cours"
            }
        })

        this.props.editProfil({
            email: this.state.mail,
            pseudo: this.state.pseudo,
            anniv: this.state.anniv,
            avatar: this.state.avatar,
            newsletter: this.state.newsletter,
            mdp: this.state.mdp,
            mdpconfirm: this.state.mdpconf
        });
    }

    handleDeconnexion = (e) => {
        e.preventDefault();

        localStorage.clear();
        window.location.href = "https://toflawin.fr";
    }

    handleDelete = (e) => {
        e.preventDefault();

        alert("Veuillez contacter Matt si vous souhaitez supprimer votre compte définitivement.")
    }

    render() {
        return <main className="page page--profil">
            <h2 className="page__ttl">Mes informations</h2>
            {
                this.state.alert.active &&
                <Alert class={`alert--${this.state.alert.type}`} ttl={this.state.alert.title}>{this.state.alert.msg}</Alert>
            }
            <form className="form">
                <div className="form__group form__group--avatar">
                    <label htmlFor="avatar" className="form__label">
                        <Avatar
                            avatar={this.state.avatar}
                            class="avatar--form"
                        />
                        Modifier ma <br />
                        photo de profil
                    </label>
                    <input type="text" name="avatar" style={{display: 'none'}} value={this.state.avatar}/>
                    <input type="file" hidden id="avatar" className="input" onChange={(e) => {
                        this.getBase64(e.target.files[0])
                        .then(result => {
                            e.target.files[0]["base64"] = result;
                          console.log("File Is", e.target.files[0]);
                        
                          this.setState({
                              avatar: result, updateAvatar: true
                          })
                        })
                        .catch(err => {
                          console.log(err);
                        });
                    } }/>
                </div>
                <div className="form__group">
                    <label htmlFor="pseudo" className="form__label">Pseudo</label>
                    <input
                        type="text"
                        name="pseudo"
                        id="pseudo"
                        placeholder="Pseudo"
                        className="input"
                        onChange={this.handleChange}
                        value={this.state.pseudo}
                    />
                </div>
                <div className="form__group">
                    <label htmlFor="mail" className="form__label">Adresse mail</label>
                    <input
                        type="mail"
                        name="mail"
                        id="mail"
                        placeholder="exemple@mail.com"
                        className="input"
                        onChange={this.handleChange}
                        value={this.state.mail}
                    />
                </div>
                <div className="form__group">
                    <label htmlFor="anniv" className="form__label">Anniversaire</label>
                    <input
                        type="date"
                        name="anniv"
                        id="anniv"
                        placeholder="Anniversaire"
                        className="input"
                        onChange={this.handleChange}
                        value={this.state.anniv}
                    />
                </div>
                <div className="form__group">
                    <label htmlFor="mdp" className="form__label">Mot de passe</label>
                    <input
                        type="password"
                        name="mdp"
                        id="mdp"
                        placeholder="Mot de passe"
                        className="input"
                        onChange={this.handleChange}
                        value={this.state.mdp}
                    />
                    <span class="form__help">Si vous ne souhaitez pas modifier votre mot de passe, laissez ce champs vide</span>
                </div>
                <div className="form__group">
                    <label htmlFor="mdpconf" className="form__label">Confirmation mdp</label>
                    <input
                        type="password"
                        name="mdpconf"
                        id="mdpconf"
                        placeholder="Confirmation mdp"
                        className="input"
                        onChange={this.handleChange}
                        value={this.state.mdpconf}
                    />
                </div>
                <div className="form__group form__group--inline">
                    <input
                        type="checkbox"
                        name="newsletter"
                        id="newsletter"
                        class="form__checkbox"
                        onChange={this.handleChange}
                        value={this.state.newsletter}
                    />
                    <label for="newsletter" class="form__help">Je m'abonne à la newsletter</label>
                </div>
                <button type='submit' className="btn btn--mb btn--secondary" onClick={this.handleEdit}>Modifier <IcomoonReact iconSet={iconSet} icon="pencil" /></button>
                <button className='btn btn--mb' onClick={this.handleDeconnexion}>Se déconnecter <IcomoonReact iconSet={iconSet} icon="unlocked" /></button>
                <button className="btn btn--error" onClick={this.handleDelete}>Supprimer mon compte définitivement</button>
            </form>
        </main>
    }
}

const mapStateToProps = state => {
    return {
        redprofil: state.profil,
        rededitprofil: state.editprofil,
    }
}

const mapDispatchToProps = {
    getProfil,
    editProfil
}

export default connect(mapStateToProps, mapDispatchToProps)(Profil);