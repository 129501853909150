import React from 'react';
import { getJeuStatsChiffres, getJeuStatsGraph } from '../../actions/jeux';
import { connect } from "react-redux";
import Fiche from '../../components/Fiche';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Line } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
};
const labels = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

class Statistiques extends React.Component {
    state = {
        alert: {
            active: true,
            type: 'warning',
            title: 'Recherche des données...',
            msg: 'Recherche de vos statistiques en cours...'
        },
        data: {
            labels,
            datasets: []
        }
    }

    componentDidMount() {
        document.title = 'Tof La Win - Statistiques';
        this.props.getJeuStatsChiffres();
        this.props.getJeuStatsGraph();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.jeustachi != undefined &&
            this.props.jeustachi != prevProps.jeustachi &&
            this.props.jeustachi.alert != this.state.alert
        ) {
            this.setState({
                alert: this.props.jeustachi.alert
            })
        }

        if (
            this.props.jeustagra != undefined &&
            this.props.jeustagra != prevProps.jeustagra
        ) {
            if (this.props.jeustagra.alert != this.state.alert) {
                this.setState({
                    alert: this.props.jeustagra.alert
                })
            }

            // Définir les datasets
            if (this.props.jeustagra.alert.type != 'error' && this.props.jeustagra.alert.type != 'warning') {
                let datasets = [{
                    label: 'Gains',
                    data: this.props.jeustagra.datas.gains,
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },{
                    label: 'Mises',
                    data: this.props.jeustagra.datas.mise,
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                }];
    
                this.setState({
                    data: {
                        labels,
                        datasets: datasets
                    }
                })
            }
        }
    }

    render() {
        return <main className="page">
            <h2 className="page__ttl">Vos statistiques</h2>
            {/** todo ajouter alert */}
            <section className="page__listfiche">
                <Fiche nb={this.props.jeustachi?.datas.nb_tickets} ttl="Tickets grattés" subttl={null} />
                <Fiche nb={this.props.jeustachi?.datas.gains} ttl="De gains" subttl={null} />
                <Fiche nb={this.props.jeustachi?.datas.mise} ttl="De mise" subttl={null} />
            </section>
            <section className="page__graphique">
                {/** todo recherche graph librairie */}
                <Line options={options} data={this.state.data} />;
            </section>
        </main>
    }
}

const mapStateToProps = state => {
    return {
        jeustachi: state.jeustachi,
        jeustagra: state.jeustagra
    }
}

const mapDispatchToProps = {
    getJeuStatsChiffres,
    getJeuStatsGraph
}

export default connect(mapStateToProps, mapDispatchToProps)(Statistiques);