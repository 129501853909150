import { getDomain, config } from "../helpers";
import axios from "axios";
import { LW_GET_GRILLES, LW_GET_NB_GRILLES, LW_GET_NB_GR_TYPE, LW_GET_SHOP, LW_GET_TEN_SUPER, LW_GET_TIRAGE, LW_SEND_GRILLE, LW_SEND_GRILLE_TIRAGE, LW_ADD_SHOP, LW_GET_LOTS, LW_GET_WINNER } from "./action-types";

export function getLwGrilles() {
    return function (dispatch) {
        let isGetting = false;
        let alert = {
            active: true,
            type: 'warning',
            title: 'Attente...',
            msg: 'Recherche des grilles en cours...'
        };
        let grilles = [];

        axios.post(`${getDomain()}api/lw/grilles`, {
            "user": localStorage['userId']
        }, config)
            .then(res => {
                alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                dispatch({
                    type: LW_GET_GRILLES,
                    payload: {
                        alert: alert,
                        isGetting: true,
                        grilles: res.data
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Vous n'avez pas reçu vos grilles. Veuillez contacter Matt (Code : Lw001)"
                };
                dispatch({
                    type: LW_GET_GRILLES,
                    payload: {
                        alert: alert,
                        isGetting: true,
                        grilles: grilles
                    }
                })
            })
    }
}

export function getLwNbGrilles() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Réception des données...',
            msg: 'Réception des données en cours...'
        };
        let nbs = {};
        axios.post(`${getDomain()}api/lw/nbgrilles`, {
            userId: localStorage['userId']
        }, config)
            .then(res => {
                alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                dispatch({
                    type: LW_GET_NB_GRILLES,
                    payload: {
                        alert: alert,
                        nbs: res.data
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Vous n'avez pas reçu vos nombres de grilles. Veuillez contacter Matt (Code : Lw001)"
                };
                dispatch({
                    type: LW_GET_NB_GRILLES,
                    payload: {
                        alert: alert,
                        nbs: nbs
                    }
                })
            })
    }
}

export function sendLwGrilleTirage({type}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Envoi de données...',
            msg: 'Envoie des grilles en cours...'
        };

        axios.post(`${getDomain()}api/lw/send`, {
            type: type,
            userId: localStorage.userId
        }, config)
            .then(res => {
                if (res.data != "ok") {
                    alert = {
                        active: true,
                        type: 'error',
                        title: 'Une erreur est survenue',
                        msg: res.data
                    };
                } else {
                    alert = {
                        active: false,
                        type: '',
                        title: '',
                        msg: ''
                    };
                }
                dispatch({
                    type: LW_SEND_GRILLE_TIRAGE,
                    payload: {
                        alert: alert,
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Vous n'avez pas reçu vos nombres de grilles. Veuillez contacter Matt (Code : Lw001)"
                };
                dispatch({
                    type: LW_SEND_GRILLE_TIRAGE,
                    payload: {
                        alert: alert,
                    }
                })
            })
    }
}

export function getLwTenSuper() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Réception de 10 grilles super',
            msg: 'Réception de 10 grilles super en cours...'
        }
        axios.post(`${getDomain()}api/lw/add-super`, {
            userId: localStorage.userId
        }, config)
        .then(res => {
            alert = {
                active: false,
                type: '',
                title: '',
                msg: ''
            };
            localStorage.getLast10 = new Date().getTime();
            dispatch({
                type: LW_GET_TEN_SUPER,
                payload: {
                    alert: alert,
                }
            })
        })
        .catch(error => {
            console.log(error);
            alert = {
                active: true,
                type: 'error',
                title: 'Une erreur est survenue',
                msg: "Vous n'avez pas reçu vos nombres de grilles. Veuillez contacter Matt (Code : Lw001)"
            };
            dispatch({
                type: LW_GET_TEN_SUPER,
                payload: {
                    alert: alert,
                }
            })
        })
    }
}

export function getLwNbGrType({type}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Réception des données...',
            msg: 'Réception des données en cours...'
        };
        let nbs = {};
        axios.post(`${getDomain()}api/lw/nbgrille`, {
            userId: localStorage['userId'],
            type: type
        }, config)
            .then(res => {
                alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                dispatch({
                    type: LW_GET_NB_GR_TYPE,
                    payload: {
                        alert: alert,
                        nbs: res.data
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Vous n'avez pas reçu vos nombres de grilles. Veuillez contacter Matt (Code : Lw001)"
                };
                dispatch({
                    type: LW_GET_NB_GR_TYPE,
                    payload: {
                        alert: alert,
                        nbs: nbs
                    }
                })
            })
    }
}

export function sendLwGrille({type, numeros, bonus, saveTirage}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Envoi de données',
            msg: 'Envoi de la grille en cours...'
        };
        let tempNumeros = [];
        numeros.forEach(num => {
            tempNumeros.push(num);
        })
        bonus.forEach(num => {
            tempNumeros.push(num);
        })
        axios.post(`${getDomain()}api/lw/faire-grille`, {
            type: type,
            numeros: tempNumeros,
            userId: localStorage['userId'],
            "save-tirage": saveTirage
        }, config)
            .then(res => {
                alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                dispatch({
                    type: LW_SEND_GRILLE,
                    payload: {
                        alert: alert,
                        isSend: true,
                        nbHasGrille: res.data.nbHasGrille
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Vous n'avez pas pu enregistrer cette grille. Veuillez contacter Matt (Code : Lw001)"
                };
                dispatch({
                    type: LW_SEND_GRILLE,
                    payload: {
                        alert: alert,
                        isSend: false,
                        nbHasGrille: null
                    }
                })
            })
    }
}

export function getLwShop() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Réception des données...',
            msg: 'Réception des données de la boutique'
        };
        let datas = [];

        axios.post(`${getDomain()}api/lw/boutique`, {}, config)
            .then(res => {
                datas = res.data;
                alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                dispatch({
                    type: LW_GET_SHOP,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Vous n'avez pas pu enregistrer cette grille. Veuillez contacter Matt (Code : Lw001)"
                };
                dispatch({
                    type: LW_GET_SHOP,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
    }
}

export function getLwLots() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Recherche de données',
            msg: "Recherche de données en cours..."
        };
        let lots = [];

        axios.post(`${getDomain()}api/lw/lots`, {}, config) 
            .then(res => {
                lots = res.data;
                alert = {
                    active: true,
                    type: 'success',
                    title: 'Lots reçus',
                    msg: 'Vous avez obtenus les informations des lots'
                };
                dispatch({
                    type: LW_GET_LOTS,
                    payload: {
                        alert: alert,
                        lots: lots
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Vous n'avez pas pu recevoir ces données. Veuillez contacter Matt (Code : Lw001)"
                };
                dispatch({
                    type: LW_GET_LOTS,
                    payload: {
                        alert: alert,
                    }
                })
            })
    }
}

export function achatLwGrille({shopId, type}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Achat de grille...',
            msg: 'Achat de grille en cours...'
        };

        axios.post(`${getDomain()}api/lw/shop`, {
            type: type,
            'shop-id': shopId,
            'userId': localStorage.userId
        }, config)
        .then(res => {
            let datas = res.data;
            if (datas.alert != null) {
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Manque de crédits',
                    msg: "Vous n'avez pas assez de crédits."
                };
            } else {
                alert = {
                    active: true,
                    type: 'success',
                    title: 'Grilles achetées',
                    msg: 'Votre achat a bien été effectué'
                }
            }
            
            dispatch({
                type: LW_ADD_SHOP,
                payload: {
                    alert: alert,
                }
            })
        })
        .catch(error => {
            console.log(error);
            alert = {
                active: true,
                type: 'error',
                title: 'Une erreur est survenue',
                msg: "Vous n'avez pas pu acheter ces grilles. Veuillez contacter Matt (Code : Lw001)"
            };
            dispatch({
                type: LW_ADD_SHOP,
                payload: {
                    alert: alert,
                }
            })
        })
    }
}

export function getLwWinner() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Réception de données',
            msg: 'Réception de données en cours'
        };
        let winners = [];

        axios.post(`${getDomain()}api/lw/winner`, {}, config)
            .then(res => {
                alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                winners = res.data;
                dispatch({
                    type: LW_GET_WINNER,
                    payload: {
                        alert: alert,
                        winners: winners
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Vous n'avez pas pu recevoir les gagnants. Veuillez contacter Matt (Code : Lw001)"
                };
                dispatch({
                    type: LW_GET_WINNER,
                    payload: {
                        alert: alert,
                        winners: winners
                    }
                })
            })
    }
}

export function getLwTirage({type}) {
    return function (dispatch) {
        let isGetting = false;
        let alert = {
            active: true,
            type: 'warning',
            title: 'Réception des données...',
            msg: 'Réception des données en cours...'
        };
        let grilles = [];
        let tirage = null;
        let gains = {};

        axios.post(`${getDomain()}api/lw/tirage`, {
            type: type,
            userId: localStorage['userId']
        })
            .then(res => {
                alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                dispatch({
                    type: LW_GET_TIRAGE,
                    payload: {
                        alert: alert,
                        isGetting: true,
                        grilles: res.data.grilles,
                        tirage: res.data.tirage,
                        gains: res.data.gains
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Vous n'avez pas reçu les données du tirage. Veuillez contacter Matt (Code : Lw001)"
                };
                dispatch({
                    type: LW_GET_TIRAGE,
                    payload: {
                        alert: alert,
                        isGetting: true,
                        grilles: grilles,
                        tirage: tirage,
                        gains: gains
                    }
                })
            })
    }
}