import React from "react";
import iconSet from "../../selection.json";
import IcomoonReact from "icomoon-react";
import Avatar from "../Avatar";
import { getDate } from "../../helpers";
import { getLwWinner } from "../../actions/lotowin";
import { connect } from "react-redux";

class LWWinner extends React.Component {
    state = {
        winners: []
    };

    componentDidMount() {
        this.props.getLwWinner();
    }

    componentDidUpdate(prevProps, prevState) {
        console.log(this.props.winners)
        if (
            this.props.winners != undefined &&
            this.props.winners?.winners != this.state.winners
        ) {
            this.setState({winners: this.props.winners?.winners});
        }
    } 

    render() {
        return <section className="lw__wi lw__mod">
            <h2 className={`lw__fg__ttl`}>
                Les gagnants
            </h2>
            <ul className="lw__wi__list">
                {
                    this.state.winners.length > 0 ?
                        this.state.winners.map(winner => {
                            return <li className={`lw__wi__item`}>
                                <Avatar
                                    avatar={winner.avatar}
                                    class="lw__wi__avatar"
                                />
                                <span className="lw__wi__pseudo">{winner.pseudo}</span>
                                <span className="lw__wi__date">{getDate(winner.date.date)}</span>
                                <span className={`lw__wi__lot lw__wi__lot--${winner.type}`}>{winner.lot}</span>
                            </li>
                        }) : <h3 className="lw__wi__empty">Aucun gagnants pour le moment</h3>
                }
            </ul>
        </section>
    }
}

const mapStateToProps = state => {
    return {
        winners: state.lwwinners
    }
}

const mapDispatchToProps = {
    getLwWinner
}

export default connect(mapStateToProps, mapDispatchToProps)(LWWinner);