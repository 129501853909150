import React from "react";
import video from "../images/credits.mp4";

class Win extends React.Component {
    render() {
        return <section className="win__fond">
            <div className="win__zone">
                <video autoplay className="win__vid">
                    <source src={video}/>
                </video>
                <h2 className="win__lot">{this.props.lot}</h2>
            </div>
            <button className="win__btn btn" onClick={() => this.props.handleClose()}>Fermer</button>
        </section>
    }
}

export default Win;