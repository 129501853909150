import { GET_JEU, GET_JEUX, SET_RES_JEU, GET_JEU_CLASSEMENT, GET_JEU_STATS, GET_JEU_GRAPH } from "../actions/action-types";

const initialState = {
    alert: {
        active: false,
        type: null,
        title: null,
        msg: null
    },
    isGetting: false,
    datas: null
};
const initialStateDatas = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
    datas: []
};

export function JeuReducer(state = initialState, action) {
    switch (action.type) {
        case GET_JEU:
            return {
                alert: action.payload.alert,
                isGetting: action.payload.isGetting,
                datas: action.payload.datas
            }
        default:
            return state;
    }
}

export function GetJeuxReducer(state = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
    jeux: []
}, action) {
    switch (action.type) {
        case GET_JEUX:
            return {
                alert: action.payload.alert,
                jeux: action.payload.jeux
            }
        default:
            return state;
    }
}

/*
export function GetJeuReducer(state = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
    jeu: null
}, action) {
    switch (action.type) {
        case GET_JEU:
            return {
                alert: action.payload.alert,
                jeu: action.payload.jeu
            }
        default:
            return state;
    }
}
*/

export function SetGainReducer(state = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
    next: false,
    gains: {
        credits: 0,
        points: 0,
        roue: 0,
        besace: 0
    },
    bigWin: false
}, action) {
    switch (action.type) {
        case SET_RES_JEU:
            return {
                alert: action.payload.alert,
                next: action.payload.next,
                gains: action.payload.gains,
                bigWin: action.payload.bigWin
            }
        default:
            return state;
    }
}

export function GetJeuClassementReducer(state = initialStateDatas, action) {
    switch (action.type) {
        case GET_JEU_CLASSEMENT:
            return {
                alert: action.payload.alert,
                datas: action.payload.datas
            }
        default:
            return state;
    }
}

export function GetJeuStatsChiffresReducer(state = initialStateDatas, action) {
    switch (action.type) {
        case GET_JEU_STATS:
            return {
                alert: action.payload.alert,
                datas: action.payload.datas
            }
        default:
            return state;
    }
}

export function GetJeuStatsGraphReducer(state = initialStateDatas, action) {
    switch (action.type) {
        case GET_JEU_GRAPH:
            return {
                alert: action.payload.alert,
                datas: action.payload.datas
            }
        default:
            return state;
    }
}