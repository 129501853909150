import React from "react";
import Avatar from "../Avatar";
import { getDateTime } from "../../helpers";
import iconSet from "../../selection.json";
import IcomoonReact from "icomoon-react";

class Message extends React.Component {
    render() {
        return <article class="message">
            <span class="message__date">{getDateTime(this.props.message.date.date)}</span>
            <div class="message__main">
                <div class="message__profile">
                    <Avatar
                        avatar={this.props.user.avatar}
                        class="message__avatar"
                    />
                    <span class="message__pseudo">{this.props.user.pseudo}</span>
                </div>
                <div class="message__content">
                    <img src={this.props.image} alt="" class="message__image" />
                    <p class="message__text">{this.props.message.message}</p>
                </div>
                {
                    this.props.user.id == localStorage.userId &&
                    <button className="message__del" onClick={() => this.props.handleDeleteMessage(this.props.message.id)}><IcomoonReact iconSet={iconSet} icon="bin" /></button>
                }
            </div>
        </article>
    }
}

export default Message;