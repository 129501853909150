import React from "react";

class Fiche extends React.Component {
    render() {
        return <article className="fiche fiche--big">
            <span className="fiche__maincontent">{this.props.nb}</span>
            <h2 className="fiche__ttl">{this.props.ttl}</h2>
            <h3 className="fiche__subttl">{this.props.subttl}</h3>
        </article>
    }
}

export default Fiche;