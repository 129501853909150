import React from "react";
import imgMenu from "../../images/jeux/lotowin/regles/menu.png";
import iconSet from "../../selection.json";
import IcomoonReact from "icomoon-react";
import Credit from "../Credits";

class LWRegles extends React.Component {
    state = {
        page: 1
    }

    render() {
        return <section className="lw__ru lw__mod">
            <h2 className={`lw__ru__ttl`}>
                Règles de LotoWin
            </h2>
            <div className="lw__ru__bigcontent">
                {/** CONCEPT DU JEU */}
                <div className={`lw__ru__sec ${this.state.page == 1 ? 'lw__ru__sec--center' : 'lw__ru__sec--left'}`}>
                    <h3 className="lw__ru__subttl">CONCEPT DU JEU</h3>
                    <div className="lw__ru__content">
                        <p className="lw__ru__txt">Bienvenue sur <strong>LotoWin</strong> ! Jeu 100% gratuit où vous pourrez gagner des tickets à gratter FDJ ou de l'argent en Paysafecard.</p>
                        <p className="lw__ru__txt">Pour jouer, rien de plus simple ! <strong>Faites vos grilles</strong> sur l'une des parties de Lotowin de votre choix et attendez le prochain tirage. Si vos numéros sont bons, vous gagnerez automatiquement !</p>
                    </div>
                </div>
                {/** INTERFACE */}
                <div className={`lw__ru__sec ${this.state.page == 2 ? 'lw__ru__sec--center' : (this.state.page < 2 ? 'lw__ru__sec--right' : 'lw__ru__sec--left')}`}>
                    <h3 className="lw__ru__subttl">L'INTERFACE</h3>
                    <div className="lw__ru__content">
                        <p className="lw__ru__txt">L'interface peut paraître compliquer. Voici donc quelques explications :</p>
                        <img src={imgMenu} alt="Image du menu de Lotowin" className="lw__ru__img" />
                        <ul className="lw__ru__list">
                            <p className="lw__ru__txt">La partie gauche du menu possède :</p>
                            <li className="lw__ru__item">
                                <IcomoonReact iconSet={iconSet} icon="home" />
                                <span className="lw__ru__subtxt">Bouton pour accéder à l'accueil de Lotowin</span>
                            </li>
                            <li className="lw__ru__item">
                                <IcomoonReact iconSet={iconSet} icon="table2" />
                                <span className="lw__ru__subtxt">Bouton pour accéder à vos précédentes grilles enregistrées</span>
                            </li>
                            <li className="lw__ru__item">
                                <IcomoonReact iconSet={iconSet} icon="gift" />
                                <span className="lw__ru__subtxt">Bouton pour accéder aux lots pouvant être gagnés</span>
                            </li>
                            <li className="lw__ru__item">
                                <IcomoonReact iconSet={iconSet} icon="trophy" />
                                <span className="lw__ru__subtxt">Bouton pour accéder à la liste des grands gagnants</span>
                            </li>
                            <li className="lw__ru__item">
                                <IcomoonReact iconSet={iconSet} icon="home3" />
                                <span className="lw__ru__subtxt">Bouton pour accéder à la boutique pour acheter des grilles supplémentaires</span>
                            </li>
                            <li className="lw__ru__item">
                                <IcomoonReact iconSet={iconSet} icon="info" />
                                <span className="lw__ru__subtxt">Bouton pour accéder à la page de règles</span>
                            </li>
                            <p className="lw__ru__txt">La partie droite du menu possède :</p>
                            <li className="lw__ru__item">
                                <IcomoonReact iconSet={iconSet} icon="cross" />
                                <span className="lw__ru__subtxt">Bouton qui permet de fermer le jeu</span>
                            </li>
                            <li className="lw__ru__item">
                                <Credit nb={"00"} class="lw__menu__link lw__menu__link--credits lw__ru__credits" />
                                <span className="lw__ru__subtxt">Indication de votre nombre de crédits</span>
                            </li>
                            <li className="lw__ru__item lw__ru__item--super">
                                <span class="lw__menu__item lw__menu__item--super lw__menu__item--credits lw__ru__credits">
                                    <IcomoonReact iconSet={iconSet} icon="table2" />
                                    100
                                </span>
                                <span className="lw__ru__subtxt">Indication de votre nombre de grilles "super" jouable</span>
                            </li>
                            <li className="lw__ru__item lw__ru__item--maxi">
                                <span class="lw__menu__item lw__menu__item--maxi lw__menu__item--credits lw__ru__credits">
                                    <IcomoonReact iconSet={iconSet} icon="table2" />
                                    100
                                </span>
                                <span className="lw__ru__subtxt">Indication de votre nombre de grilles "maxi" jouable</span>
                            </li>
                            <li className="lw__ru__item lw__ru__item--mega">
                                <span class="lw__menu__item lw__menu__item--mega lw__menu__item--credits lw__ru__credits">
                                    <IcomoonReact iconSet={iconSet} icon="table2" />
                                    100
                                </span>
                                <span className="lw__ru__subtxt">Indication de votre nombre de grilles "mega" jouable</span>
                            </li>
                            <li className="lw__ru__item lw__ru__item--extra">
                                <span class="lw__menu__item lw__menu__item--extra lw__menu__item--credits lw__ru__credits">
                                    <IcomoonReact iconSet={iconSet} icon="table2" />
                                    100
                                </span>
                                <span className="lw__ru__subtxt">Indication de votre nombre de grilles "extra" jouable</span>
                            </li>
                        </ul>
                    </div>
                </div>
                {/** FAIRE UNE GRILLE */}
                <div className={`lw__ru__sec ${this.state.page == 3 ? 'lw__ru__sec--center' : (this.state.page < 3 ? 'lw__ru__sec--right' : 'lw__ru__sec--left')}`}>
                    <h3 className="lw__ru__subttl">FAIRE UNE GRILLE</h3>
                    <div className="lw__ru__content">
                        <p className="lw__ru__txt">Afin de participer à LotoWin, il faut remplir des grilles avec 7 numéros allant de 1 à 70 et 3 numéros bonus.</p>
                        <p className="lw__ru__txt">Lors de l'enregistrement de votre grille, vous pouvez choisir soit de l'enregistrer pour un tirage, ou pas. Auquel cas, vous pourrez l'envoyer plus tard pour un autre tirage.</p>
                        <p className="lw__ru__txt">Vous ne pouvez pas enregistrer plus de 100 grilles pour un tirage et vous ne pouvez pas enregistrer plus de 100 grilles sans les liées à un tirage. Vous pouvez donc avoir 200 grilles d'enregistrer simultanément (100 pour le prochain tirage et 100 que vous pourrez envoyer après le tirage).</p>
                        <p className="lw__ru__txt">Afin d'enregistrer une grille, vous devez d'abord sélectionner vos numéros de manière choisies ou aléatoire. Ensuite, il faut cliquer sur "enregistrer". Une publicité de 10 secondes se lancera et votre grille sera enregistrée après ces 10 secondes.</p>
                    </div>
                </div>
                {/** REGARDER UN TIRAGE */}
                <div className={`lw__ru__sec ${this.state.page == 4 ? 'lw__ru__sec--center' : (this.state.page < 4 ? 'lw__ru__sec--right' : 'lw__ru__sec--left')}`}>
                    <h3 className="lw__ru__subttl">REGARDER UN TIRAGE</h3>
                    <div className="lw__ru__content">
                        <p className="lw__ru__txt">Pour voir un tirage, il faut se rendre sur la page d'accueil de LotoWin au moins 20 secondes avant le début du tirage. Un bouton "Voir le tirage" apparaîtera.</p>
                        <p className="lw__ru__txt">Le LotoWin "super" se déroule toutes les 15 minutes.</p>
                        <p className="lw__ru__txt">Le LotoWin "maxi" se déroule toutes les heures.</p>
                        <p className="lw__ru__txt">Le LotoWin "mega" se déroule quotidiennement à 8h00 et à 20h00.</p>
                        <p className="lw__ru__txt">Le LotoWin "extra" se déroule quotidiennement à 21h00.</p>
                    </div>
                </div>
                {/** OBTENTION DE GRILLES */}
                <div className={`lw__ru__sec ${this.state.page == 5 ? 'lw__ru__sec--center' : (this.state.page < 5 ? 'lw__ru__sec--right' : 'lw__ru__sec--left')}`}>
                    <h3 className="lw__ru__subttl">OBTENTION DE GRILLES</h3>
                    <div className="lw__ru__content">
                        <p className="lw__ru__txt">Il existe de manière d'obtenir des grilles :</p>
                        <p className="lw__ru__txt">- Vous obtiendrez automatiquement 10 grilles "super" toutes les 30 secondes. (Dans la limite de 100 grilles simultanées).</p>
                        <p className="lw__ru__txt">- Pour les grilles "maxi", "mega" et "extra", il faut se les procurer via la boutique. (Aucune limite mais le prix et la quantité des produits pourront subir une modification en cas de besoin).</p>
                    </div>
                </div>
                {/** OBTENTION DES LOTS */}
                <div className={`lw__ru__sec ${this.state.page == 6 ? 'lw__ru__sec--center' : 'lw__ru__sec--right'}`}>
                    <h3 className="lw__ru__subttl">OBTENTION DES LOTS</h3>
                    <div className="lw__ru__content">
                        <p className="lw__ru__txt">Il existe deux types de lots :</p>
                        <p className="lw__ru__txt">- Les lots virtuels : Sont obtenus automatiquement lors du tirage. Il s'agit des crédits et des grilles LotoWin.</p>
                        <p className="lw__ru__txt">- Les lots matériels : Sont obtenus suite à votre demande écrite par mail à <strong>lachainedematt1@gmail.com</strong>. Il s'agit des gros lots tels que les goodies Tof La Win, les tickets à gratter ou les tickets en Paysafecard.</p>
                        <p className="lw__ru__txt">La valeur des lots pourront potentiellement être modifiées dans l'avenir.</p>
                    </div>
                </div>
            </div>
            <nav className="lw__ru__nav">
                <button
                    className={`lw__ru__navbtn ${this.state.page == 1 ? 'lw__ru__navbtn--active' : ''}`}
                    onClick={() => this.setState({page: 1})}
                ></button>
                <button
                    className={`lw__ru__navbtn ${this.state.page == 2 ? 'lw__ru__navbtn--active' : ''}`}
                    onClick={() => this.setState({page: 2})}
                ></button>
                <button
                    className={`lw__ru__navbtn ${this.state.page == 3 ? 'lw__ru__navbtn--active' : ''}`}
                    onClick={() => this.setState({page: 3})}
                ></button>
                <button
                    className={`lw__ru__navbtn ${this.state.page == 4 ? 'lw__ru__navbtn--active' : ''}`}
                    onClick={() => this.setState({page: 4})}
                ></button>
                <button
                    className={`lw__ru__navbtn ${this.state.page == 5 ? 'lw__ru__navbtn--active' : ''}`}
                    onClick={() => this.setState({page: 5})}
                ></button>
                <button
                    className={`lw__ru__navbtn ${this.state.page == 6 ? 'lw__ru__navbtn--active' : ''}`}
                    onClick={() => this.setState({page: 6})}
                ></button>
            </nav>
        </section>
    }
}

export default LWRegles;