import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/login/Login";
import Home from "../pages/Home";
import Header from "./Header";
import Profil from "../pages/login/Profil";
import Classement from "../pages/jeux/Classement";
import Statistiques from "../pages/jeux/Statistiques";
import Poker from "../pages/divers/Poker";
import Videos from "../pages/videos/Videos";
import Calendarr from "../pages/divers/Calendar";
import Stats from "../pages/divers/Stats";
import Annonces from "../pages/divers/Annonces";
import { Helmet } from 'react-helmet-async';

import requireAuthentification from "../helpers/require-authentification";
import Jeu from "../pages/Jeu";
import Jeux from "../pages/jeux/Jeux";
import PlusCredits from "../pages/jeux/PlusCredits"
import Tchat from "./tchat/Tchat";
import Loading from "../pages/divers/Loading";
import Footer from "./Footer";
import Contact from "../pages/divers/Contact";
import MentLeg from "../pages/divers/MentLeg";
import PolConf from "../pages/divers/PolConf";
import Adsense from "./Adsense";
import Bingo from "./bingo/Bingo";
import BingoAdmin from "./bingo/BingoAdmin";

export default class App extends Component {
    render() {
        return <>
            <div className="container">
                <div className="pub pub--first">
                    <Adsense dataAdSlot="3471887249" />
                </div>
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        {/** Login */}
                        <Route exact path="/login" element={<Login />}/>
                        {/** Profil */}
                        <Route exact path="/profil" element={<Profil />}/>
                        {/** Jeux */}
                        <Route exact path="/jeux" element={<Jeux />} />
                        <Route exact path="/jeu-1" element={<Jeu />} />
                        <Route exact path="/jeu-2" element={<Jeu />} />
                        <Route exact path="/jeu-3" element={<Jeu />} />
                        <Route exact path="/jeu-4" element={<Jeu />} />
                        <Route exact path="/jeu-5" element={<Jeu />} />
                        <Route exact path="/jeu-6" element={<Jeu />} />
                        <Route exact path="/jeu-7" element={<Jeu />} />
                        <Route exact path="/jeu-9" element={<Jeu />} />
                        <Route exact path="/jeu-12" element={<Jeu />} />
                        <Route exact path="/plus-de-credits" element={<PlusCredits />} /> {/** todo pas afficher si pas user */}
                        <Route exact path="/classement" element={<Classement />} />
                        <Route exact path="/vos-statistiques" element={<Statistiques />} /> {/** todo pas afficher si pas user */}
                        <Route exact path="/poker" element={<Poker />} />
                        <Route exact path="/videos" element={<Videos />} />
                        <Route exact path="/calendrier" element={<Calendarr />} />
                        <Route exact path="/tof-statistiques" element={<Stats />} />
                        <Route exact path="/actualites" element={<Annonces />} />
                        <Route exact path="/loading" element={<Loading />} />
                        <Route exact path="/contact" element={<Contact />} />
                        <Route exact path="/mentions-legales" element={<MentLeg />} />
                        <Route exact path="/politique-confidentialite" element={<PolConf />} />
                        <Route exact path="/bingo" element={<Bingo />} />
                        <Route exact path="/bingo-admin" element={<BingoAdmin />} />
                        <Route exact path="*" element={<Loading />} />
                    </Routes>
                <div className="pub">
                    <Adsense dataAdSlot="3471887249" />
                </div>
            </div>
            <Header />
            <Footer />
            <Tchat />
        </>
    }
}