// Login
export const SET_AUTHENTICATION = "SET_AUTHENTICATION";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const MDP_SEND_CODE = "MDP_SEND_CODE";
export const MDP_VERIF_CODE = "MDP_VERIF_CODE";
export const MDP_NEW_MDP = "MDP_NEW_MDP";
export const NEW_USER = "NEW_USER";

// Home
export const GET_DATAS = "GET_DATAS";

// Jeux
export const GET_JEUX = "GET_JEUX";
export const GET_JEU = "GET_JEU";
export const SET_RES_JEU = "SET_RES_JEU";
export const GET_JEU_CLASSEMENT = "GET_JEU_CLASSEMENT";
export const GET_JEU_STATS = "GET_JEU_STATS";
export const GET_JEU_GRAPH = "GET_JEU_GRAPH";

// Profil
export const GET_PROFIL = "GET_PROFIL"; // ok
export const EDIT_PROFIL = "EDIT_PROFIL"; // ok
export const GET_INFO_JEU = "GET_INFO_JEU"; // ok
export const REMOVE_CREDITS = "REMOVE_CREDITS"; // ok
export const CONVERT_POINT = "CONVERT_POINT"; // ok
export const LAST_CO = "LAST_CO"; // ok
export const VERIF_ROUE = "VERIF_ROUE"; // ok
export const ADD_ROUE = "ADD_ROUE"; // ok
export const VERIF_CREDITS = "VERIF_CREDITS";

// Vidéos
export const GET_VIDEOS = "GET_VIDEOS";

// Concours
export const GET_CONCOURS = "GET_CONCOURS";

// LotoWin
export const LW_GET_GRILLES = "LW_GET_GRILLES";
export const LW_GET_TIRAGE = "LW_GET_TIRAGE";
export const LW_SEND_GRILLE = "LW_SEND_GRILLE";
export const LW_GET_LOTS = "LW_GET_LOTS";
export const LW_PAY_GRILLE = "LW_PAY_GRILLE";
export const LW_GET_WINNER = "LW_GET_WINNER";
export const LW_GET_NB_GRILLES = "LW_GET_NB_GRILLES";
export const LW_GET_NB_GR_TYPE = "LW_GET_NB_GR_TYPE";
export const LW_SEND_GRILLE_TIRAGE = "LW_SEND_GRILLE_TIRAGE";
export const LW_GET_TEN_SUPER = "LW_GET_TEN_SUPER";
export const LW_GET_SHOP = "LW_GET_SHOP";
export const LW_ADD_SHOP = "LW_ADD_SHOP";

// Message
export const GET_FORUM = "GET_FORUM"; 
export const GET_SALONS = "GET_SALONS";
export const GET_SALON = "GET_SALON";
export const GET_NOTIFS = "GET_NOTIFS";
export const SET_MESSAGE = "SET_MESSAGE";
export const GET_USER_MSG = "GET_USER_MSG";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const GET_USER_LETTER = "GET_USER_LETTER";
export const REMOVE_NOTIF = "REMOVE_NOTIF";

// Divers
export const GET_POKER = "GET_POKER";
export const GET_STAT_TOF = "GET_STAT_TOF";
export const GET_ANNONCES = "GET_ANNONCES";
export const GET_CALENDAR = "GET_CALENDAR";

// Bingo
export const BINGO_GET_TIRAGE = "BINGO_GET_TIRAGE";
export const BINGO_GET_GRILLE = "BINGO_GET_GRILLE";
export const BINGO_SET_GRILLE = "BINGO_SET_GRILLE";
export const BINGO_SET_TIRAGE = "BINGO_SET_TIRAGE";
export const BINGO_GET_JOUEURS = "BINGO_GET_JOUEURS";
export const BINGO_GET_CLASS = "BINGO_GET_CLASS";
export const BINGO_SET_RAZ = "BINGO_SET_RAZ";