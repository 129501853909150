import React from "react";
import Credit from "../../components/Credits";
import { Link } from "react-router-dom";
import { getDatasNbJeux, setConvertPoint, addRoue } from "../../actions/profil";
import { connect } from "react-redux";
import besace from "../../images/jeux/besace/grande_minia.svg";
import roue from "../../images/jeux/roue/grande_minia.svg";
import Alert from "../../components/Alert";
import Adsense from "../../components/Adsense";

class PlusCredits extends React.Component {
    state = {
        alert: {
            active: false,
            type: '',
            title: '',
            msg: ''
        },
        timePub: 10,
        activPub: false
    }

    componentDidMount() {
        document.title = 'Tof La Win - Plus de crédits';
        this.props.getDatasNbJeux(); // todo à vérifier
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.datasnbjeu != undefined &&
            this.props.datasnbjeu != prevProps.datasnbjeu &&
            this.state.alert != this.props.datasnbjeu.alert
        ) {
            this.setState({alert: this.props.datasnbjeu.alert});
        }

        if (
            this.props.pointsconvert != undefined &&
            this.props.pointsconvert != prevProps.pointsconvert
        ) {
            if (this.state.alert != this.props.pointsconvert.alert) {
                this.setState({alert: this.props.pointsconvert.alert});
                if (this.props.pointsconvert.alert.active) {
                    alert(this.props.pointsconvert.alert.msg);
                }
            }
            // todo si next = true, mettre alert à success dans fichier action
            if (this.props.pointsconvert.next) {
                this.props.getDatasNbJeux();
                alert("Vos points ont bien été convertis");
            }            
        }

        if (
            this.props.addrouee != undefined &&
            this.props.addrouee != prevProps.addrouee
        ) {
            if (this.state.alert != this.props.addrouee.alert) {
                this.setState({alert: this.props.addrouee.alert});
            }
            // todo verification
            if (this.props.addrouee.next) {
                this.props.getDatasNbJeux();// todo verification
            }
        }
    }

    startTimer() {
        let interv = setInterval(() => {
            if ( this.state.timePub == 0) {
                this.setState({
                    timePub: 10,
                    activPub: false
                })
                clearInterval(interv);
                this.props.addRoue(); // todo à vérifier
            } else {
                this.setState({timePub: this.state.timePub - 1});
            }
        }, 1000);
// todo vérifier lancement
    }

    render() {
        return localStorage.userId == undefined  ?
            <h3 className="page__empty">Vous devez vous connecter pour accéder aux jeux</h3> :
            <main className="page page--jeux page--morecredits morecredits">
            <header className="page page--jeux__header">
                <Link to="/jeux">Les jeux</Link>
                <hr />
                <span>Crédits : <b>{this.props.datasnbjeu != undefined ? this.props.datasnbjeu.infos.credits : 0}</b></span>
                <hr />
                <span>Points : <b>{this.props.datasnbjeu != undefined ? this.props.datasnbjeu.infos.points : 0}</b></span>
                <hr />
                <span>Besace : <b>{this.props.datasnbjeu != undefined ? this.props.datasnbjeu.infos.besace : 0}</b></span>
                <hr />
                <span>Roue : <b>{this.props.datasnbjeu != undefined ? this.props.datasnbjeu.infos.roue : 0}</b></span>
            </header>
            {
                this.state.alert.active ?
                    <Alert class={`alert--${this.state.alert.type}`} ttl={this.state.alert.title}>{this.state.alert.msg}</Alert> : ""
            }
            <button className="morecredits__addroue" onClick={() => {this.setState({activPub: true}); this.startTimer()}}>
                Obtenir une roue
            </button>
            <Link to="/jeu/9" className="morecredits__besace">
                <img src={besace} alt="Jeu besace" className="morecredits__besace__img" />
            </Link>
            <Link to="/eujezjejufeuj" className="morecredits__roue">
                <img src={roue} alt="Jeu roue" className="morecredits__roue__img" />
            </Link>
            <div className="morecredits__convert">
                <h2 className="morecredits__convert__ttl">Convertissez vos points</h2>
                <div className="morecredits__convert__item">
                    <span>
                        1M pts = <Credit nb={1} className="" />
                    </span>
                    <button className="btn" onClick={() => this.props.setConvertPoint({points: 1000000}) /* todo vérifier param */}>Convertir</button>
                </div>
                <div className="morecredits__convert__item">
                    <span>
                        3M pts = <Credit nb={5} className="" />
                    </span>
                    <button className="btn" onClick={() => this.props.setConvertPoint({points: 3000000}) /* todo vérifier param */}>Convertir</button>
                </div>
                <div className="morecredits__convert__item">
                    <span>
                        5M pts = <Credit nb={10} className="" />
                    </span>
                    <button className="btn" onClick={() => this.props.setConvertPoint({points: 5000000}) /* todo vérifier param */}>Convertir</button>
                </div>
            </div>
            <div className={`modpub ${this.state.activPub && "modpub--active"}`}>
                <div className="modpub__zone">
                    <h2 className="modpub__ttl">Publicité</h2>
                    <p className="modpub__desc">Veuillez patienter <b>{this.state.timePub}</b> secondes jusqu'à la fin de la pub pour obtenir votre roue.</p>
                    <div>                    
                        <Adsense dataAdSlot="2725195055" />                    
                        
                        
                        <Adsense dataAdSlot="2725195055" />                    
                        
                        
                        <Adsense dataAdSlot="2725195055" />  
                    </div>                  
                </div>
            </div>
        </main>
    }
}

const mapStateToProps = state => {
    return {
        datasnbjeu: state.datasnbjeu,
        pointsconvert: state.convpoints,
        addrouee: state.addroue
    }
}

const mapDispatchToProps = {
    getDatasNbJeux,
    setConvertPoint,
    addRoue
}

export default connect(mapStateToProps, mapDispatchToProps)(PlusCredits);