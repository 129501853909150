import React from "react";
import iconSet from "../selection.json";
import IcomoonReact from "icomoon-react";

class Avatar extends React.Component {
    render() {
        return <div className={`avatar ${this.props.class}`}>
            {
                (this.props.avatar != null && this.props.avatar != "null" && this.props.avatar != "https://api.toflawin.fr/image/membres/") ?
                    <img src={this.props.avatar} alt="Photo de profil" className="avatar__img" /> :
                    <IcomoonReact iconSet={iconSet} icon="users" />
            }
        </div>
    }
}

export default Avatar;