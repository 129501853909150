import React from "react";
import logoSuper from "../../images/jeux/lotowin/logo_super_tel.svg";
import logoMaxi from "../../images/jeux/lotowin/logo_maxi_tel.svg";
import logoMega from "../../images/jeux/lotowin/logo_mega_tel.svg";
import logoExtra from "../../images/jeux/lotowin/logo_extra_win.svg";
import boulierSuper from "../../images/jeux/lotowin/boulier_super_tel.svg";
import boulierMaxi from "../../images/jeux/lotowin/boulier_maxi_tel.svg";
import boulierMega from "../../images/jeux/lotowin/boulier_mega_tel.svg";
import boulierExtra from "../../images/jeux/lotowin/boulier_extra_tel.svg";
import iconSet from "../../selection.json";
import IcomoonReact from "icomoon-react";

let interv;

class LWBoulier extends React.Component {
    state = {
        boulier: null,
        seeTirage: false,
        counter: "00:00:00",
    };

    componentDidMount() {
        let type = this.props.typeb;
        const bouliers = {
            super: {
                logo: logoSuper,
                type: "super",
                boulier: boulierSuper
            },
            maxi: {
                logo: logoMaxi,
                type: "maxi",
                boulier: boulierMaxi
            },
            mega: {
                logo: logoMega,
                type: "mega",
                boulier: boulierMega
            },
            extra: {
                logo: logoExtra,
                type: "extra",
                boulier: boulierExtra
            }
        };

        this.setState({boulier: bouliers[type]});

        interv = setInterval(this.counterTirage, 1000);
        
    }

    componentWillUnmount() {
        clearInterval(interv);
    }

    // todo mettre en place le compteur
    counterTirage = () => {
        // Obtenir l'heure du tirage
        let max = null;
        let hour = "";
        let minute = "";

        // Récupère heure minute actu
        let datetime = new Date();

        // récupère minute et heure
        let tempminute = datetime.getMinutes();
        let temphour = datetime.getHours();

        if (this.props.typeb == "super") {
            // si inf 15
            if (tempminute < 15) {
                minute = 15;
            } else {
                if (tempminute < 30) {
                    minute = 30;
                } else {
                    if (tempminute < 45) {
                        minute = 45;
                    } else {
                        minute = "00";
                        temphour = temphour + 1;
                    }
                }
            }
            if (temphour < 10) {
                hour = "0" + temphour;
            } else {
                hour = temphour;
            }
        } 
        if (this.props.typeb == "maxi") {
            // Toutes les heures
            minute = "00";
            hour = temphour < 10 ? ("0" + (temphour + 1)) : temphour + 1;
        }
        if (this.props.typeb == "mega") {
            // 8h et 20h
            minute = "00";
            if (temphour >= 20 || temphour < 8) {
                hour = "08";
            } else {
                hour = "20";
            }
        }
        if (this.props.typeb == "extra") {
            minute = "00";
            hour = "21";
        }

        max = hour + ":" + minute + ":00";

        // ==== Réduire pour faire le décompte
        let now = new Date();
        let nowtemphour = now.getHours();
        let nowtempmin = now.getMinutes();
        let nowtempsec = now.getSeconds();
        let nowhour = "";
        let nowmin = "";
        let nowsec = "";
        let maxhour = max.split(':')[0];
        let maxmin = max.split(':')[1];
        let maxsec = max.split(':')[2];

        // Mettre en forme
        if (nowtemphour < 10) {
            nowhour = "0" + nowtemphour;
        } else {
            nowhour = nowtemphour;
        }
        if (nowtempmin < 10) {
            nowmin = "0" + nowtempmin;
        } else {
            nowmin = nowtempmin;
        }
        if (nowtempsec < 10) {
            nowsec = "0" + nowtempsec;
        } else {
            nowsec = nowtempsec;
        }

        // string to int
        nowhour = parseInt(nowhour);
        nowmin = parseInt(nowmin);
        nowsec = parseInt(nowsec);
        maxhour = maxhour === "00" ? 24 : parseInt(maxhour);
        maxmin = maxmin === "00" ? 60: parseInt(maxmin);
        maxsec = maxsec === "00" ? 60 : parseInt(maxsec);

        let newsec = 0;
        let newmin = 0;
        let newhour = 0;
 
        if (this.props.typeb == "mega" || this.props.typeb == "extra") {
            if (nowhour > maxhour) {
                newhour = (24 - nowhour) + maxhour - 1;
            } else {
                newhour = maxhour - nowhour - 1;
            }
        }
        if (this.props.typeb == "super" || this.props.typeb == "maxi") {
            newhour = maxhour - nowhour - 1;
        }
        newsec = maxsec - nowsec - 1;
        newmin = maxmin - nowmin - 1;

        let tempseetirage = false;
        if (this.props.typeb == "super") {
            // Toutes les 15 minutes
            if (newmin == 14 && newsec >= 30 && newsec <= 59) {
                tempseetirage = true;
            }
        } else {
            // Toutes les heures
            if (newhour == 0 && newmin == 59 && newsec >= 30 && newsec <= 59) {
                tempseetirage = true;
            }
        }
        /*
        if (newhour == 0 && newmin == 0 && newsec <= 20) {
            tempseetirage = true;
        } else {
            if (this.state.seeTirage && newsec >= 40) {
                tempseetirage = true;
            }
        }
        */
        newsec = newsec < 10 ? ("0" + newsec) : newsec;
        newmin = newmin < 10 ? ("0" + newmin) : newmin;
        newhour = newhour < 10 ? ("0" + newhour) : newhour;

        if (this.props.typeb == "super") {
            newhour = "00";
        }
        let decompte = newhour + ":" + newmin + ":" + newsec;
        
        this.setState({
            counter: decompte,
            seeTirage: tempseetirage
        });
    }

    handleChangePage = ({type, value}) => {
        this.props.handleChange({type: type, value: value});
    }

    render() {
        if (this.state.boulier != null) {
            return <section className="lw__bl">
                {/** Logo */}
                <img src={this.state.boulier.logo} alt="Logo Lotowin" className="lw__bl__logo" />
                {/** Compteur */}
                <div className={`lw__bl__compteur lw__bl__compteur--${this.state.boulier.type}`}>
                    <span className="lw__bl__cptttl">Prochain tirage&nbsp;:</span>
                    <span className="lw__bl__time">{this.state.counter}</span>
                </div>
                {/** Voir tirage */}
                {/** ${ this.state.seeTirage == false && "btntirage--inactive"} */}
                <button className={`btntirage ${ this.state.seeTirage == false && "btntirage--inactive"}`} onClick={() => this.handleChangePage({type: "tirage", "value": this.props.typeb})}>
                    <IcomoonReact iconSet={iconSet} icon="controller-record" />
                    Voir le tirage
                </button>
                {/** Boulier */}
                <img src={this.state.boulier.boulier} alt="Boulier Lotowin" className="lw__bl__img" />
                {/** Faire grille */}
                <button
                    className={`btnaction btnaction--${this.state.boulier.type}`}
                    onClick={() => this.props.changeModal("grille")}
                >
                    Faire une grille
                    <IcomoonReact iconSet={iconSet} icon="table2" />
                </button>
            </section>
        } else {
            return "";
            // todo message attente réception données
        }
    }
}

export default LWBoulier;
