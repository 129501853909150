import React from "react";
import { sendLwGrille, getLwNbGrType, sendLwGrilleTirage } from "../../actions/lotowin";
import { connect } from "react-redux";
import iconSet from "../../selection.json";
import IcomoonReact from "icomoon-react";
import Adsense from "../Adsense";

class LWGrille extends React.Component {
    state = {
        numeros: [],
        bonus: [],
        nbGrillesHas: null,
        nbGrillesWaiting: 0,
        nbGrillesTirage: 0,
        isDirectAuto: false,
        countPub: 10,
        activPub: false,
        nbTirAndNot: {},
        isSend: false,
        activGriEnr: false,
        activGriSend: false,
        isSending: false,
        alert: {
            active: false,
            type: '',
            title: '',
            msg: ''
        }
    }

    componentDidMount() {
        this.props.getLwNbGrType({type: this.props.typeb});
        this.setState({nbGrillesHas: this.props.nbGrilles[this.props.typeb]});
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.nbGrillesHas != this.props.nbHasGrille && this.state.nbGrillesHas != null && this.props.nbHasGrille != null) {
            this.handleChangeGrilleHas(this.props.nbHasGrille);
            this.setState({
                nbGrillesHas: this.props.nbHasGrille
            })
        }
        
        if (
            this.props.nbTirAndNot.nbs != undefined &&
            this.props.nbTirAndNot.nbs != this.state.nbTirAndNot
        ) {
            this.setState({nbTirAndNot: this.props.nbTirAndNot.nbs});
        }

        if (
            this.state.isSending &&
            this.props.isSend != undefined &&
            this.props.isSend != this.state.isSend
        ) {
            if (this.props.isSend) {
                this.setState({
                    isSend: this.props.isSend,
                    numeros: [],
                    bonus: [],
                    activGriEnr: true,
                    isSending: false
                });

                setTimeout(() => {
                    this.setState({activGriEnr: false});
                    this.props.getLwNbGrType({type: this.props.typeb});
                }, 3000);
            }
        }
        /*
        console.log("===============")
        console.log(this.props.sendForTirage.alert)
        console.log(this.state.alert.active)
        console.log(this.state.activGriSend)*/
        if (
            this.props.sendForTirage.alert != undefined &&
            !this.props.sendForTirage.alert.active &&
            this.state.alert.active != this.props.sendForTirage.alert.active &&
            this.state.activGriSend == false
        ) {
            this.setState({
                activGriSend: true,
                alert: this.props.sendForTirage.alert
            })
            setTimeout(() => {
                this.setState({activGriSend: false});
                this.props.getLwNbGrType({type: this.props.typeb});
            }, 3000);
        }

        if ((this.state.numeros.length > 0 || this.state.bonus.length > 0) && this.state.isSending == false && this.state.isSend) {
            this.setState({isSend: false});
        }
    } 

    handleChangeGrilleHas = (value) => {
        this.props.handleChangeGrilleHas(value, this.props.typeb);
    }

    handleClickNum = (num) => {
        let tempnums = this.state.numeros;
        let tempbonus = this.state.bonus;

        // Si le numéro est présent dans les numéros
        if (tempnums.includes(num)) {
            // Le retirer
            tempnums.splice(tempnums.findIndex(el => el == num), 1);
        } else {
            // Si le numéro est présent dans les bonus
            if (tempbonus.includes(num)) { 
                // Le retirer
                tempbonus.splice(tempbonus.findIndex(el => el == num), 1);
            } else {
                // S'il y a pas encore 5 numéros
                if (tempnums.length < 5) {
                    // L'ajouter
                    tempnums.push(num);
                } else {
                    // S'il y a pas encore 3 numéros bonus
                    if (tempbonus.length < 3) {
                        // L'ajouter
                        tempbonus.push(num);
                    } else {
                        // Si tout les numéros sont là, message d'erreur
                        alert("Vous ne pouvez plus ajouter de numéros");
                    }
                }
            }
        }

        this.setState({
            numeros: tempnums,
            bonus: tempbonus
        })
    }

    handleAlea = () => {
        // RAZ
        this.setState({
            numeros: [],
            bonus: []
        });
        let nums = [];
        let countnum = 0;
        for (let i = 1; i <= 70; i++) {
            nums.push(i);
        }

        let interv = setInterval(() => {
            // Choix des numéros
            for (let i = 0; i < 8; i++) {
                let index = Math.floor(Math.random() * nums.length);
                if (this.state.numeros.length < 5) {
                    this.setState({
                        numeros: [...this.state.numeros, nums[index]]
                    })
                    // tempnums.push(nums[index]);
                } else {
                    if (this.state.bonus.length < 3) {
                        this.setState({
                            bonus: [...this.state.bonus, nums[index]]
                        })
                        // tempbonus.push(nums[index]);
                    }
                }
                nums.splice(index, 1);
            }
    
            countnum += 1;
            
            if (countnum == 8) {
                clearInterval(interv);
            }
        }, 100);
    }

    handleChangeDirectauto = () => {
        this.setState({isDirectAuto: !this.state.isDirectAuto});
    }

    handleSaveGrille = () => {
        // Vérification si assez de grille jouable
        if (this.state.nbGrillesHas > 0) {
            // Vérification s'il y a plus de 100 grilles en attente
            if (this.state.nbGrillesWaiting < 100) {
                // Vérification s'il y a plus de 100 grilles dans le tirage, si l'option tirage direct est choisi
                let verif = true;
                if (this.state.isDirectAuto) {
                    verif = false;
                    if (this.state.nbGrillesTirage < 100) {
                        verif = true;
                    } else {
                        alert("Vous ne pouvez pas enregistrer plus de 100 grilles pour le prochain tirage.");
                    }
                }
                
                if (verif) {
                    // Vérification nombres suffisants
                    if (this.state.numeros.length == 5) {
                        if (this.state.bonus.length == 3) {
                            // Lancement de la pub
                            this.lancerPub();
                        } else {
                            alert("Veuillez sélectionner 3 numéros bonus.");
                        }
                    } else {
                        alert("Veuillez sélectionner 5 numéros.");
                    }
                }
            } else {
                alert("Vous ne pouvez pas enregistrer plus de 100 grilles");
            }
        } else {
            if (this.props.typeb == "super") {
                alert("Vous n'avez pas assez de grille. Veuillez attendre 30 secondes pour en obtenir 10 supplémentaires.");
            } else {
                alert("Vous n'avez pas assez de grilles. Veuillez accéder à la boutique pour en acheter.");
            }
        }
    }

    lancerPub = () => {
        if (this.state.activPub == false && this.state.countPub == 10) {
        this.setState({
                activPub: true
            });
            setTimeout(() => {
                this.lancerPub();
            }, 1000);
        } else {
        if (this.state.activPub && this.state.countPub != 0) {
        this.setState({
                    countPub: this.state.countPub - 1
                });
                setTimeout(() => {
                    this.lancerPub();
                }, 1000);
            } else {
                // Enregistrer grille
                this.setState({isSending: true});
                this.props.sendLwGrille({
                    type: this.props.typeb,
                    numeros: this.state.numeros,
                    bonus: this.state.bonus,
                    saveTirage: this.state.isDirectAuto
                });
                this.setState({
                    activPub: false,
                    countPub: 10
                })
            }
        }
    }

    handleSendForTirage = () => {
        if (this.state.nbTirAndNot?.noTirage > 0) {
            this.props.sendLwGrilleTirage({type: this.props.typeb});
            this.setState({
                alert: {
                    active: true,
                    type: 'warning',
                    title: 'Envoi de données',
                    msg: 'Envoi de données en cours'
                }
            })
        } else {
            alert("Vous n'avez aucune grille a envoyer pour le prochain tirage.");
        }
    }

    render() {
        const nums = [];
        for (let i = 1; i <= 80; i++) {
            nums.push(i);
        }

        return <section className="lw__fg lw__mod">
            <h2 className={`lw__fg__ttl lw__fg__ttl--${this.props.typeb}`}>
                Faites votre grille
            </h2>
            <h3 className="lw__fg__subttl">Veuillez choisir vos numéros</h3>
            <div className={`lw__fg__compteur lw__fg__compteur--${this.props.typeb}`}>
                <span className="lw__fg__cpttxt">{this.state.numeros.length}/5 numéros - {this.state.bonus.length}/3 numéros bonus</span>
            </div>
            <div className="lw__fg__grille">
                {
                    nums.map((num, index) => <button
                        key={index}
                        className={`lw__fg__btnnum lw__fg__btnnum--${this.props.typeb} ${this.state.numeros.includes(num) && "lw__fg__btnnum--classique--" + this.props.typeb} ${this.state.bonus.includes(num) && "lw__fg__btnnum--bonus"}`}
                        onClick={() => this.handleClickNum(num)}
                    >
                        {num}
                    </button>)
                }
            </div>
            <div className="lw__fg__listbtn">
                <div className="lw__fg__inpgrp">
                    <input checked={this.state.isDirectAuto} onClick={() => this.handleChangeDirectauto()} type="checkbox" name="savetirage" id="savetirage" />
                    <label htmlFor="savetirage" className="lw__fg__inplabel">Enregistrer automatiquement pour le prochain tirage</label>
                </div>
                <button onClick={() => this.handleAlea()} className={`lw__fg__btnval lw__fg__btnalea lw__fg__btnalea--${this.props.typeb}`}>Aléatoire</button>
                <button
                    className={`lw__fg__btnval lw__fg__btnval--${this.props.typeb}`}
                    onClick={() => this.handleSaveGrille()}
                >Enregistrer</button>
                <button
                    className={`lw__fg__sendtirage lw__fg__sendtirage--${this.props.typeb}`}
                    onClick={() => this.handleSendForTirage()}
                ><IcomoonReact iconSet={iconSet} icon="paperplane" />Envoyer {this.state.nbTirAndNot?.noTirage} grilles pour le prochain tirage</button>
                <p className="lw__fg__nbgrilletirage">Vous avez envoyé <span className={`lw__fg__nbgrille lw__fg__nbgrille--${this.props.typeb}`}>{this.state.nbTirAndNot?.toTirage} <IcomoonReact iconSet={iconSet} icon="table2" /></span> pour le prochain tirage.</p>
            </div>
            {
                this.state.activGriEnr ?
                    <div className="lw__ti__mod">
                        <h3 className="lw__ti__modttl">Votre grille a bien été enregistrée</h3>
                    </div>
                    : ""
            }
            {
                this.state.activGriSend ?
                    <div className="lw__ti__mod">
                        <h3 className="lw__ti__modttl">Vos grilles ont bien été envoyées</h3>
                    </div>
                    : ""
            }
            <div className={`lw__fg__pub lw__fg__pub--${this.props.typeb} ${this.state.activPub ? 'lw__fg__pub--active' : ''}`}>
                <h2 className="lw__fg__pubttl">
                    La publicité se fermera dans {this.state.countPub} secondes et votre grille sera enregistrée.
                </h2>
                {
                    this.state.activPub &&
                    <div>
                            <Adsense dataAdSlot="2725195055" />
                        
                            <Adsense dataAdSlot="2725195055" />
                        
                            <Adsense dataAdSlot="2725195055" />
                        
                    </div>
                }
                
            </div>
        </section>
    }
}

const mapStateToProps = state => {
    return {
        isSend: state.lwsendgrille.isSend,
        nbHasGrille: state.lwsendgrille.nbHasGrille,
        nbTirAndNot: state.lwgetnbtype,
        sendForTirage: state.lwsendgrilletirage
    }
}

const mapDispatchToProps = {
    sendLwGrille,
    getLwNbGrType,
    sendLwGrilleTirage
}

export default connect(mapStateToProps, mapDispatchToProps)(LWGrille);