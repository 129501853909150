import { getDomain, config } from "../helpers";
import axios from "axios";
import { MDP_SEND_CODE, MDP_VERIF_CODE, MDP_NEW_MDP, NEW_USER } from "./action-types";

// ========== Modification de mot de passe ======
// MDP - Envoi de l'adresse mail et envoi du code par mail
export function sendMdpCode({email}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Attente...',
            msg: "Vérification de l'adresse mail..."
        };
        let next = false;

        axios.post(`${getDomain()}user/sendcode`, {
            email: email,
        }, config)
            .then(res => {
                switch (res.data[0]) {
                    case "code_send":
                        next = true;
                        alert = {
                            active: false,
                            type: '',
                            title: '',
                            msg: ''
                        };
                        break;
                    case "inexist":
                        next = false;
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Adresse mail inexistante',
                            msg: "Cette adresse mail n'est liée à aucun compte."
                        };
                        break;
                    case "error":
                        next = false;
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Champs vide',
                            msg: "Veuillez remplir tous les champs obligatoires."
                        };
                        break;
                }

                dispatch({
                    type: MDP_SEND_CODE,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Log001)"
                };
                dispatch({
                    type: MDP_SEND_CODE,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
    }
}

// MDP - Vérification du code
export function verifMdpCode({email, code}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Vérification...',
            msg: "Vérification du code en cours..."
        };
        let next = false;

        axios.post(`${getDomain()}user/verifcode`, {
            email: email,
            code: code
        }, config)
            .then(res => {
                switch (res.data[0]) {
                    case "is_correct":
                        alert = {
                            active: false,
                            type: '',
                            title: '',
                            msg: ''
                        };
                        next = true;
                        break;
                    case "bad_code":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Mauvais code',
                            msg: 'Ce code est incorrect.'
                        };
                        next = false;
                        break;
                    case "error":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Champs vide',
                            msg: 'Veuillez remplir tous les champs obligatoires'
                        };
                        next = true;
                        break;
                }

                dispatch({
                    type: MDP_VERIF_CODE,
                    payload: {
                        alert: alert,
                        next: next
                    } 
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Log002)"
                };
                dispatch({
                    type: MDP_VERIF_CODE,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
    }
}

// MDP - Modification du mot de passe
export function setMdpMdp({email, code, mdp, confMdp}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Modification en cours...',
            msg: 'Enregistrement du nouveau mot de passe en cours.'
        };
        let next = false;

        axios.post(`${getDomain()}user/newpass`, {
            email: email,
            code: code,
            mdp: mdp,
            mdpconfirm: confMdp
        }, config)
            .then(res => {
                switch (res.data[0]) {
                    case "mdp_success":
                        next = true;
                        alert = {
                            active: true,
                            type: 'success',
                            title: 'Mot de passe modifié !',
                            msg: "Votre mot de passe a bien été modifié. Vous pouvez vous connecter à présent."
                        }
                        break;
                    case "bad_user":
                        next = false;
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Utilisateur inexistant.',
                            msg: "L'utilisateur est inexistant"
                        };
                        break;
                    case "mdp_unmatch":
                        next = false;
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Saisie incorrect',
                            msg: "Vos mots de passe ne correspondent pas."
                        };
                        break;
                    case "bad_code":
                        next = false;
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Saisie incorrect',
                            msg: 'Le code est incorrect.'
                        };
                        break;
                    case "error":
                        next = false;
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Saisie incorrect',
                            msg: "Veuillez remplir tous les champs obligatoires"
                        }
                        break;
                }
                dispatch({
                    type: MDP_NEW_MDP,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Vous n'avez pas pu modifier votre mot de passe. Veuillez contacter Matt (Code : Log003)"
                };
                dispatch({
                    type: MDP_NEW_MDP,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
    }
}

// ========= Inscription =========================
// Envoi et vérification des données
export function setNewUser({
    username,
    email,
    confirmEmail,
    mdp,
    confirmMdp,
    anniv,
    polconf,
    newslettter
}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Inscription en cours...',
            msg: 'Création de votre compte en cours...'
        };
        let next = false;

        axios.post(`${getDomain()}user/add`, {
            usernamei: username,
            emaili: email,
            confirmEmaili: confirmEmail,
            mdpi: mdp,
            confirmMdpi: confirmMdp,
            annivi: anniv,
            polconfi: '"' + polconf + '"',
            newsletteri: '"' + newslettter + '"',
        }, config)
            .then(res => {
                switch (res.data[0]) {
                    case "mdp_equals":
                        next = false;
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Saisie incorrect',
                            msg: "Vos mots de passe ne correspondent pas"
                        };
                        break;
                    case "email_equals":
                        next = false;
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Saisie incorrect',
                            msg: 'Vos adresses mail ne correspondent pas'
                        };
                        break;
                    case "email_exist":
                        next = false;
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Saisie incorrect',
                            msg: "Cette adresse mail existe déjà"
                        };
                        break;
                    case "username_exist":
                        next = false;
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Saisie incorrect',
                            msg: "Ce nom d'utilisateur est déjà pris."
                        };
                        break;
                    case "compte_exist":
                        next = false;
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue',
                            msg: "Vous ne pouvez pas posséder deux comptes"
                        };
                        break;
                    case "empty":
                        next = false;
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Saisie incorrect',
                            msg: "Veuillez remplir tous les champs obligatoires"
                        };
                        break;
                    case "inscr_success":
                        next = true;
                        alert = {
                            active: true,
                            type: 'success',
                            title: 'Compte créé !',
                            msg: "Votre compte a bien été créé. Vous pouvez vous connecter à présent."
                        }
                        break;
                }
                dispatch({
                    type: NEW_USER,
                    payload: {
                        next: next,
                        alert: alert
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: "Votre compte n'a pas pu être créé. Veuillez contacter Matt (Code : Log004)"
                };
                dispatch({
                    type: NEW_USER,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
    }
}