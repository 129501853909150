import { GET_DATAS } from "../actions/action-types";

const initialState = {
    alert: {
        active: false,
        type: null,
        title: null,
        msg: null
    },
    isGetting: false,
    datas: null
};

export function GetDataReducer (state = initialState, action) {
    switch (action.type) {
        case GET_DATAS:
            return {
                alert: action.payload.alert,
                isGetting: action.payload.isGetting,
                datas: action.payload.datas
            }
        default:
            return state;
    }
}