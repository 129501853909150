import React from "react";
import iconSet from "../../selection.json";
import IcomoonReact from "icomoon-react";
import LWGrille from "./Grille";
import LWGrilles from "./Grilles";
import LWLots from "./Lots";
import LWWinner from "./Winner";
import LWBoutique from "./Boutique";
import LWRegles from "./Regles";

class LWModal extends React.Component {
    handleChangeGrilleHas = (value, type) => {
        this.props.handleChangeGrilleHas(value, type);
    }

    render() {
        return <div className={`lw__modal ${this.props.active && "lw__modal--active"}`}>
            {
                this.props.typeb == "grille" ?
                    <LWGrille
                        nbGrilles={this.props.nbGrilles}
                        typeb={this.props.typec}
                        handleChangeGrilleHas={(value, type) => this.handleChangeGrilleHas(value, type)}
                        active={this.props.active}
                    /> : (
                        this.props.typeb == "grilles" ?
                            <LWGrilles /> : (
                                this.props.typeb == "lots" ?
                                    <LWLots /> : (
                                        this.props.typeb == "winner" ?
                                            <LWWinner /> : (
                                                this.props.typeb == "boutique" ?
                                                    <LWBoutique researchNb={this.props.researchNb}/> :
                                                    this.props.typeb == "regles" ?
                                                        <LWRegles/> : ""
                                            )
                                    )
                            )
                    )
            }
            <button className="lw__modclose" onClick={() => this.props.handleCloseModal(true)}>
                <IcomoonReact iconSet={iconSet} icon="cross" />
            </button>
        </div>
    }
}

export default LWModal;