import { getDomain, config } from "../helpers";
import axios from "axios";
import { BINGO_GET_CLASS, BINGO_GET_GRILLE, BINGO_GET_JOUEURS, BINGO_GET_TIRAGE, BINGO_SET_GRILLE, BINGO_SET_RAZ, BINGO_SET_TIRAGE } from "./action-types";

// Obtenir les données du dernier tirage
export function getBingoTirage() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Recherche de données...',
            msg: 'Recherche du dernier tirage de bingo en cours...'
        };
        let datas = {};

        axios.post(`${getDomain()}bingolive/futur-tirage`, {}, config)
            .then(res => {
                alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                datas = res.data;

                dispatch({
                    type: BINGO_GET_TIRAGE,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
            .catch (error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: 'Une erreur est survenue. Veuillez contacter Matt (Code : Bingo001)'
                }
                dispatch({
                    type: BINGO_GET_TIRAGE,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
    }
}

// OBtenir la grille du joueur
export function getBingoGrille() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Recherche de données...',
            msg: 'Recherche de votre grille de bingo en cours...'
        };
        let datas = {};

        axios.post(`${getDomain()}bingolive/cartons`, {
            credentials: localStorage.credentials,
            user: localStorage.userId
        }, config)
            .then(res => {
                datas = res.data;
                console.log(datas);

                if (datas[0] == "not_carton") {
                    datas = {};
                    alert = {
                        active: false,
                        type: '',
                        title: '',
                        msg: ''
                    };
                } else {
                    if (datas[0] == "user_inexist") {
                        datas = {};
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue',
                            msg: 'Une erreur est survenue. Veuillez contacter Matt (Code : Bingo006)'
                        }
                    } else {
                        if (datas[0] == "error_login") {
                            datas = {};
                            alert = {
                                active: true,
                                type: 'error',
                                title: "Une erreur est survenue",
                                msg: "Une erreur est survenue. Veuillez contacter Matt (Code : Bingo007)"
                            }
                        } else {
                            datas = datas.numeros.split(',');
                            alert = {
                                active: false,
                                type: '',
                                title: '',
                                msg: ''
                            };
                        }
                    }
                }
                
                dispatch({
                    type: BINGO_GET_GRILLE,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
            .catch (error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: 'Une erreur est survenue. Veuillez contacter Matt (Code : Bingo002)'
                }
                dispatch({
                    type: BINGO_GET_GRILLE,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
    }
}

// Sauvegarder la grille du joueur
export function setBingoGrille({num1, num2, num3, num4, num5, num6, num7, num8, num9, num10, num11}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Analyse des données...',
            msg: 'Analyse des données de votre grille en cours...'
        };
        let isEnd = false;

        axios.post(`${getDomain()}bingolive/savecartons`, {
            credentials: localStorage.credentials,
            user: localStorage.userId,
            num1: num1,
            num2: num2,
            num3: num3,
            num4: num4,
            num5: num5,
            num6: num6,
            num7: num7,
            num8: num8,
            num9: num9,
            num10: num10,
            num11: num11,
        }, config)
            .then(res => {
                switch (res.data[0]) {
                    case "ok":
                        isEnd = true;
                        alert = {
                            active: true,
                            type: 'success',
                            title: 'Grille enregistrée',
                            msg: 'Votre grille a bien été enregistrée'
                        };
                        break;
                    case "error_goodnumber":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Deux numéros équivalents',
                            msg: 'Veuillez choisir différents numéros pour chaque cases et veuillez choisir des numéros entre 1 et 90 inclus.'
                        }
                        break;
                    case "error_number":
                        alert = {
                            active: true,
                            type: 'error',
                            title: "Numéros manquants",
                            msg: "Veuillez choisir 11 numéros entre 1 et 90 inclus"
                        };
                        break;
                    case "tirage_block":
                        alert = {
                            active: true,
                            type: 'error',
                            title: "Fin des inscriptions",
                            msg: "Vous ne pouvez pas enregistrer de numéros pendant le tirage"
                        };
                        break;
                    case "user_inexist":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue',
                            msg: "Veuillez contacter Matt (Code : Bingo003)"
                        };
                        break;
                    case "error_login":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue',
                            msg: "Veuillez contacter Matt (Code : Bingo004)"
                        };
                        break;
                }
                dispatch({
                    type: BINGO_SET_GRILLE,
                    payload: {
                        alert: alert,
                        isEnd: isEnd
                    }
                })
            })
            .catch (error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: 'Une erreur est survenue. Veuillez contacter Matt (Code : Bingo005)'
                }
                dispatch({
                    type: BINGO_SET_GRILLE,
                    payload: {
                        alert: alert,
                        isEnd: isEnd
                    }
                })
            })
    }
}

// Envoyer un numéro pour le tirage
export function setBingoNum({num}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Analyse des données...',
            msg: 'Analyse du numéro envoyé'
        };
        let numRes = 0;

        axios.post(`${getDomain()}bingolive/sendnum`, {
            credentials: localStorage.credentials,
            user: localStorage.userId,
            numero: num
        }, config)
            .then(res => {
                let datas = res.data[0];

                switch (datas) {
                    case "sendnum_ok":
                        alert = {
                            active: false,
                            type: '',
                            title: '',
                            msg: ''
                        };
                        numRes = num;
                        break;
                    case "user_inexist":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue',
                            msg: 'Une erreur est survenue. Veuillez contacter Matt (Code : Bingo008)'
                        }
                        break;
                    case "error_login":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue',
                            msg: 'Une erreur est survenue. Veuillez contacter Matt (Code : Bingo009)'
                        }
                        break;
                    default:
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue',
                            msg: 'Une erreur est survenue. Veuillez contacter Matt (Code : Bingo0010)'
                        }
                        break;
                }

                dispatch({
                    type: BINGO_SET_TIRAGE,
                    payload: {
                        alert: alert,
                        numRes: numRes
                    }
                })
            })
            .catch (error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: 'Une erreur est survenue. Veuillez contacter Matt (Code : Bingo011)'
                }
                dispatch({
                    type: BINGO_SET_TIRAGE,
                    payload: {
                        alert: alert,
                        numRes: numRes
                    }
                })
            })
    }
}

// Obtenir le classement
export function getBingoClassement() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Recherche du classement',
            msg: 'Recherche du classement en cours...'
        }
        let datas = [];

        axios.post(`${getDomain()}bingolive/classement`, {}, config)
            .then(res => {
                let datas = res.data;
                let alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                }

                dispatch({
                    type: BINGO_GET_JOUEURS,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
            .catch (error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: 'Une erreur est survenue. Veuillez contacter Matt (Code : Bingo012)'
                }
                dispatch({
                    type: BINGO_GET_JOUEURS,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
    }
}

// Remise à zéro
export function setBingoRaz() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Remise à zéro',
            msg: 'Remise à zéro en cours...'
        }
        let isEnd = false;

        axios.post(`${getDomain()}bingolive/zero`, {
            credentials: localStorage.credentials,
            user: localStorage.userId
        }, config)
            .then(res => {
                let datas = res.data[0];

                switch (datas) {
                    case "ok_raz":
                        alert = {
                            active: true,
                            type: 'success',
                            title: 'Remise à zéro effectuée',
                            msg: 'La remise à zéro a été effectuée'
                        }
                        isEnd = true;
                        break;
                    case "user_inexist":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue',
                            msg: 'Une erreur est survenue. Veuillez contacter Matt (Code : Bingo013)'
                        }
                        break;
                    case "error_login":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue',
                            msg: 'Une erreur est survenue. Veuillez contacter Matt (Code : Bingo014)'
                        }
                        break;
                }

                dispatch({
                    type: BINGO_SET_RAZ,
                    payload: {
                        alert: alert,
                        isEnd: isEnd
                    }
                })
            })
            .catch (error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue',
                    msg: 'Une erreur est survenue. Veuillez contacter Matt (Code : Bingo015)'
                }
                dispatch({
                    type: BINGO_SET_RAZ,
                    payload: {
                        alert: alert,
                        isEnd: isEnd
                    }
                })
            })
    }
}