import React from "react";
import iconSet from "../../selection.json";
import IcomoonReact from "icomoon-react";
import Message from "./Message";
import { getNotifs, getUserByLetter, getSalon } from "../../actions/tchat";
import { connect } from "react-redux";
import Forum from "./Forum";
import Salons from "./Salons";
import Salon from "./Salon";
import Avatar from "../Avatar";

class Tchat extends React.Component {
    state = {
        alert: {
            active: true,
            type: 'error',
            title: 'Ceci est un message derreur test',
            msg: ''
        },
        nbnotifs: 0,
        step: "forum",
        searchValue: '',
        users: [],
        salon: null,
        salonnotif: 0,
        active: false
    }

    componentDidMount() {
        this.props.getNotifs();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.tchnotif != undefined && this.props.tchnotif != prevProps.tchnotif) {
            if (this.props.tchnotif.nbnotifs != this.state.nbnotifs) {
                this.setState({nbnotifs: this.props.tchnotif.nbnotifs});
            }
            if (this.props.tchnotif.alert != this.state.alert) {
                this.setState({alert: this.props.tchnotif.alert});
            }
        }

        if (this.props.tchuserlet != undefined && this.props.tchuserlet != prevProps.tchuserlet) {
            if (this.props.tchuserlet.datas != this.state.users) {
                this.setState({users: this.props.tchuserlet.datas});
            }
            if (this.props.tchuserlet.alert != this.state.alert) {
                this.setState({alert: this.props.tchuserlet.alert});
            }
        }

        if (this.props.tchsalon != undefined && this.props.tchsalon != prevProps.tchsalon) {
            if (this.props.tchsalon.id != this.state.salon) {
                this.handleChangeSalon(this.props.tchsalon.id, this.props.tchsalon.notif);
            }
            if (this.props.tchsalon.alert != this.state.alert) {
                this.setState({alert: this.props.tchsalon.alert});
            }
        }
    } 

    handleChangeAlert(alert) {
        if (alert != undefined && this.state.alert != alert) {
            this.setState({alert: alert});
        }
    }

    handleChangeSearchValue(e) {
        this.setState({
            searchValue: e.target.value,
            step: 'users'
        });
        this.props.getUserByLetter({letters: e.target.value});
    }

    handleChangeSalon(salon, salonnotif) {
        this.setState({
            salon: salon,
            salonnotif: salonnotif,
            step: 'salon'
        })
    }

    render() {
        return this.state.active == false ?
            <button class="tchat__btn" onClick={() => this.setState({active: true})}>
                {
                    this.state.nbnotifs > 0 ? <span className="tchat__notif">{this.state.nbnotifs}</span> : ''
                }
                <IcomoonReact iconSet={iconSet} icon="bubbles" />
            </button> :
            <section class="tchat tchat--open">
            {
                this.state.active &&
                localStorage.userId != undefined ?
                <div class="tchat__container tchat__container--open">
                    {
                        this.state.alert.active &&
                        <div className={`tchat__alert tchat__alert--${this.state.alert.type}`}>
                            {this.state.alert.title}
                        </div>
                    }
                    <header class="tchat__header">
                        <div class="tchat__tabs">
                            <button class={`tchat__tab ${this.state.step == "forum" && "tchat__tab--active"}`} onClick={() => this.setState({step: 'forum'})}>
                                Forum
                            </button>
                            <button class={`tchat__tab ${(this.state.step == "salons" || this.state.step == "salon") && "tchat__tab--active"}`}  onClick={() => this.setState({step: 'salons', salon: null})}>Tchat</button>
                            <div className={`tchat__tabsearch ${this.state.step == "users" && "tchat__tabsearch--active"}`}>
                                <label class="tchat__recbtn" for="searchuser">
                                    <IcomoonReact iconSet={iconSet} icon="search" />
                                </label>
                                <input
                                    className="tchat__search"
                                    value={this.state.searchValue}
                                    id="searchuser"
                                    onChange={(el) => this.handleChangeSearchValue(el)}
                                    placeholder="Rechercher un utilisateur..."
                                />
                            </div>
                        </div>
                        <button class="tchat__close" onClick={() => {
                            this.setState({active: false});
                            this.props.getNotifs();
                        }}>
                            <IcomoonReact iconSet={iconSet} icon="cross" />
                        </button>
                    </header>
                    {
                        (this.state.step == "forum" && this.state.active) ?
                            <Forum handleChangeAlert={alert => this.handleChangeAlert(alert)}/> : 
                            (
                                this.state.step == "salons" ?
                                    <Salons handleChangeAlert={alert => this.handleChangeAlert(alert)} handleChangeSalon={(salon, nbnotifs) => this.handleChangeSalon(salon, nbnotifs)} salon={this.state.salon}/> : (
                                        this.state.step == "salon" ?
                                        <Salon handleChangeAlert={alert => this.handleChangeAlert(alert)} salon={this.state.salon} notif={this.state.salonnotif} /> :
                                        (
                                            this.state.step == "users" ?
                                                (this.state.users.length == 0 ?
                                                    <h3 className="tchat__empty">Aucun utilisateur ayant ce pseudo n'a été trouvé.</h3> :
                                                    <ul className="tchat__listusers">
                                                        {
                                                            this.state.users.map(user => {
                                                                return <button key={`user-${user.id}`} className="tchat__listuser" onClick={() => {
                                                                    this.props.getSalon({otherUserId: user.id});
                                                                    this.setState({searchValue: ''})
                                                                }}>
                                                                    <Avatar
                                                                        avatar={user.avatar}
                                                                        class="tchat__listuser__avatar"
                                                                    />
                                                                    <span className="tchat__listuser__pseudo">{user.username}</span>
                                                                </button>
                                                            })
                                                        }
                                                    </ul>
                                                    ) : ""
                                        )
                                    )
                            )
                    }
                </div> : <div className="tchat__container tchat__container--open">
                    <header class="tchat__header">
                        <button class="tchat__close" onClick={() => {
                            this.setState({active: false});
                            this.props.getNotifs();
                        }}>
                            <IcomoonReact iconSet={iconSet} icon="cross" />
                        </button>
                    </header>
                    <h3 className="tchat__empty">Veuillez vous connecter pour accéder aux messages</h3>
                </div>
            }
        </section>
    }
}

const mapStateToProps = state => {
    return {
        tchnotif: state.tchnotif,
        tchuserlet: state.tchuserlet,
        tchsalon: state.tchsalon
    }
}

const mapDispatchToProps = {
    getNotifs,
    getUserByLetter,
    getSalon
}

export default connect(mapStateToProps, mapDispatchToProps)(Tchat);