import React from 'react';
import iconSet from "../../selection.json";
import IcomoonReact from "icomoon-react";

class AccBoulier extends React.Component {
    handleChangePage = (page) => {
        this.props.handleChange(page);
    }

    render() {
        return <div class={`lw__boulier ${this.props.class}`} id={this.props.id}>
            <img src={this.props.logo} alt="Logo Lotowin" className="lw__boulier__logo" />
            {/*<button onClick={() => {this.handleChangePage({type: "tirage", value: this.props.typeb})}} class={`btntirage`} >Voir le tirage /button> */}
            <img src={this.props.boulier} alt="Boulier Loto Win Super Win" class="lw__boulier__img" />
            <button class={`btnaction btnaction--accueil btnaction--${this.props.typeb}`} onClick={() => this.props.handleChange({type: "jeux", value: this.props.typeb})}>Jouer ({this.props.nbgrille}/100 <IcomoonReact iconSet={iconSet} icon="table2" />)</button>
        </div>
    }
}

export default AccBoulier;