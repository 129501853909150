import React from "react";
import credit from "../images/credits.svg";

class Credit extends React.Component {
    render() {
        return <span class={`credits ${this.props.class}`}>
            {this.props.nb}
            <img src={credit} alt="Crédits - Monnaie virtuel du site Tof La Win" className="credits__ico" />
        </span>
    }
}

export default Credit;