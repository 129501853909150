import React from "react";
import BingoModal from "./BingoModal";
import Alert from "../Alert";
import { getBingoTirage, getBingoGrille } from "../../actions/bingo";
import { connect } from "react-redux";

const arrayPlace = [1,2,3,5,7,9,10,12,14,16,18];

class Bingo extends React.Component {
    state = {
        numeros: [],
        hasCarton: true,
        activeModal: false,
        cases: [],
        tirage: {},
        alert: {
            active: false,
            type: '',
            title: '',
            msg: ''
        },
        carton: null,
        numsCartons: []
    }

    componentDidMount() {
        // Numéro afficher dans le tirage
        let numeros = [];

        for (let i = 1; i <= 90; i++) {
            numeros.push({
                value: i,
                isSort: false
            })
        }

        // Préparation du carton du joueur
        let tempCasesCarton = [];
        for (let i = 1; i<= 18; i++) {
            tempCasesCarton.push({
                value: i,
                place: arrayPlace.includes(i),
                numero: "",
                cocher: false
            });
        }

        this.setState({numeros: numeros, cases: tempCasesCarton});

        this.setState({
            alert: {
                active: true,
                type: 'warning',
                title: 'Recherche de données...',
                msg: 'Recherche du dernier tirage de bingo en cours...'
            }
        })
        this.props.getBingoTirage();
        this.props.getBingoGrille();

        setInterval(() => this.props.getBingoTirage(), 60000);
        // todo : obtenir la grille
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.bingotirage != undefined &&
            this.props.bingotirage != prevProps.bingotirage
        ) {
            let tempNumeros = this.state.numeros;
            let arrayNumeros = this.props.bingotirage.datas.numeros != null ? this.props.bingotirage.datas.numeros.split(',') : [];
            tempNumeros.forEach(num => {
                if (arrayNumeros.includes(num.value.toString())) {
                    num.isSort = true;
                }
            })

            this.setState({
                tirage: this.props.bingotirage.datas,
                alert: this.props.bingotirage.alert,
                numeros: tempNumeros
            });
        }

        if (
            this.props.bingogrille != undefined &&
            this.props.bingogrille != prevProps.bingogrille &&
            this.props.bingogrille.alert != this.state.alert
        ) {
            let tempCases = this.state.cases;
            let countNum = 0;
            let arrayNum = this.props.bingogrille.datas;
            tempCases.forEach(casee => {
                if (casee.place) {
                    casee.numero = arrayNum[countNum];
                    countNum += 1;
                }
            })
            this.createCarton(tempCases);
            this.setState({
                cases: tempCases,
                alert: this.props.bingogrille.alert,
                hasCarton: arrayNum.length > 0,
                numsCartons: arrayNum
            });
        }

        if (this.state.cases != prevState.cases) {
            this.createCarton(this.state.cases);
        }
    } 

    createCarton = (cases) => {
        let carton = <div className="bingo__carton">
            {
                cases.map((casee, index) => {
                    console.log(casee.cocher)
                    return <span onClick={() => this.cocherGrille(index)} className={`bingo__case ${casee.cocher && "bingo__case--cocher"}`}>{casee.numero}</span>
                })    
            }
        </div>;

        this.setState({carton: carton});
    }

    cocherGrille = (index) => {
        let tempCases = this.state.cases;
        tempCases[index].cocher = true;

        this.setState({cases: tempCases});
        this.createCarton(this.state.cases);
    }

    render() {
        return <section className="page page--bingo">
            <h2 className="page__ttl">Bingo en live</h2>
            {
                this.state.alert.active ?
                    <Alert class={`alert--${this.state.alert.type}`} ttl={this.state.alert.title}>{this.state.alert.msg}</Alert> : ""
            }
            <div className="bingo">
                {/** -- Tirage du bingo -- **/}
                <div className="bingo__result">
                    {
                        this.state.numeros.map(numero => {
                            return <span className={`bingo__resnum ${numero.isSort && "bingo__resnum--active"}`}>{numero.value}</span>
                        })
                    }
                </div>

                {/** -- Bouton modifier/créé carton -- **/}
                <button className="bingo__btn" onClick={() => this.setState({activeModal: true})}>
                    {this.state.hasCarton ? "Modifier la grille" : "Créer une grille"}
                </button>

                {/** -- Détails du carton -- **/}
                {
                    this.state.hasCarton &&
                    this.state.carton
                }

                {/** -- Modal ajout/création -- **/}
                {
                    this.state.activeModal &&
                    <BingoModal handleCloseModal={() => {
                        this.setState({activeModal: false});
                        this.props.getBingoGrille();
                    }} numeros={this.state.numsCartons}/>
                }
            </div>
        </section>
    }
}

const mapStateToProps = state => {
    return {
        bingotirage: state.bingoTirage,
        bingogrille: state.bingoGrille
    }
}

const mapDispatchToProps = {
    getBingoTirage,
    getBingoGrille
}

export default connect(mapStateToProps, mapDispatchToProps)(Bingo);