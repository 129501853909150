import { LW_ADD_SHOP, LW_GET_GRILLES, LW_GET_LOTS, LW_GET_NB_GRILLES, LW_GET_NB_GR_TYPE, LW_GET_SHOP, LW_GET_TEN_SUPER, LW_GET_TIRAGE, LW_GET_WINNER, LW_SEND_GRILLE, LW_SEND_GRILLE_TIRAGE } from "../actions/action-types";

const initialState = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
    isGetting: false,
    grilles: []
};

export function GetLwGrillesReducer(state = initialState, action) {
    switch (action.type) {
        case LW_GET_GRILLES:
            return {
                alert: action.payload.alert,
                isGetting: action.payload.isGetting,
                grilles: action.payload.grilles,
            }
        default:
            return state;
    }
}

export function GetLwWinnersReducer(state = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
    winners: []
}, action) {
    switch (action.type) {
        case LW_GET_WINNER:
            return {
                alert: action.payload.alert,
                winners: action.payload.winners
            }
        default:
            return state;
    }
}

export function GetLwTirageReducer(state = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
    isGetting: false,
    grilles: [],
    gains: {},
    tirage: null
}, action) {
    switch (action.type) {
        case LW_GET_TIRAGE:
            return {
                alert: action.payload.alert,
                isGetting: action.payload.isGetting,
                grilles: action.payload.grilles,
                tirage: action.payload.tirage,
                gains: action.payload.gains
            }
        default:
            return state;
    }
}

export function GetLwNbGrilleReducer(state = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
    nbs: {}
}, action) {
    switch (action.type) {
        case LW_GET_NB_GRILLES:
            return {
                alert: action.payload.alert,
                nbs: action.payload.nbs
            }
        default:
            return state;
    }
}

export function SendLwGrilleReducer(state = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
    isSend: false,
    nbHasGrille: null
}, action) {
    switch (action.type) {
        case LW_SEND_GRILLE:
            return {
                alert: action.payload.alert,
                isSend: action.payload.isSend,
                nbHasGrille: action.payload.nbHasGrille
            }
        default:
            return state;
    }
}

export function GetLwGrTypeReducer(state = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
    nbs: {}
}, action) {
    switch (action.type) {
        case LW_GET_NB_GR_TYPE:
            return {
                alert: action.payload.alert,
                nbs: action.payload.nbs
            }
        default:
            return state;
    }
}

export function GetLwTenSuperReducer(state = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
}, action) {
    switch (action.type) {
        case LW_GET_TEN_SUPER:
            return {
                alert: action.payload.alert,
            }
        default:
            return state;
    }
}

export function AddLwShopReducer(state = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
}, action) {
    switch (action.type) {
        case LW_ADD_SHOP:
            return {
                alert: action.payload.alert
            }
        default:
            return state;
    }
}

export function GetLwLotsReducer(state = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
    lots: []
}, action) {
    switch (action.type) {
        case LW_GET_LOTS:
            return {
                alert: action.payload.alert,
                lots: action.payload.lots,
            }
        default:
            return state;
    }
}

export function GetLwShopReducer(state = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
    datas: []
}, action) {
    switch (action.type) {
        case LW_GET_SHOP:
            return {
                alert: action.payload.alert,
                datas: action.payload.datas
            }
        default:
            return state;
    }
}

export function SendLwGrilleTirageReducer(state = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
}, action) {
    switch (action.type) {
        case LW_SEND_GRILLE_TIRAGE:
            return {
                alert: action.payload.alert
            }
        default:
            return state;
    }
}