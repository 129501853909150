import React from "react";
import iconSet from "../../selection.json";
import IcomoonReact from "icomoon-react";
import boulierImgSuper from "../../images/jeux/lotowin/boulier_super_tel.svg";
import boulierImgMaxi from "../../images/jeux/lotowin/boulier_maxi_tel.svg";
import boulierImgMega from "../../images/jeux/lotowin/boulier_mega_tel.svg";
import boulierImgExtra from "../../images/jeux/lotowin/boulier_extra_tel.svg";
import logoImgSuper from "../../images/jeux/lotowin/logo_super_tel.svg";
import logoImgMaxi from "../../images/jeux/lotowin/logo_maxi_tel.svg";
import logoImgMega from "../../images/jeux/lotowin/logo_mega_tel.svg";
import logoImgExtra from "../../images/jeux/lotowin/logo_extra_win.svg";
import { boulesTirageLW } from "../../helpers/import-boules";
import { connect } from "react-redux";
import { getLwTirage } from "../../actions/lotowin";
import soundFond from "../../sounds/lotowin/fond.mp3";
import soundBon1 from "../../sounds/lotowin/bon1.mp3";
import soundBon2 from "../../sounds/lotowin/bon2.mp3";
import soundBon3 from "../../sounds/lotowin/bon3.mp3";
import soundBon4 from "../../sounds/lotowin/bon4.mp3";
import soundBon5 from "../../sounds/lotowin/bon5.mp3";
import soundBonBonus1 from "../../sounds/lotowin/bonbonus1.mp3";
import soundBonBonus2 from "../../sounds/lotowin/bonbonus2.mp3";
import soundBonBonus3 from "../../sounds/lotowin/bonbonus3.mp3";
import soundSelec from "../../sounds/lotowin/selec.mp3";
import soundToutselec from "../../sounds/lotowin/toutselec.mp3";
import Credit from "../Credits";

const audioFond = new Audio(soundFond);
const audioBon1 = new Audio(soundBon1);
const audioBon2 = new Audio(soundBon2);
const audioBon3 = new Audio(soundBon3);
const audioBon4 = new Audio(soundBon4);
const audioBon5 = new Audio(soundBon5);
const audioBonBonus1 = new Audio(soundBonBonus1);
const audioSelec = new Audio(soundSelec);
const audioToutselec = new Audio(soundToutselec);

class LWTirage extends React.Component {
    state = {
        alert: {
            active: false,
            type: null,
            title: null,
            msg: null
        },
        boulier: {
            logo: null,
            boulier: null
        },
        boules: [],
        tirage: [],
        grilles: [],
        activeSearchTirage: false,
        activeModBonus: false,
        activeModEnd: false,
        indexNum: -2,
        gains: {}
    }

    componentDidMount() {
        console.log(this.props)
        if (this.props.typeb == "super") {
            this.setState({
                boulier: {
                    logo: logoImgSuper,
                    boulier: boulierImgSuper
                }
            })
        }
        if (this.props.typeb == "maxi") {
            this.setState({
                boulier: {
                    logo: logoImgMaxi,
                    boulier: boulierImgMaxi
                }
            })
        }
        if (this.props.typeb == "mega") {
            this.setState({
                boulier: {
                    logo: logoImgMega,
                    boulier: boulierImgMega
                }
            })
        }
        if (this.props.typeb == "extra") {
            this.setState({
                boulier: {
                    logo: logoImgExtra,
                    boulier: boulierImgExtra
                }
            })
        }
        this.setState({
            activeSearchTirage: true
        });
        setTimeout(() => {
            this.props.getLwTirage({type: this.props.typeb});
        }, 10000);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.datas != undefined &&
            this.props.datas.alert != this.state.alert
        ) {
            this.setState({alert: this.props.datas.alert});
        }
        if (
            this.props.datas != undefined &&
            this.props.datas.tirage != this.state.tirage &&
            this.props.datas.tirage != null
        ) {
            // Trouver les boules
            let expBoules = this.props.datas.tirage.boules.split(',');
            let tempBoules = [];
            expBoules.forEach((boule, index) => {
                tempBoules.push({
                    top1: false,
                    top2: false,
                    norm: index < 7,
                    num: boule
                });
            })
            // Trouver les grilles
            let tempGrilles = [];
            this.props.datas.grilles.forEach(grille => {
                let expNums = grille.numeros.split(',');
                let tempNums = [];
                expNums.forEach(num => {
                    tempNums.push({
                        isSort: false,
                        num: num
                    });
                })
                tempGrilles.push({
                    nbGoodNum: 0,
                    numeros: tempNums,
                });
            });
            // Gains
            this.setState({
                tirage: this.props.datas.tirage,
                boules: tempBoules,
                grilles: tempGrilles,
                gains: this.props.datas.gains
            });
            setTimeout(() => {
                audioFond.volume = 0.5;
                audioFond.play();
                this.setState({
                    activeSearchTirage: false
                })
                this.makeTirage();
            }, 1000);
        }
    } 

    makeTirage() {
        this.setState({activeModBonus: false});
        let tempBoules = this.state.boules;
        let tempIndex = this.state.indexNum;

        if (tempIndex >= 0) {
            if (tempIndex != 10 && tempIndex != 11) {
                tempBoules[tempIndex].top1 = true;
            }

            if (tempIndex != 0) {
                tempBoules[tempIndex - 1].top2 = true;
            } 
        }

        // Modifier le numéro de chaque grille si c'est lui sorti (-1)
        let tempGrilles = this.state.grilles;
        if (tempIndex > 0) {
            tempGrilles = [];
            this.state.grilles.forEach(grille => {
                let hasGoodNb = false;
                let countTemp = 0;
                let max = 5;
                if (tempIndex > 7) {
                    countTemp = 5;
                    max = 8;
                }
                while (countTemp < max && hasGoodNb == false) {
                    if (grille.numeros[countTemp].num == tempBoules[tempIndex - 1].num) {
                        hasGoodNb = true;
                    } else {
                        countTemp += 1;
                    }
                }
                if (hasGoodNb) {
                    grille.nbGoodNum += 1;
                    grille.numeros[countTemp].isSort = true;
                    if (tempIndex <= 7) {
                        if (grille.nbGoodNum == 1) {
                            audioBon1.play();
                        }
                        if (grille.nbGoodNum == 2) {
                            audioBon2.play();
                        }
                        if (grille.nbGoodNum == 3) {
                            audioBon3.play();
                        }
                        if (grille.nbGoodNum == 4) {
                            audioBon4.play();
                        }
                        if (grille.nbGoodNum == 5) {
                            audioBon5.play();
                        }
                    } else {
                        audioBonBonus1.play();
                    }
                }
                tempGrilles.push(grille);
            });
        }
        
        tempIndex += 1;
        this.setState({
            boules: tempBoules,
            indexNum: tempIndex,
            grilles: tempGrilles
        });
        if (tempIndex != 11 && tempIndex != 8) {
            setTimeout(() => {
                this.makeTirage();
            }, 2000);
        }
        if (tempIndex == 8) {
            setTimeout(() => {
                this.setState({activeModBonus: true});
            }, 1000);
            setTimeout(() => {
                this.makeTirage();
            }, 5000);
        }
        if (tempIndex == 11) {
            this.setState({
                activeModEnd: true
            })
        }
    }

    endTirage() {

    }

    render() {
        return <section className="lw__ti">
            {/** Boules avant tirage (qui montent) */}
            {/** Todo modifier compteur super (tirage toutes les 15 min au lieu de 10) */}
            <div className="lw__ti__boulespre">
                {
                    this.state.boules.length > 0 ?
                        this.state.boules.map((boule, index) => {
                            return <img key={index} src={boulesTirageLW[this.props.typeb][boule.num - 1]} alt="Boule Lotowin" className={`lw__ti__boulepre ${boule.top1 && `lw__ti__boulepre--top--${boule.norm ? "norm" : "bonus"}`}`} />
                        })
                        : ""
                }
            </div>
            {/** Boules normales après tirages (dans rangée) */}
            <div className="lw__ti__boulesnorm">
                {
                    this.state.boules.length > 0 ?
                        this.state.boules.map((boule, key) => {
                            if (key >= 7) {
                                return "";
                            }
                            console.log("============")
                            console.log(this.props.typeb);
                            console.log(boule.num);
                            console.log(boulesTirageLW)
                            return <img src={boulesTirageLW[this.props.typeb][boule.num - 1]} alt="Boule Lotowin" className={`lw__ti__boulenorm ${boule.top2 && "lw__ti__boulenorm--active"}`} />
                        })
                        : ""
                }
            </div>
            {/** Boules bonus après tirages (dans rangée) */}
            <div className="lw__ti__boulesbonus">
                {
                    this.state.boules.length > 0 ?
                        this.state.boules.map((boule, key) => {
                            if (key < 7) {
                                return "";
                            }
                            return <img src={boulesTirageLW[this.props.typeb][boule.num - 1]} alt="Boule Lotowin" className={`lw__ti__boulenorm ${boule.top2 && "lw__ti__boulenorm--active"}`} />
                        })
                        : ""
                }
            </div>
            {/** Logo */}
            <img src={this.state.boulier.logo} alt="Logo Lotowin" className="lw__ti__logo" />
            {/** Boulier */}
            <img src={this.state.boulier.boulier} alt="Boulier Lotowin" className="lw__ti__img" />
                   
            {
                this.state.activeSearchTirage &&
                <div className="lw__ti__mod">
                    <h3 className="lw__ti__modttl">Recherche du tirage en cours...</h3>
                </div>
            }

            {
                this.state.activeModBonus &&
                <div className="lw__ti__mod">
                    <h3 className="lw__ti__modttl">Tirage des boules bonus</h3>
                </div>
            }
            {
                this.state.activeModEnd &&
                <div className="lw__ti__mod">
                    <h3 className="lw__ti__modttl">Vos gains :</h3>
                    {
                        (
                            this.state.gains['grilles-maxi'] == 0 &&
                            this.state.gains['grilles-mega'] == 0 &&
                            this.state.gains['grilles-extra'] == 0 &&
                            this.state.gains['goodies'] == 0 &&
                            this.state.gains['credits'] == 0 &&
                            this.state.gains['grosGains'].length == 0
                        ) ?
                            <div className="lw__ti__modcontent">
                                <h3 className="lw__ti__modsubttl">Vous n'avez rien gagné lors de ce tirage</h3>
                            </div> :
                            <div className="lw__ti__modcontent">
                                {
                                    this.state.gains['grosGains'].length != 0 &&
                                    this.state.gains['grosGains'].map(gain => <span className="lw__ti__modtxt lw__ti__modtxt--gros">
                                        {gain}
                                        <p className="lw__ti__modhelp">(Veuillez contacter Matt)</p>
                                    </span>)
                                }
                                {
                                    this.state.gains['grilles-extra'] != 0 &&
                                    <span className="lw__ti__modtxt lw__ti__modtxt--extra">
                                        {this.state.gains['grilles-extra']}
                                        <IcomoonReact iconSet={iconSet} icon="table2" />
                                    </span>
                                }
                                {
                                    this.state.gains['grilles-mega'] != 0 &&
                                    <span className="lw__ti__modtxt lw__ti__modtxt--mega">
                                        {this.state.gains['grilles-mega']}
                                        <IcomoonReact iconSet={iconSet} icon="table2" />
                                    </span>
                                }
                                {
                                    this.state.gains['grilles-maxi'] != 0 &&
                                    <span className="lw__ti__modtxt lw__ti__modtxt--maxi">
                                        {this.state.gains['grilles-maxi']}
                                        <IcomoonReact iconSet={iconSet} icon="table2" />
                                    </span>
                                }
                                {
                                    this.state.gains['goodies'] != 0 &&
                                    <span className="lw__ti__modtxt">
                                        {this.state.gains['goodies']}
                                        Goodies Tof La Win
                                    </span>
                                }
                                {
                                    this.state.gains['credits'] != 0 &&
                                    <Credit nb={this.state.gains['credits']} class="lw__ti__modtxt" />
                                }
                            </div>
                    }
                </div>
            }
            {/** Todo ajouter les grilles du joueur */}
            <div className="lw__ti__grilles">
                {
                    this.state.grilles
                        /*.sort((a, b) => {
                            console.log("=====")
                            console.log(a.nbGoodNum);
                            console.log(b.nbGoodNum);
                            if (a.nbGoodNum < b.nbGoodNum) {
                                return 1;
                            }
                            if (a.nbGoodNum < b.nbGoodNum) {
                                return -1;
                            }
                        })*/
                        .map((grille, key) => {
                            return <div className="lw__ti__grille" style={{order: 8 - grille.nbGoodNum}} key={`grille--${key}`}>
                                {
                                    grille.numeros.map((num, index) => {
                                        return <span
                                            key={`num--${index}`}
                                            className={`
                                                lw__ti__num
                                                lw__ti__num--${this.props.typeb}
                                                ${index > 4 && `lw__ti__num--${this.props.typeb}--bonus`}
                                                ${num.isSort && `lw__ti__num--${this.props.typeb}${index > 4 ? '--bonus' : ''}--sort`}`}>
                                            {num.num}
                                        </span>
                                    })
                                }
                            </div>
                    })
                }
            </div>
        </section>
    }
}

const mapStateToProps = state => {
    return {
        datas: state.lwtirage
    }
}

const mapDispatchToProps = {
    getLwTirage
}

export default connect(mapStateToProps, mapDispatchToProps)(LWTirage);