import React from "react";
import logo from "../images/logo.svg";
import { Link } from "react-router-dom";

class Footer extends React.Component {
    render() {
        return <footer className="footer">
            <div className="footer__content">
                <section className="footer__section">
                    <h2 className="footer__ttl">Plan du site</h2>
                    <ul className="footer__list">
                        <li className="footer__item">
                            <Link className="footer__link" to="/videos">
                                Les Vidéos
                            </Link>
                        </li>
                        <li className="footer__item">
                            <Link className="footer__link" to="/jeux">
                                Les jeux
                            </Link>
                        </li>
                        <li className="footer__item">
                            <Link className="footer__link" to="/concours">
                                Les concours
                            </Link>
                        </li>
                        <li className="footer__item">
                            <Link className="footer__link" to="/calendrier">
                                Les évènements
                            </Link>
                        </li>
                        <li className="footer__item">
                            <Link className="footer__link" to="/statistiques">
                                Les statistiques
                            </Link>
                        </li>
                    </ul>
                </section>
                <img src={logo} className="footer__logo" alt="Logo Tof La Win - Chaine Youtube de grattage" />
                <section className="footer__section">
                    <h2 className="footer__ttl">Informations</h2>
                    <ul className="footer__list">
                        <li className="footer__item">
                            <Link className="footer__link" to="/contact">
                                Contactez-nous
                            </Link>
                        </li>
                        <li className="footer__item">
                            <Link className="footer__link" to="/mentions-legales">
                                Mentions légales
                            </Link>
                        </li>
                        <li className="footer__item">
                            <Link className="footer__link" to="/politique-confidentialite">
                                Politique de confidentialité
                            </Link>
                        </li>
                    </ul>
                </section>
            </div>
            <div className="footer__fixe">
                <span>© 2024 TOF LA WIN - Tous droits réservés</span>
                <Link to="/contact">Contactez-nous</Link>
            </div>
        </footer>
    }
}

export default Footer;