import React from "react";

class Loading extends React.Component {
    render() {
        return <main className="page">
            <h2 className="page__ttl">Page introuvable</h2>
            <h3 className="page__empty">Cette page n'est pas disponible pour le moment, veuillez réessayer plus tard.</h3>
        </main>
    }
}

export default Loading