import { combineReducers } from "redux";
import { AuthenticationReducer, ForgotReducer } from "./authentication";
import { GetDataReducer } from "./home";
import { GetJeuClassementReducer, GetJeuStatsChiffresReducer, GetJeuStatsGraphReducer, GetJeuxReducer, JeuReducer, SetGainReducer } from "./jeu";
import { AddLwShopReducer, GetLwGrTypeReducer, GetLwGrillesReducer, GetLwLotsReducer, GetLwNbGrilleReducer, GetLwShopReducer, GetLwTenSuperReducer, GetLwTirageReducer, GetLwWinnersReducer, SendLwGrilleReducer, SendLwGrilleTirageReducer } from "./lotowin";
import { MdpNewMdpReducer, MdpSendCodeReducer, MdpVerifCodeReducer, SetNewUserReducer } from "./login";
import { EditProfilReducer, GetDatasNbJeuxReducer, GetProfilReducer, GetVerifRoueReducer, SetAddRoueReducer, SetConvertPointReducer, SetLastCoReducer, SetRemoveCreditsReducer, VerifCreditsReducer } from "./profil";
import { DeleteMessageReducer, GetForumReducer, GetMsgSalonReducer, GetNotifsReducer, GetSalonReducer, GetSalonsReducer, GetUserByLetterReducer, RemoveNotifReducer, SendMessageReducer } from "./tchat";
import { GetAnnoncesReducer, GetCalendarReducer, GetPokerReducer, GetStatTofReducer } from "./divers";
import { GetVideosReducer } from "./videos";
import { getBingoClassementReducer, getBingoGrilleReducer, getBingoTirageReducer, setBingoGrilleReducer, setBingoRazReducer, setBingoTirageReducer } from "./bingo";

const rootReducer = combineReducers({
    authentication: AuthenticationReducer,
    forgot: ForgotReducer,
    getdatas: GetDataReducer,
    jeu: JeuReducer,
    lwgrilles: GetLwGrillesReducer,
    lwtirage: GetLwTirageReducer,
    lwnbgrille: GetLwNbGrilleReducer,
    lwsendgrille: SendLwGrilleReducer,
    lwgetnbtype: GetLwGrTypeReducer,
    lwsendgrilletirage: SendLwGrilleTirageReducer,
    lwgettensuper: GetLwTenSuperReducer,
    shop: GetLwShopReducer,
    shopAdd: AddLwShopReducer,
    lwlots: GetLwLotsReducer,
    lwwinners: GetLwWinnersReducer,
    mdpsendcode: MdpSendCodeReducer,
    mdpverifcode: MdpVerifCodeReducer,
    mdpnewcode: MdpNewMdpReducer,
    addnewuser: SetNewUserReducer,
    // Profil
    profil: GetProfilReducer, //ok
    editprofil: EditProfilReducer, //ok
    lastco: SetLastCoReducer, // ok
    datasnbjeu: GetDatasNbJeuxReducer, // ok
    removecred: SetRemoveCreditsReducer,
    convpoints: SetConvertPointReducer,
    verifroue: GetVerifRoueReducer,
    addroue: SetAddRoueReducer,
    verifcredit: VerifCreditsReducer,
    // Jeux
    jeux: GetJeuxReducer,
    setgains: SetGainReducer,
    jeuclas: GetJeuClassementReducer,
    jeustachi: GetJeuStatsChiffresReducer,
    jeustagra: GetJeuStatsGraphReducer,
    // Tchat
    tchforum: GetForumReducer,
    tchsalons: GetSalonsReducer,
    tchsalon: GetSalonReducer,
    tchnotif: GetNotifsReducer,
    tchsendmess: SendMessageReducer,
    tchmsgsalon: GetMsgSalonReducer,
    tchdel: DeleteMessageReducer,
    tchuserlet: GetUserByLetterReducer,
    tchremnoti: RemoveNotifReducer,
    // Divers
    divpok: GetPokerReducer,
    divsta: GetStatTofReducer,
    divann: GetAnnoncesReducer,
    divcal: GetCalendarReducer,
    // Vidéos
    videos: GetVideosReducer,
    // Bingo
    bingoTirage: getBingoTirageReducer,
    bingoGrille: getBingoGrilleReducer,
    bingosetgrille: setBingoGrilleReducer,
    bingosetitrage: setBingoTirageReducer,
    bingoClassement: getBingoClassementReducer,
    bingoraz: setBingoRazReducer
});

export default rootReducer;