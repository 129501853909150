export const getDomain = () => {
    // return 'http://127.0.0.1:8000/';
    return 'https://api.toflawin.fr/';
}

export const config = {
    headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Credentials':'true',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers':'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
    }
}

export const logout = () => {
    localStorage.removeItem('credentials');
    localStorage.removeItem('userId');
    localStorage.removeItem('prenom');
    localStorage.removeItem('nom');
    localStorage.removeItem('avatar');
    localStorage.removeItem('role');
    localStorage.setItem('isLoggin', false);
    alert("Vous êtes bien déconnecté !");
    window.location.reload();
}

export const isAdmin = () => {
    let verif = false;
    JSON.parse(localStorage.getItem('role')).forEach(role => {
        if (role == "ROLE_SUPER_ADMIN" || role == "ROLE_ADMIN") {
            verif = true;
        }
    })

    return verif;
}

export const getDate = (date) => {
    let theDate = "Le ";

    let tempDate = new Date(date);
    let tempMonth = tempDate.getMonth() + 1;

    theDate += (tempDate.getDate() < 10 ? "0" : "") + tempDate.getDate() + "/";
    theDate += (tempMonth < 10 ? "0" : "") + tempMonth + "/";
    theDate += tempDate.getFullYear();

    return theDate;
}

export const getDateTime = (date) => {
    let theDate = "Le ";

    let tempDate = new Date(date);
    if (tempDate.getHours() == 0 && tempDate.getMinutes() == 0 && tempDate.getSeconds() == 0) {
        return "Aucune date";
    } else {
        let tempMonth = tempDate.getMonth() + 1;

        theDate += (tempDate.getDate() < 10 ? "0" : "") + tempDate.getDate() + "/";
        theDate += (tempMonth < 10 ? "0" : "") + tempMonth + "/";
        theDate += tempDate.getFullYear();
        theDate += " à ";
        theDate += (tempDate.getHours() < 10 ? "0" : "") + tempDate.getHours() + ":";
        theDate += (tempDate.getMinutes() < 10 ? "0" : "") + tempDate.getMinutes();
    
        return theDate;
    }
}

export const formatedCredit = (credits) => {
    let res = "";
    
    if (credits != undefined) {
        credits = credits.toString();

        if (credits < 1000) {
            res = credits;
        } else {
            if (credits < 10000) {
                res = credits[0] + "," + credits[1] + "K";
            } else {
                if (credits < 100000) {
                    console.log(credits)
                    res = credits[0] + credits[1] + "," + credits[2] + "K";
                } else {
                    if (credits < 1000000) {
                        res = credits[0] + credits[1]  + credits[2] + "K";
                    } else {
                        if (credits < 10000000) {
                            res = credits[0] + "," + credits[1] + "M";
                        } else {
                            if (credits < 100000000) {
                                res = credits[0] + credits[1] + "," + credits[2] + "M";
                            } else {
                                if (credits < 1000000000) {
                                    res = credits[0] + credits[1]  + credits[2] + "M";
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return res;
}