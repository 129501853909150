import React from "react";
import { getAnnonces } from "../../actions/divers";
import { connect } from "react-redux";
import Card from "../../components/Card";

class Annonces extends React.Component {
    state = {
        alert: {
            active: true,
            type: 'warning',
            title: 'Recherche de données...',
            msg: 'Recherche des annonces en cours...'
        }
    }

    componentDidMount() {
        document.title = 'Tof La Win - Annonces';
        this.props.getAnnonces();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.divann != undefined &&
            this.props.divann != prevProps.divann &&
            this.props.divann.alert != this.state.alert
        ) {
            this.setState({alert: this.props.divann.alert});
        }
    }

    render() {
        return <main className="page">
            <h2 className="page__ttl">Actualités</h2>
            <section className="page__listcard">
                {/** Todo ajouter compo Card */}
                {
                    this.props.divann.datas.length == 0 ?
                        <h2 className="page__empty">Aucune actualité pour le moment</h2> :
                        this.props.divann.datas.map((ann, index) => {
                            console.log(ann)
                            return <Card
                                class={null}
                                title={ann.titre}
                                subttl={null}
                                date={ann.date?.date}
                                linkimg={null}
                                imglink={null}
                                desc={ann.description}
                                img={null}
                                link={null}
                            />
                        })
                }
            </section>
        </main>
    }
}

const mapStateToProps = state => {
    return {
        divann: state.divann
    }
}

const mapDispatchToProps = {
    getAnnonces
}

export default connect(mapStateToProps, mapDispatchToProps)(Annonces);