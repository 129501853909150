import { getDomain, config } from "../helpers";
import axios from "axios";
import { GET_FORUM, GET_SALONS, GET_NOTIFS, SET_MESSAGE, GET_USER_MSG, GET_SALON, DELETE_MESSAGE, GET_USER_LETTER, REMOVE_CREDITS } from "./action-types";

// === Messages =======================
// Obtenir les messages du forum général
export function getForum() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Réception des messages en cours',
            msg: ''
        };
        let messages = [];

        axios.post(`${getDomain()}tchat/forum`, {}, config)
            .then(res => {
                alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                if (res.data.messages.length > 0) {
                    messages = res.data.messages;
                }

                dispatch({
                    type: GET_FORUM,
                    payload: {
                        alert: alert,
                        datas: messages
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: "Vous n'avez pas pu recevoir les messages du forum. Veuillez contacter Matt (Code : Tch001)",
                    msg: ""
                };
                dispatch({
                    type: GET_FORUM,
                    payload: {
                        alert: alert,
                        datas: messages
                    }
                })
            })
    }
}

// Obtenir les salons du user
export function getSalons() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Recherche de vos salons en cours...',
            msg: ''
        };
        let salons = [];

        axios.post(`${getDomain()}tchat/salons/${localStorage.userId}`, {}, config)
            .then(res => {
                alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                salons = res.data.salons;

                dispatch({
                    type: GET_SALONS,
                    payload: {
                        alert: alert,
                        datas: salons
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: "Vous n'avez pas pu recevoir vos salons. Veuillez contacter Matt (Code : Tch002)",
                    msg: ""
                };
                dispatch({
                    type: GET_SALONS,
                    payload: {
                        alert: alert,
                        datas: salons
                    }
                })
            })
    }
}

// Obtenir les messages du salon
export function getSalon({otherUserId}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Réception des messages en cours...',
            msg: ''
        };
        let next = false;
        let id = null;

        axios.post(`${getDomain()}tchat/prive/tchat-empty/${otherUserId}`, {
            credentials: localStorage.credentials,
            user: localStorage.userId
        }, config)  
            .then(res => {
                if (res.data['id'] != undefined) {
                    alert = {
                        active: false,
                        type: '',
                        title: '',
                        msg: ''
                    };
                    next = true;
                    id = res.data.id;
                }  else {
                    if (res.data[0] == "compte_unexist") {
                        alert = {
                            active: true,
                            type: 'error',
                            title: "Ce compte n'existe pas",
                            msg: ''
                        };
                    } else {
                        alert = {
                            active: true,
                            type: 'error',
                            title: "Vous n'avez pas pu recevoir les données de ce salon. Veuillez contacter Matt (Code : Tch003)"
                        }
                    }
                }

                dispatch({
                    type: GET_SALON,
                    payload: {
                        alert: alert,
                        id: id,
                        next: next
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: "Vous n'avez pas pu recevoir vos salons. Veuillez contacter Matt (Code : Tch002)",
                    msg: ""
                };
                dispatch({
                    type: GET_SALON,
                    payload: {
                        alert: alert,
                        id: id,
                        next: next
                    }
                })
            })
    }
}

// Obtenir le nombre de notif
export function getNotifs() {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Réception des notifications en cours...',
            msg: ''
        };
        let nbnotifs = 0;

        axios.post(`${getDomain()}tchat/notifications/${localStorage.userId}`, {}, config) 
            .then(res => {
                let alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                let nbnotifs = res.data.notifs;

                dispatch({
                    type: GET_NOTIFS,
                    payload: {
                        alert: alert,
                        nbnotifs: nbnotifs
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: "Vous n'avez pas pu recevoir vos notifications. Veuillez contacter Matt (Code : Tch004)",
                    msg: ""
                };
                dispatch({
                    type: GET_NOTIFS,
                    payload: {
                        alert: alert,
                        nbnotifs: nbnotifs,
                    }
                })
            })
    }
}

// Envoyer un message
export function sendMessage({ message, salon }) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Envoi du message en cours...',
            msg: ''
        };
        let next = false;

        axios.post(`${getDomain()}tchat/sendmessage`, {
            user: localStorage.userId,
            credentials: localStorage.credentials,
            message: message,
            salon: salon
        }, config)  
            .then(res => {
                switch (res.data[0]) {
                    case "send_message":
                        next = true;
                        alert = {
                            active: false,
                            type: '',
                            title: '',
                            msg: ''
                        };
                        break;
                    case "compte_unexist":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue. Veuillez contacter Matt (Code : Tch006)',
                            msg: ''
                        };
                        break;
                    case "error":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue. Veuillez contacter Matt (Code : Tch007)',
                            msg: ''
                        };
                        break;
                }

                dispatch({
                    type: SET_MESSAGE,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: "Vous n'avez pas pu envoyer le message. Veuillez contacter Matt (Code : Tch008)",
                    msg: ""
                };
                dispatch({
                    type: SET_MESSAGE,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
    }
}

// Obtenir les messages d'un salon
export function getMsgSalon({salon}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Recherche des messages en cours...',
            msg: ''
        };
        let datas = [];

        axios.post(`${getDomain()}tchat/prive/${salon}`, {}, config)
            .then(res => {
                alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };
                datas = res.data.messages;

                dispatch({
                    type: GET_USER_MSG,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: "Vous n'avez pas pu recevoir les messages. Veuillez contacter Matt (Code : Tch010)",
                    msg: ""
                };
                dispatch({
                    type: GET_USER_MSG,
                    payload: {
                        alert: alert,
                        datas: datas
                    }
                })
            })
    }
}

// Supprimer un message
export function deleteMessage({message}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Suppression du message en cours...',
            msg: ''
        };
        let next = false;

        axios.post(`${getDomain()}tchat/delete/${message}`, {
            user: localStorage.userId,
            credentials: localStorage.credentials
        }, config)  
            .then(res => {
                switch (res.data[0]) {
                    case 'delete_message':
                        alert = {
                            active: true,
                            type: 'success',
                            title: 'Message supprimé',
                            msg: ''
                        };
                        next = true;
                        break;
                    case 'not_user':
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Vous ne pouvez pas supprimer ce message',
                            msg: ''
                        };
                        break;
                    case 'compte_unexist':
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue. Veuillez contacter Matt (Code : Tch011)',
                            msg: ''
                        };
                        break;
                    case 'error':
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue. Veuillez contacter Matt (Code : Tch012)',
                            msg: ''
                        }
                        break;
                }

                dispatch({
                    type: DELETE_MESSAGE,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: "Vous n'avez pas pu supprimer ce message. Veuillez contacter Matt (Code : Tch013)",
                    msg: ""
                };
                dispatch({
                    type: DELETE_MESSAGE,
                    payload: {
                        alert: alert,
                        next: next
                    }
                })
            })
    }
}

// Obtenir utilisateur par les lettres
export function getUserByLetter({letters}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Recherche des utilisateurs en cours...',
            msg: ''
        };
        let users = [];

        axios.post(`${getDomain()}tchat/search/user/${letters}`, {}, config)
            .then(res => {
                let alert = {
                    active: false,
                    type: '',
                    title: '',
                    msg: ''
                };

                users = res.data;

                dispatch({
                    type: GET_USER_LETTER,
                    payload: {
                        alert: alert,
                        datas: users
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: "Vous n'avez pas pu avoir la liste des utilisateurs. Veuillez contacter Matt (Code : Tch014)",
                    msg: ""
                };
                dispatch({
                    type: GET_USER_LETTER,
                    payload: {
                        alert: alert,
                        datas: users
                    }
                })
            })
    }
}

// Enlever les notifs d'un salon
export function removeNotif({salon, notif}) {
    return function (dispatch) {
        let alert = {
            active: true,
            type: 'warning',
            title: 'Suppression des notifications de ce salon...',
            msg: ''
        };
        let nbnotif = notif;

        axios.post(`${getDomain()}tchat/remove-notif/${salon}`, {
            user: localStorage.userId,
            credentials: localStorage.credentials
        }, config)
            .then(res => {
                switch (res.data[0]) {
                    case "ok":
                        alert = {
                            active: false,
                            type: '',
                            title: '',
                            msg: ''
                        };
                        nbnotif = 0;
                        break;
                    case "not_in_salon":
                            alert = {
                                active: true,
                                type: 'error',
                                title: 'Une erreur est survenue, veuillez contacter Matt (Code : Tch015)',
                                msg: ''
                            };
                            break;
                    case "compte_unexist":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue, veuillez contacter Matt (Code : Tch016)',
                            msg: ''
                        };
                        break;
                    case "error":
                        alert = {
                            active: true,
                            type: 'error',
                            title: 'Une erreur est survenue, veuillez contacter Matt (Code : Tch017)',
                            msg: ''
                        };
                        break;
                }

                dispatch({
                    type: REMOVE_CREDITS,
                    payload: {
                        alert: alert,
                        nbnotif: nbnotif
                    }
                })
            })
            .catch(error => {
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Une erreur est survenue, veuillez contacter Matt (Code : Tch018)',
                    msg: ""
                };
                dispatch({
                    type: REMOVE_CREDITS,
                    payload: {
                        alert: alert,
                        nbnotif: nbnotif
                    }
                })
            })
    }
}