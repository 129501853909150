import React from "react";
import { Link } from "react-router-dom";
import { getDateTime } from "../helpers";

class Card extends React.Component {
    render() {
        return <article className={`card ${this.props.class}`}>
            <h2 className="card__ttl">
                {this.props.title}
            </h2>
            <h3 className="card__subttl">{this.props.subttl}</h3>
            <span className="card__date">{this.props.date != null && getDateTime(this.props.date)}</span>
            {
                this.props.linkimg != null ?
                    <Link to={this.props.linkimg} className="card__imglink">
                        <img src={this.props.imglink} alt="Miniature de vidéo" className="card__img" />
                    </Link> : ""
            }
            {
                this.props.otherlink != null ?
                    <a href={this.props.otherlink} className="card__imglink">
                        <img src={this.props.imglink} alt="Miniature de vidéo" className="card__img" />
                    </a> : ""
            }
            <p className="card__desc">{this.props.desc}</p>
            {/*
                this.props.linkimg != null ?
                    <Link to={this.props.linkimg} className="card__imglink">
                        <img src={this.props.img} alt="Miniature de vidéo" className="card__img" />
                    </Link> : ""
        */}
            {
                this.props.img != null ?
                    <img src={this.props.img} alt="Miniature de vidéo" className="card__img" /> : ""
            }
            {
                this.props.link != null ?
                    <Link to={this.props.link} className="btn card__btn">{this.props.txtlink}</Link> : ""
            }
        </article>
    }
}

export default Card;