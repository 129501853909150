import { GET_FORUM, GET_SALONS, GET_SALON, GET_NOTIFS, SET_MESSAGE, GET_USER_MSG, DELETE_MESSAGE, GET_USER_LETTER, REMOVE_CREDITS } from "../actions/action-types";

const initialStateNext = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
    next: false
};

const initialStateDatas = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
    datas: []
};

const initialStateNotif = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
    nbnotifs: 0
}

export function GetForumReducer(state = initialStateDatas, action) {
    switch (action.type) {
        case GET_FORUM:
            return {
                alert: action.payload.alert,
                datas: action.payload.datas
            }
        default:
            return state;
    }
}

export function GetSalonsReducer(state = initialStateDatas, action) {
    switch (action.type) {
        case GET_SALONS:
            return {
                alert: action.payload.alert,
                datas: action.payload.datas
            }
        default:
            return state;
    }
}

export function GetSalonReducer(state = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
    id: null,
    next: false
}, action) {
    switch (action.type) {
        case GET_SALON:
            return {
                alert: action.payload.alert,
                id: action.payload.id,
                next: action.payload.next
            }
        default:
            return state;
    }
}

export function GetNotifsReducer(state = initialStateNotif, action) {
    switch (action.type) {
        case GET_NOTIFS:
            return {
                alert: action.payload.alert,
                nbnotifs: action.payload.nbnotifs
            }
        default:
            return state;
    }
}

export function SendMessageReducer(state = initialStateNext, action) {
    switch (action.type) {
        case SET_MESSAGE:
            return {
                alert: action.payload.alert,
                next: action.payload.next
            }
        default: 
            return state;
    }
}

export function GetMsgSalonReducer(state = initialStateDatas, action) {
    switch (action.type) {
        case GET_USER_MSG:
            return {
                alert: action.payload.alert,
                datas: action.payload.datas
            }
        default:
            return state;
    }
}

export function DeleteMessageReducer(state = initialStateNext, action) {
    switch (action.type) {
        case DELETE_MESSAGE:
            return {
                alert: action.payload.alert,
                next: action.payload.next
            }
        default:
            return state;
    }
}

export function GetUserByLetterReducer(state = initialStateDatas, action) {
    switch (action.type) {
        case GET_USER_LETTER:
            return {
                alert: action.payload.alert,
                datas: action.payload.datas
            }
        default:
            return state;
    }
}

export function RemoveNotifReducer(state = initialStateNotif, action) {
    switch (action.type) {
        case REMOVE_CREDITS:
            return {
                alert: action.payload.alert,
                nbnotifs: action.payload.nbnotif
            }
        default:
            return state;
    }
}