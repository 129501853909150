import React from "react";
import { Link } from "react-router-dom";
import { getBingoTirage, setBingoNum, getBingoClassement, setBingoRaz } from "../../actions/bingo";
import { connect } from "react-redux";
import Alert from "../Alert";

class BingoAdmin extends React.Component {
    state = {
        alert: {
            active: false,
            type: '',
            title: '',
            msg: ''
        },
        numeros: [],
        classement: []
    }

    componentDidMount() {
        // Numéro afficher dans le tirage
        let numeros = [];

        for (let i = 1; i <= 90; i++) {
            numeros.push({
                value: i,
                isSort: false
            })
        }

        this.setState({
            numeros: numeros, 
            alert: {
                active: true,
                type: 'warning',
                title: 'Recherche de données...',
                msg: 'Recherche du dernier tirage de bingo en cours...'
            }
        })

        this.props.getBingoTirage();
        this.props.getBingoClassement();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.bingotirage != undefined &&
            this.props.bingotirage != prevProps.bingotirage &&
            this.props.bingotirage.alert != this.state.alert
        ) {
            let tempNumeros = this.state.numeros;
            let arrayNumeros = this.props.bingotirage.datas.numeros != null ? this.props.bingotirage.datas.numeros.split(',') : [];
            tempNumeros.forEach(num => {
                if (arrayNumeros.includes(num.value.toString())) {
                    num.isSort = true;
                }
            })

            this.setState({
                tirage: this.props.bingotirage.datas,
                alert: this.props.bingotirage.alert,
                numeros: tempNumeros
            });
        }

        if (
            this.props.bingosetitrage != undefined &&
            this.props.bingosetitrage != prevProps.bingosetitrage
        ) {
            if (this.props.bingosetitrage.alert.active) {
                this.setState({
                    alert: this.props.bingosetitrage.alert
                })
            } else {
                this.setState({
                    alert: {
                        active: true,
                        type: 'warning',
                        title: 'Recherche de données...',
                        msg: 'Recherche du dernier tirage de bingo en cours...'
                    }
                })
                this.props.getBingoTirage();
                this.props.getBingoClassement();
            }
        }

        if (
            this.props.bingoClassement != undefined &&
            this.props.bingoClassement != prevProps.bingoClassement
        ) {
            this.setState({
                alert: this.props.bingoClassement.alert,
                classement: this.props.bingoClassement.datas
            })
        }

        if (
            this.props.bingoraz != undefined &&
            this.props.bingoraz != prevProps.bingoraz &&
            this.props.bingoraz.alert != this.state.alert
        ) {
            this.setState({
                alert: this.props.bingoraz.alert
            })
            this.props.getBingoClassement();
            this.props.getBingoTirage();
        }
    } 

    handleSendNum = (num) => {
        if (window.confirm("Souhaitez-vous envoyer le numéro " + num + " ?")) {
            this.setState({
                alert: {
                    active: true,
                    type: 'warning',
                    title: 'Analyse du numéro',
                    msg: 'Analyse du numéro en cours...'
                }
            })
            this.props.setBingoNum({num: num});
        }
    }

    handleRaz = () => {
        if (window.confirm("Souhaitez-vous faire une remise à zéro ?")) {
            this.props.setBingoRaz();
        }
    }

    render() {
        return <section className="page page--bingo">
            <h2 className="page__ttl">Bingo Admin</h2>
            {
                this.state.alert.active ?
                    <Alert class={`alert--${this.state.alert.type}`} ttl={this.state.alert.title}>{this.state.alert.msg}</Alert> : ""
            }
            <header className="page__headlistbtn">
                <Link to="/bingo-admin" className="bingo__btn">Le tirage</Link>
                <Link to="/bingo-joueurs" className="bingo__btn">Les joueurs</Link>
                <button type="button" onClick={() => this.handleRaz()} className="bingo__btn">Remise à zéro</button>
            </header>
            <div className="bingo">
                {/** Grille du tirage */}
                <div className="bingo__result">
                    {
                        this.state.numeros.map(numero => {
                            return <button
                                className={`bingo__resnum ${numero.isSort && "bingo__resnum--active"}`}
                                onClick={() => this.handleSendNum(numero.value)}
                            >{numero.value}</button>
                        })
                    }
                </div>

                {/** Classement joueurs */}
                <div className="bingo__classement">
                    {
                        this.state.classement.map(joueur => {
                            return <span className="bingo__classementjoueur">Plus que <b>{joueur.nb}</b> numéros pour <b>{joueur.pseudo}</b></span>
                        })
                    }
                </div>
            </div>
        </section>
    }
}

const mapStateToProps = state => {
    return {
        bingotirage: state.bingoTirage,
        bingosetitrage: state.bingosetitrage,
        bingoClassement: state.bingoClassement,
        bingoraz: state.bingoraz
    }
}

const mapDispatchToProps = {
    getBingoTirage,
    setBingoNum,
    getBingoClassement,
    setBingoRaz
}


export default connect(mapStateToProps, mapDispatchToProps)(BingoAdmin);