import { BINGO_GET_CLASS, BINGO_GET_GRILLE, BINGO_GET_JOUEURS, BINGO_GET_TIRAGE, BINGO_SET_GRILLE, BINGO_SET_RAZ, BINGO_SET_TIRAGE } from "../actions/action-types";

const initialStateDatas = {
    alert: {
        active: false,
        type: null,
        title: null,
        msg: null
    },
    datas: null
}
const initialStateEnd = {
    alert: {
        active: false,
        type: null,
        title: null,
        msg: null
    },
    isEnd: false
}
const initialStatenum = {
    alert: {
        active: false,
        type: null,
        title: null,
        msg: null
    },
    numRes: 0
}

export function getBingoTirageReducer (state = initialStateDatas, action) {
    switch (action.type) {
        case BINGO_GET_TIRAGE:
            return {
                alert: action.payload.alert,
                datas: action.payload.datas,
            }
        default:
            return state;
    }
}

export function getBingoGrilleReducer (state = initialStateDatas, action) {
    switch (action.type) {
        case BINGO_GET_GRILLE:
            return {
                alert: action.payload.alert,
                datas: action.payload.datas,
            }
        default:
            return state;
    }
}

export function setBingoGrilleReducer( state = initialStateEnd, action) {
    switch (action.type) {
        case BINGO_SET_GRILLE:
            return {
                alert: action.payload.alert,
                isEnd: action.payload.isEnd,
            }
        default:
            return state;
    }
}

export function setBingoTirageReducer (state = initialStatenum, action) {
    switch (action.type) {
        case BINGO_SET_TIRAGE:
            return {
                alert: action.payload.alert,
                numRes: action.payload.numRes
            }
        default:
            return state;
    }
}

export function getBingoClassementReducer (state = initialStateDatas, action) {
    switch (action.type) {
        case BINGO_GET_JOUEURS:
            return {
                alert: action.payload.alert,
                datas: action.payload.datas,
            }
        default:
            return state;
    }
}

export function setBingoRazReducer (state = initialStateEnd, action) {
    switch (action.type) {
        case BINGO_SET_RAZ:
            return {
                alert: action.payload.alert,
                isEnd: action.payload.isEnd,
            }
        default:
            return state;
    }
}