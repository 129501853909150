import { GET_PROFIL, EDIT_PROFIL, GET_INFO_JEU, REMOVE_CREDITS, CONVERT_POINT, LAST_CO, VERIF_ROUE, ADD_ROUE, VERIF_CREDITS } from "../actions/action-types";

const initialStateAlert = {
    alert : {
        active: false,
        type: null,
        title: null,
        msg: null
    }
}

const initialStateNext = {
    alert: {
        active: false,
        type: null,
        title: null,
        msg: null
    },
    next: false
}

export function GetProfilReducer(state = {
    alert: {
        active: false,
        type: null,
        title: null,
        msg: null
    },
    profil: null
}, action) {
    switch (action.type) {
        case GET_PROFIL:
            return {
                alert: action.payload.alert,
                profil: action.payload.profil
            }
        default:
            return state;
    }
}

export function EditProfilReducer(state = initialStateNext, action) {
    switch (action.type) {
        case EDIT_PROFIL:
            return {
                alert: action.payload.alert,
                next: action.payload.next
            }
        default:
            return state;
    }
}

export function SetLastCoReducer(state = initialStateAlert, action) {
    switch (action.type) {
        case LAST_CO:
            return {
                alert: action.payload.alert
            }
        default:
            return state;
    }
}

export function GetDatasNbJeuxReducer(state = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
    infos: {
        besace: 0,
        credits: 0,
        points: 0,
        roue: 0
    }
}, action) {
    switch (action.type) {
        case GET_INFO_JEU:
            return {
                alert: action.payload.alert,
                infos: action.payload.infos
            }
        default:
            return state;
    }
}

export function SetRemoveCreditsReducer(state = initialStateNext, action) {
    switch (action.type) {
        case REMOVE_CREDITS:
            return {
                alert: action.payload.alert,
                next: action.payload.next
            }
        default:
            return state;
    }
}

export function SetConvertPointReducer(state = initialStateNext, action) {
    switch (action.type) {
        case CONVERT_POINT:
            return {
                alert: action.payload.alert,
                next: action.payload.next
            }
        default:
            return state;
    }
}

export function GetVerifRoueReducer(state = initialStateNext, action) {
    switch (action.type) {
        case VERIF_ROUE:
            return {
                alert: action.payload.alert,
                next: action.payload.next
            }
        default:
            return state;
    }
}

export function SetAddRoueReducer(state = initialStateNext, action) {
    switch (action.type) {
        case ADD_ROUE:
            return {
                alert: action.payload.alert,
                next: action.payload.next
            }
        default:
            return state;
    }
}

export function VerifCreditsReducer(state = initialStateNext, action) {
    switch (action.type) {
        case VERIF_CREDITS:
            return {
                alert: action.payload.alert,
                next: action.payload.next
            }
        default:
            return state;
    }
}