import React from "react";
import { getJeuClassement } from "../../actions/jeux";
import { connect } from "react-redux";

class Classement extends React.Component {
    state = {
        alert: {
            active: true,
            type: 'warning',
            title: 'Recherche des données...',
            msg: 'Recherche des données du classement en cours...'
        },
        filtre: "gains",
        time: 'semaine'
    }
    
    componentDidMount() {
        document.title = 'Tof La Win - Classement';
        this.props.getJeuClassement();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.jeuclas != undefined &&
            this.props.jeuclas != prevProps.jeuclas &&
            this.props.jeuclas.alert != this.state.alert
        ) {
            this.setState({alert: this.props.jeuclas.alert});
        }
        console.log(this.props)
    }

    render() {
        return <main className="page page--classement">
            <h2 className="page__ttl">Classement</h2>
            {/** todo ajouter alert */}
            <section className="classement">
                <header className="classement__header">
                    <div className="classement__filtre">
                        <button onClick={() => this.setState({filtre: "gains"})} className={`classement__btnfiltre ${this.state.filtre == "gains" && "classement__btnfiltre--active"}`}>Gains</button>
                        <button onClick={() => this.setState({filtre: "points"})} className={`classement__btnfiltre ${this.state.filtre == "points" && "classement__btnfiltre--active"}`}>Points</button>
                        <button onClick={() => this.setState({filtre: "nb_joue"})} className={`classement__btnfiltre ${this.state.filtre == "nb_joue" && "classement__btnfiltre--active"}`}>Joué</button>
                    </div>
                    <div className="classement__trier">
                        <select className="classement__seltri" value={this.state.time} onChange={(el) => this.setState({time: el.target.value})}>
                            <option value="semaine">Semaine</option>
                            <option value="mois">Mois</option>
                            <option value="all">Tout</option>
                        </select>
                    </div>
                </header>
                <ul className="classement__list">
                    {
                        this.props.jeuclas != undefined &&
                        this.props.jeuclas.datas != undefined &&
                        this.props.jeuclas.datas.length > 0 ?
                            this.props.jeuclas.datas.sort((a, b) => {
                                let values = a[this.state.time];
                                let values2 = b[this.state.time];
                                if (values != null && values2 != null) {
                                    return (values[this.state.filtre] - values2[this.state.filtre]) * -1;
                                } else {
                                    return 0; // à mododifier
                                }
                            }).map((item, index) => {
                                let values = item[this.state.time];
                                if (values != null) {
                                    return <li className={`classement__item ${index == 0 && "classement__item--big"}`}>
                                        <span className="classement__pseudo">{item.joueurs}</span>
                                        <span className="classement__pts">{values[this.state.filtre]}</span>
                                    </li>
                                } else {
                                    return ""
                                }
                            }) : <h2 className="jeux__empty">Aucun classement n'est disponible pour le moment</h2>
                    }
                </ul>
            </section>
        </main>
    }
}

const mapStateToProps = state => {
    return {
        jeuclas: state.jeuclas
    }
}

const mapDispatchToProps = {
    getJeuClassement
}

export default connect(mapStateToProps, mapDispatchToProps)(Classement);