import { GET_ANNONCES, GET_CALENDAR, GET_POKER, GET_STAT_TOF } from "../actions/action-types";

const initialStateDatas = {
    alert: {
        active: false,
        type: '',
        title: '',
        msg: ''
    },
    datas: []
};

export function GetPokerReducer(state = initialStateDatas, action) {
    switch (action.type) {
        case GET_POKER:
            return {
                alert: action.payload.alert,
                datas: action.payload.datas
            }
        default:
            return state;
    }
}

export function GetStatTofReducer(state = initialStateDatas, action) {
    switch (action.type) {
        case GET_STAT_TOF:
            return {
                alert: action.payload.alert,
                datas: action.payload.datas
            }
        default:
            return state;
    }
}

export function GetAnnoncesReducer(state = initialStateDatas, action) {
    switch (action.type) {
        case GET_ANNONCES:
            return {
                alert: action.payload.alert,
                datas: action.payload.datas
            }
        default:
            return state;
    }
}

export function GetCalendarReducer(state = initialStateDatas, action) {
    switch (action.type) {
        case GET_CALENDAR:
            return {
                alert: action.payload.alert,
                datas: action.payload.datas
            }
        default:
            return state;
    }
}