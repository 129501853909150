// Boules Super
import bouleSuper1 from "../images/jeux/lotowin/boules/super/1.png";
import bouleSuper2 from "../images/jeux/lotowin/boules/super/2.png";
import bouleSuper3 from "../images/jeux/lotowin/boules/super/3.png";
import bouleSuper4 from "../images/jeux/lotowin/boules/super/4.png";
import bouleSuper5 from "../images/jeux/lotowin/boules/super/5.png";
import bouleSuper6 from "../images/jeux/lotowin/boules/super/6.png";
import bouleSuper7 from "../images/jeux/lotowin/boules/super/7.png";
import bouleSuper8 from "../images/jeux/lotowin/boules/super/8.png";
import bouleSuper9 from "../images/jeux/lotowin/boules/super/9.png";
import bouleSuper10 from "../images/jeux/lotowin/boules/super/10.png";
import bouleSuper11 from "../images/jeux/lotowin/boules/super/11.png";
import bouleSuper12 from "../images/jeux/lotowin/boules/super/12.png";
import bouleSuper13 from "../images/jeux/lotowin/boules/super/13.png";
import bouleSuper14 from "../images/jeux/lotowin/boules/super/14.png";
import bouleSuper15 from "../images/jeux/lotowin/boules/super/15.png";
import bouleSuper16 from "../images/jeux/lotowin/boules/super/16.png";
import bouleSuper17 from "../images/jeux/lotowin/boules/super/17.png";
import bouleSuper18 from "../images/jeux/lotowin/boules/super/18.png";
import bouleSuper19 from "../images/jeux/lotowin/boules/super/19.png";
import bouleSuper20 from "../images/jeux/lotowin/boules/super/20.png";
import bouleSuper21 from "../images/jeux/lotowin/boules/super/21.png";
import bouleSuper22 from "../images/jeux/lotowin/boules/super/22.png";
import bouleSuper23 from "../images/jeux/lotowin/boules/super/23.png";
import bouleSuper24 from "../images/jeux/lotowin/boules/super/24.png";
import bouleSuper25 from "../images/jeux/lotowin/boules/super/25.png";
import bouleSuper26 from "../images/jeux/lotowin/boules/super/26.png";
import bouleSuper27 from "../images/jeux/lotowin/boules/super/27.png";
import bouleSuper28 from "../images/jeux/lotowin/boules/super/28.png";
import bouleSuper29 from "../images/jeux/lotowin/boules/super/29.png";
import bouleSuper30 from "../images/jeux/lotowin/boules/super/30.png";
import bouleSuper31 from "../images/jeux/lotowin/boules/super/31.png";
import bouleSuper32 from "../images/jeux/lotowin/boules/super/32.png";
import bouleSuper33 from "../images/jeux/lotowin/boules/super/33.png";
import bouleSuper34 from "../images/jeux/lotowin/boules/super/34.png";
import bouleSuper35 from "../images/jeux/lotowin/boules/super/35.png";
import bouleSuper36 from "../images/jeux/lotowin/boules/super/36.png";
import bouleSuper37 from "../images/jeux/lotowin/boules/super/37.png";
import bouleSuper38 from "../images/jeux/lotowin/boules/super/38.png";
import bouleSuper39 from "../images/jeux/lotowin/boules/super/39.png";
import bouleSuper40 from "../images/jeux/lotowin/boules/super/40.png";
import bouleSuper41 from "../images/jeux/lotowin/boules/super/41.png";
import bouleSuper42 from "../images/jeux/lotowin/boules/super/42.png";
import bouleSuper43 from "../images/jeux/lotowin/boules/super/43.png";
import bouleSuper44 from "../images/jeux/lotowin/boules/super/44.png";
import bouleSuper45 from "../images/jeux/lotowin/boules/super/45.png";
import bouleSuper46 from "../images/jeux/lotowin/boules/super/46.png";
import bouleSuper47 from "../images/jeux/lotowin/boules/super/47.png";
import bouleSuper48 from "../images/jeux/lotowin/boules/super/48.png";
import bouleSuper49 from "../images/jeux/lotowin/boules/super/49.png";
import bouleSuper50 from "../images/jeux/lotowin/boules/super/50.png";
import bouleSuper51 from "../images/jeux/lotowin/boules/super/51.png";
import bouleSuper52 from "../images/jeux/lotowin/boules/super/52.png";
import bouleSuper53 from "../images/jeux/lotowin/boules/super/53.png";
import bouleSuper54 from "../images/jeux/lotowin/boules/super/54.png";
import bouleSuper55 from "../images/jeux/lotowin/boules/super/55.png";
import bouleSuper56 from "../images/jeux/lotowin/boules/super/56.png";
import bouleSuper57 from "../images/jeux/lotowin/boules/super/57.png";
import bouleSuper58 from "../images/jeux/lotowin/boules/super/58.png";
import bouleSuper59 from "../images/jeux/lotowin/boules/super/59.png";
import bouleSuper60 from "../images/jeux/lotowin/boules/super/60.png";
import bouleSuper61 from "../images/jeux/lotowin/boules/super/61.png";
import bouleSuper62 from "../images/jeux/lotowin/boules/super/62.png";
import bouleSuper63 from "../images/jeux/lotowin/boules/super/63.png";
import bouleSuper64 from "../images/jeux/lotowin/boules/super/64.png";
import bouleSuper65 from "../images/jeux/lotowin/boules/super/65.png";
import bouleSuper66 from "../images/jeux/lotowin/boules/super/66.png";
import bouleSuper67 from "../images/jeux/lotowin/boules/super/67.png";
import bouleSuper68 from "../images/jeux/lotowin/boules/super/68.png";
import bouleSuper69 from "../images/jeux/lotowin/boules/super/69.png";
import bouleSuper70 from "../images/jeux/lotowin/boules/super/70.png";
import bouleSuper71 from "../images/jeux/lotowin/boules/super/71.png";
import bouleSuper72 from "../images/jeux/lotowin/boules/super/72.png";
import bouleSuper73 from "../images/jeux/lotowin/boules/super/73.png";
import bouleSuper74 from "../images/jeux/lotowin/boules/super/74.png";
import bouleSuper75 from "../images/jeux/lotowin/boules/super/75.png";
import bouleSuper76 from "../images/jeux/lotowin/boules/super/76.png";
import bouleSuper77 from "../images/jeux/lotowin/boules/super/77.png";
import bouleSuper78 from "../images/jeux/lotowin/boules/super/78.png";
import bouleSuper79 from "../images/jeux/lotowin/boules/super/79.png";
import bouleSuper80 from "../images/jeux/lotowin/boules/super/80.png";

// Boules Maxi
import bouleMaxi1 from "../images/jeux/lotowin/boules/maxi/1.png";
import bouleMaxi2 from "../images/jeux/lotowin/boules/maxi/2.png";
import bouleMaxi3 from "../images/jeux/lotowin/boules/maxi/3.png";
import bouleMaxi4 from "../images/jeux/lotowin/boules/maxi/4.png";
import bouleMaxi5 from "../images/jeux/lotowin/boules/maxi/5.png";
import bouleMaxi6 from "../images/jeux/lotowin/boules/maxi/6.png";
import bouleMaxi7 from "../images/jeux/lotowin/boules/maxi/7.png";
import bouleMaxi8 from "../images/jeux/lotowin/boules/maxi/8.png";
import bouleMaxi9 from "../images/jeux/lotowin/boules/maxi/9.png";
import bouleMaxi10 from "../images/jeux/lotowin/boules/maxi/10.png";
import bouleMaxi11 from "../images/jeux/lotowin/boules/maxi/11.png";
import bouleMaxi12 from "../images/jeux/lotowin/boules/maxi/12.png";
import bouleMaxi13 from "../images/jeux/lotowin/boules/maxi/13.png";
import bouleMaxi14 from "../images/jeux/lotowin/boules/maxi/14.png";
import bouleMaxi15 from "../images/jeux/lotowin/boules/maxi/15.png";
import bouleMaxi16 from "../images/jeux/lotowin/boules/maxi/16.png";
import bouleMaxi17 from "../images/jeux/lotowin/boules/maxi/17.png";
import bouleMaxi18 from "../images/jeux/lotowin/boules/maxi/18.png";
import bouleMaxi19 from "../images/jeux/lotowin/boules/maxi/19.png";
import bouleMaxi20 from "../images/jeux/lotowin/boules/maxi/20.png";
import bouleMaxi21 from "../images/jeux/lotowin/boules/maxi/21.png";
import bouleMaxi22 from "../images/jeux/lotowin/boules/maxi/22.png";
import bouleMaxi23 from "../images/jeux/lotowin/boules/maxi/23.png";
import bouleMaxi24 from "../images/jeux/lotowin/boules/maxi/24.png";
import bouleMaxi25 from "../images/jeux/lotowin/boules/maxi/25.png";
import bouleMaxi26 from "../images/jeux/lotowin/boules/maxi/26.png";
import bouleMaxi27 from "../images/jeux/lotowin/boules/maxi/27.png";
import bouleMaxi28 from "../images/jeux/lotowin/boules/maxi/28.png";
import bouleMaxi29 from "../images/jeux/lotowin/boules/maxi/29.png";
import bouleMaxi30 from "../images/jeux/lotowin/boules/maxi/30.png";
import bouleMaxi31 from "../images/jeux/lotowin/boules/maxi/31.png";
import bouleMaxi32 from "../images/jeux/lotowin/boules/maxi/32.png";
import bouleMaxi33 from "../images/jeux/lotowin/boules/maxi/33.png";
import bouleMaxi34 from "../images/jeux/lotowin/boules/maxi/34.png";
import bouleMaxi35 from "../images/jeux/lotowin/boules/maxi/35.png";
import bouleMaxi36 from "../images/jeux/lotowin/boules/maxi/36.png";
import bouleMaxi37 from "../images/jeux/lotowin/boules/maxi/37.png";
import bouleMaxi38 from "../images/jeux/lotowin/boules/maxi/38.png";
import bouleMaxi39 from "../images/jeux/lotowin/boules/maxi/39.png";
import bouleMaxi40 from "../images/jeux/lotowin/boules/maxi/40.png";
import bouleMaxi41 from "../images/jeux/lotowin/boules/maxi/41.png";
import bouleMaxi42 from "../images/jeux/lotowin/boules/maxi/42.png";
import bouleMaxi43 from "../images/jeux/lotowin/boules/maxi/43.png";
import bouleMaxi44 from "../images/jeux/lotowin/boules/maxi/44.png";
import bouleMaxi45 from "../images/jeux/lotowin/boules/maxi/45.png";
import bouleMaxi46 from "../images/jeux/lotowin/boules/maxi/46.png";
import bouleMaxi47 from "../images/jeux/lotowin/boules/maxi/47.png";
import bouleMaxi48 from "../images/jeux/lotowin/boules/maxi/48.png";
import bouleMaxi49 from "../images/jeux/lotowin/boules/maxi/49.png";
import bouleMaxi50 from "../images/jeux/lotowin/boules/maxi/50.png";
import bouleMaxi51 from "../images/jeux/lotowin/boules/maxi/51.png";
import bouleMaxi52 from "../images/jeux/lotowin/boules/maxi/52.png";
import bouleMaxi53 from "../images/jeux/lotowin/boules/maxi/53.png";
import bouleMaxi54 from "../images/jeux/lotowin/boules/maxi/54.png";
import bouleMaxi55 from "../images/jeux/lotowin/boules/maxi/55.png";
import bouleMaxi56 from "../images/jeux/lotowin/boules/maxi/56.png";
import bouleMaxi57 from "../images/jeux/lotowin/boules/maxi/57.png";
import bouleMaxi58 from "../images/jeux/lotowin/boules/maxi/58.png";
import bouleMaxi59 from "../images/jeux/lotowin/boules/maxi/59.png";
import bouleMaxi60 from "../images/jeux/lotowin/boules/maxi/60.png";
import bouleMaxi61 from "../images/jeux/lotowin/boules/maxi/61.png";
import bouleMaxi62 from "../images/jeux/lotowin/boules/maxi/62.png";
import bouleMaxi63 from "../images/jeux/lotowin/boules/maxi/63.png";
import bouleMaxi64 from "../images/jeux/lotowin/boules/maxi/64.png";
import bouleMaxi65 from "../images/jeux/lotowin/boules/maxi/65.png";
import bouleMaxi66 from "../images/jeux/lotowin/boules/maxi/66.png";
import bouleMaxi67 from "../images/jeux/lotowin/boules/maxi/67.png";
import bouleMaxi68 from "../images/jeux/lotowin/boules/maxi/68.png";
import bouleMaxi69 from "../images/jeux/lotowin/boules/maxi/69.png";
import bouleMaxi70 from "../images/jeux/lotowin/boules/maxi/70.png";
import bouleMaxi71 from "../images/jeux/lotowin/boules/maxi/71.png";
import bouleMaxi72 from "../images/jeux/lotowin/boules/maxi/72.png";
import bouleMaxi73 from "../images/jeux/lotowin/boules/maxi/73.png";
import bouleMaxi74 from "../images/jeux/lotowin/boules/maxi/74.png";
import bouleMaxi75 from "../images/jeux/lotowin/boules/maxi/75.png";
import bouleMaxi76 from "../images/jeux/lotowin/boules/maxi/76.png";
import bouleMaxi77 from "../images/jeux/lotowin/boules/maxi/77.png";
import bouleMaxi78 from "../images/jeux/lotowin/boules/maxi/78.png";
import bouleMaxi79 from "../images/jeux/lotowin/boules/maxi/79.png";
import bouleMaxi80 from "../images/jeux/lotowin/boules/maxi/80.png";

// Boules Mega
import bouleMega1 from "../images/jeux/lotowin/boules/mega/1.png";
import bouleMega2 from "../images/jeux/lotowin/boules/mega/2.png";
import bouleMega3 from "../images/jeux/lotowin/boules/mega/3.png";
import bouleMega4 from "../images/jeux/lotowin/boules/mega/4.png";
import bouleMega5 from "../images/jeux/lotowin/boules/mega/5.png";
import bouleMega6 from "../images/jeux/lotowin/boules/mega/6.png";
import bouleMega7 from "../images/jeux/lotowin/boules/mega/7.png";
import bouleMega8 from "../images/jeux/lotowin/boules/mega/8.png";
import bouleMega9 from "../images/jeux/lotowin/boules/mega/9.png";
import bouleMega10 from "../images/jeux/lotowin/boules/mega/10.png";
import bouleMega11 from "../images/jeux/lotowin/boules/mega/11.png";
import bouleMega12 from "../images/jeux/lotowin/boules/mega/12.png";
import bouleMega13 from "../images/jeux/lotowin/boules/mega/13.png";
import bouleMega14 from "../images/jeux/lotowin/boules/mega/14.png";
import bouleMega15 from "../images/jeux/lotowin/boules/mega/15.png";
import bouleMega16 from "../images/jeux/lotowin/boules/mega/16.png";
import bouleMega17 from "../images/jeux/lotowin/boules/mega/17.png";
import bouleMega18 from "../images/jeux/lotowin/boules/mega/18.png";
import bouleMega19 from "../images/jeux/lotowin/boules/mega/19.png";
import bouleMega20 from "../images/jeux/lotowin/boules/mega/20.png";
import bouleMega21 from "../images/jeux/lotowin/boules/mega/21.png";
import bouleMega22 from "../images/jeux/lotowin/boules/mega/22.png";
import bouleMega23 from "../images/jeux/lotowin/boules/mega/23.png";
import bouleMega24 from "../images/jeux/lotowin/boules/mega/24.png";
import bouleMega25 from "../images/jeux/lotowin/boules/mega/25.png";
import bouleMega26 from "../images/jeux/lotowin/boules/mega/26.png";
import bouleMega27 from "../images/jeux/lotowin/boules/mega/27.png";
import bouleMega28 from "../images/jeux/lotowin/boules/mega/28.png";
import bouleMega29 from "../images/jeux/lotowin/boules/mega/29.png";
import bouleMega30 from "../images/jeux/lotowin/boules/mega/30.png";
import bouleMega31 from "../images/jeux/lotowin/boules/mega/31.png";
import bouleMega32 from "../images/jeux/lotowin/boules/mega/32.png";
import bouleMega33 from "../images/jeux/lotowin/boules/mega/33.png";
import bouleMega34 from "../images/jeux/lotowin/boules/mega/34.png";
import bouleMega35 from "../images/jeux/lotowin/boules/mega/35.png";
import bouleMega36 from "../images/jeux/lotowin/boules/mega/36.png";
import bouleMega37 from "../images/jeux/lotowin/boules/mega/37.png";
import bouleMega38 from "../images/jeux/lotowin/boules/mega/38.png";
import bouleMega39 from "../images/jeux/lotowin/boules/mega/39.png";
import bouleMega40 from "../images/jeux/lotowin/boules/mega/40.png";
import bouleMega41 from "../images/jeux/lotowin/boules/mega/41.png";
import bouleMega42 from "../images/jeux/lotowin/boules/mega/42.png";
import bouleMega43 from "../images/jeux/lotowin/boules/mega/43.png";
import bouleMega44 from "../images/jeux/lotowin/boules/mega/44.png";
import bouleMega45 from "../images/jeux/lotowin/boules/mega/45.png";
import bouleMega46 from "../images/jeux/lotowin/boules/mega/46.png";
import bouleMega47 from "../images/jeux/lotowin/boules/mega/47.png";
import bouleMega48 from "../images/jeux/lotowin/boules/mega/48.png";
import bouleMega49 from "../images/jeux/lotowin/boules/mega/49.png";
import bouleMega50 from "../images/jeux/lotowin/boules/mega/50.png";
import bouleMega51 from "../images/jeux/lotowin/boules/mega/51.png";
import bouleMega52 from "../images/jeux/lotowin/boules/mega/52.png";
import bouleMega53 from "../images/jeux/lotowin/boules/mega/53.png";
import bouleMega54 from "../images/jeux/lotowin/boules/mega/54.png";
import bouleMega55 from "../images/jeux/lotowin/boules/mega/55.png";
import bouleMega56 from "../images/jeux/lotowin/boules/mega/56.png";
import bouleMega57 from "../images/jeux/lotowin/boules/mega/57.png";
import bouleMega58 from "../images/jeux/lotowin/boules/mega/58.png";
import bouleMega59 from "../images/jeux/lotowin/boules/mega/59.png";
import bouleMega60 from "../images/jeux/lotowin/boules/mega/60.png";
import bouleMega61 from "../images/jeux/lotowin/boules/mega/61.png";
import bouleMega62 from "../images/jeux/lotowin/boules/mega/62.png";
import bouleMega63 from "../images/jeux/lotowin/boules/mega/63.png";
import bouleMega64 from "../images/jeux/lotowin/boules/mega/64.png";
import bouleMega65 from "../images/jeux/lotowin/boules/mega/65.png";
import bouleMega66 from "../images/jeux/lotowin/boules/mega/66.png";
import bouleMega67 from "../images/jeux/lotowin/boules/mega/67.png";
import bouleMega68 from "../images/jeux/lotowin/boules/mega/68.png";
import bouleMega69 from "../images/jeux/lotowin/boules/mega/69.png";
import bouleMega70 from "../images/jeux/lotowin/boules/mega/70.png";
import bouleMega71 from "../images/jeux/lotowin/boules/mega/71.png";
import bouleMega72 from "../images/jeux/lotowin/boules/mega/72.png";
import bouleMega73 from "../images/jeux/lotowin/boules/mega/73.png";
import bouleMega74 from "../images/jeux/lotowin/boules/mega/74.png";
import bouleMega75 from "../images/jeux/lotowin/boules/mega/75.png";
import bouleMega76 from "../images/jeux/lotowin/boules/mega/76.png";
import bouleMega77 from "../images/jeux/lotowin/boules/mega/77.png";
import bouleMega78 from "../images/jeux/lotowin/boules/mega/78.png";
import bouleMega79 from "../images/jeux/lotowin/boules/mega/79.png";
import bouleMega80 from "../images/jeux/lotowin/boules/mega/80.png";

// Boules Extra
import bouleExtra1 from "../images/jeux/lotowin/boules/extra/1.png";
import bouleExtra2 from "../images/jeux/lotowin/boules/extra/2.png";
import bouleExtra3 from "../images/jeux/lotowin/boules/extra/3.png";
import bouleExtra4 from "../images/jeux/lotowin/boules/extra/4.png";
import bouleExtra5 from "../images/jeux/lotowin/boules/extra/5.png";
import bouleExtra6 from "../images/jeux/lotowin/boules/extra/6.png";
import bouleExtra7 from "../images/jeux/lotowin/boules/extra/7.png";
import bouleExtra8 from "../images/jeux/lotowin/boules/extra/8.png";
import bouleExtra9 from "../images/jeux/lotowin/boules/extra/9.png";
import bouleExtra10 from "../images/jeux/lotowin/boules/extra/10.png";
import bouleExtra11 from "../images/jeux/lotowin/boules/extra/11.png";
import bouleExtra12 from "../images/jeux/lotowin/boules/extra/12.png";
import bouleExtra13 from "../images/jeux/lotowin/boules/extra/13.png";
import bouleExtra14 from "../images/jeux/lotowin/boules/extra/14.png";
import bouleExtra15 from "../images/jeux/lotowin/boules/extra/15.png";
import bouleExtra16 from "../images/jeux/lotowin/boules/extra/16.png";
import bouleExtra17 from "../images/jeux/lotowin/boules/extra/17.png";
import bouleExtra18 from "../images/jeux/lotowin/boules/extra/18.png";
import bouleExtra19 from "../images/jeux/lotowin/boules/extra/19.png";
import bouleExtra20 from "../images/jeux/lotowin/boules/extra/20.png";
import bouleExtra21 from "../images/jeux/lotowin/boules/extra/21.png";
import bouleExtra22 from "../images/jeux/lotowin/boules/extra/22.png";
import bouleExtra23 from "../images/jeux/lotowin/boules/extra/23.png";
import bouleExtra24 from "../images/jeux/lotowin/boules/extra/24.png";
import bouleExtra25 from "../images/jeux/lotowin/boules/extra/25.png";
import bouleExtra26 from "../images/jeux/lotowin/boules/extra/26.png";
import bouleExtra27 from "../images/jeux/lotowin/boules/extra/27.png";
import bouleExtra28 from "../images/jeux/lotowin/boules/extra/28.png";
import bouleExtra29 from "../images/jeux/lotowin/boules/extra/29.png";
import bouleExtra30 from "../images/jeux/lotowin/boules/extra/30.png";
import bouleExtra31 from "../images/jeux/lotowin/boules/extra/31.png";
import bouleExtra32 from "../images/jeux/lotowin/boules/extra/32.png";
import bouleExtra33 from "../images/jeux/lotowin/boules/extra/33.png";
import bouleExtra34 from "../images/jeux/lotowin/boules/extra/34.png";
import bouleExtra35 from "../images/jeux/lotowin/boules/extra/35.png";
import bouleExtra36 from "../images/jeux/lotowin/boules/extra/36.png";
import bouleExtra37 from "../images/jeux/lotowin/boules/extra/37.png";
import bouleExtra38 from "../images/jeux/lotowin/boules/extra/38.png";
import bouleExtra39 from "../images/jeux/lotowin/boules/extra/39.png";
import bouleExtra40 from "../images/jeux/lotowin/boules/extra/40.png";
import bouleExtra41 from "../images/jeux/lotowin/boules/extra/41.png";
import bouleExtra42 from "../images/jeux/lotowin/boules/extra/42.png";
import bouleExtra43 from "../images/jeux/lotowin/boules/extra/43.png";
import bouleExtra44 from "../images/jeux/lotowin/boules/extra/44.png";
import bouleExtra45 from "../images/jeux/lotowin/boules/extra/45.png";
import bouleExtra46 from "../images/jeux/lotowin/boules/extra/46.png";
import bouleExtra47 from "../images/jeux/lotowin/boules/extra/47.png";
import bouleExtra48 from "../images/jeux/lotowin/boules/extra/48.png";
import bouleExtra49 from "../images/jeux/lotowin/boules/extra/49.png";
import bouleExtra50 from "../images/jeux/lotowin/boules/extra/50.png";
import bouleExtra51 from "../images/jeux/lotowin/boules/extra/51.png";
import bouleExtra52 from "../images/jeux/lotowin/boules/extra/52.png";
import bouleExtra53 from "../images/jeux/lotowin/boules/extra/53.png";
import bouleExtra54 from "../images/jeux/lotowin/boules/extra/54.png";
import bouleExtra55 from "../images/jeux/lotowin/boules/extra/55.png";
import bouleExtra56 from "../images/jeux/lotowin/boules/extra/56.png";
import bouleExtra57 from "../images/jeux/lotowin/boules/extra/57.png";
import bouleExtra58 from "../images/jeux/lotowin/boules/extra/58.png";
import bouleExtra59 from "../images/jeux/lotowin/boules/extra/59.png";
import bouleExtra60 from "../images/jeux/lotowin/boules/extra/60.png";
import bouleExtra61 from "../images/jeux/lotowin/boules/extra/61.png";
import bouleExtra62 from "../images/jeux/lotowin/boules/extra/62.png";
import bouleExtra63 from "../images/jeux/lotowin/boules/extra/63.png";
import bouleExtra64 from "../images/jeux/lotowin/boules/extra/64.png";
import bouleExtra65 from "../images/jeux/lotowin/boules/extra/65.png";
import bouleExtra66 from "../images/jeux/lotowin/boules/extra/66.png";
import bouleExtra67 from "../images/jeux/lotowin/boules/extra/67.png";
import bouleExtra68 from "../images/jeux/lotowin/boules/extra/68.png";
import bouleExtra69 from "../images/jeux/lotowin/boules/extra/69.png";
import bouleExtra70 from "../images/jeux/lotowin/boules/extra/70.png";
import bouleExtra71 from "../images/jeux/lotowin/boules/extra/71.png";
import bouleExtra72 from "../images/jeux/lotowin/boules/extra/72.png";
import bouleExtra73 from "../images/jeux/lotowin/boules/extra/73.png";
import bouleExtra74 from "../images/jeux/lotowin/boules/extra/74.png";
import bouleExtra75 from "../images/jeux/lotowin/boules/extra/75.png";
import bouleExtra76 from "../images/jeux/lotowin/boules/extra/76.png";
import bouleExtra77 from "../images/jeux/lotowin/boules/extra/77.png";
import bouleExtra78 from "../images/jeux/lotowin/boules/extra/78.png";
import bouleExtra79 from "../images/jeux/lotowin/boules/extra/79.png";
import bouleExtra80 from "../images/jeux/lotowin/boules/extra/80.png";

// Boules Dans tirage Super
import bouleBackSuper1 from "../images/jeux/lotowin/boules_back/super/1.png";
import bouleBackSuper2 from "../images/jeux/lotowin/boules_back/super/2.png";
import bouleBackSuper3 from "../images/jeux/lotowin/boules_back/super/3.png";
import bouleBackSuper4 from "../images/jeux/lotowin/boules_back/super/4.png";
import bouleBackSuper5 from "../images/jeux/lotowin/boules_back/super/5.png";
import bouleBackSuper6 from "../images/jeux/lotowin/boules_back/super/6.png";
import bouleBackSuper7 from "../images/jeux/lotowin/boules_back/super/7.png";
import bouleBackSuper8 from "../images/jeux/lotowin/boules_back/super/8.png";
import bouleBackSuper9 from "../images/jeux/lotowin/boules_back/super/9.png";
import bouleBackSuper10 from "../images/jeux/lotowin/boules_back/super/10.png";
import bouleBackSuper11 from "../images/jeux/lotowin/boules_back/super/11.png";
import bouleBackSuper12 from "../images/jeux/lotowin/boules_back/super/12.png";
import bouleBackSuper13 from "../images/jeux/lotowin/boules_back/super/13.png";
import bouleBackSuper14 from "../images/jeux/lotowin/boules_back/super/14.png";
import bouleBackSuper15 from "../images/jeux/lotowin/boules_back/super/15.png";
import bouleBackSuper16 from "../images/jeux/lotowin/boules_back/super/16.png";
import bouleBackSuper17 from "../images/jeux/lotowin/boules_back/super/17.png";
import bouleBackSuper18 from "../images/jeux/lotowin/boules_back/super/18.png";
import bouleBackSuper19 from "../images/jeux/lotowin/boules_back/super/19.png";
import bouleBackSuper20 from "../images/jeux/lotowin/boules_back/super/20.png";
import bouleBackSuper21 from "../images/jeux/lotowin/boules_back/super/21.png";
import bouleBackSuper22 from "../images/jeux/lotowin/boules_back/super/22.png";
import bouleBackSuper23 from "../images/jeux/lotowin/boules_back/super/23.png";
import bouleBackSuper24 from "../images/jeux/lotowin/boules_back/super/24.png";
import bouleBackSuper25 from "../images/jeux/lotowin/boules_back/super/25.png";
import bouleBackSuper26 from "../images/jeux/lotowin/boules_back/super/26.png";
import bouleBackSuper27 from "../images/jeux/lotowin/boules_back/super/27.png";
import bouleBackSuper28 from "../images/jeux/lotowin/boules_back/super/28.png";
import bouleBackSuper29 from "../images/jeux/lotowin/boules_back/super/29.png";
import bouleBackSuper30 from "../images/jeux/lotowin/boules_back/super/30.png";
import bouleBackSuper31 from "../images/jeux/lotowin/boules_back/super/31.png";
import bouleBackSuper32 from "../images/jeux/lotowin/boules_back/super/32.png";
import bouleBackSuper33 from "../images/jeux/lotowin/boules_back/super/33.png";
import bouleBackSuper34 from "../images/jeux/lotowin/boules_back/super/34.png";
import bouleBackSuper35 from "../images/jeux/lotowin/boules_back/super/35.png";
import bouleBackSuper36 from "../images/jeux/lotowin/boules_back/super/36.png";
import bouleBackSuper37 from "../images/jeux/lotowin/boules_back/super/37.png";
import bouleBackSuper38 from "../images/jeux/lotowin/boules_back/super/38.png";
import bouleBackSuper39 from "../images/jeux/lotowin/boules_back/super/39.png";
import bouleBackSuper40 from "../images/jeux/lotowin/boules_back/super/40.png";
import bouleBackSuper41 from "../images/jeux/lotowin/boules_back/super/41.png";
import bouleBackSuper42 from "../images/jeux/lotowin/boules_back/super/42.png";
import bouleBackSuper43 from "../images/jeux/lotowin/boules_back/super/43.png";
import bouleBackSuper44 from "../images/jeux/lotowin/boules_back/super/44.png";
import bouleBackSuper45 from "../images/jeux/lotowin/boules_back/super/45.png";
import bouleBackSuper46 from "../images/jeux/lotowin/boules_back/super/46.png";
import bouleBackSuper47 from "../images/jeux/lotowin/boules_back/super/47.png";
import bouleBackSuper48 from "../images/jeux/lotowin/boules_back/super/48.png";
import bouleBackSuper49 from "../images/jeux/lotowin/boules_back/super/49.png";
import bouleBackSuper50 from "../images/jeux/lotowin/boules_back/super/50.png";
import bouleBackSuper51 from "../images/jeux/lotowin/boules_back/super/51.png";
import bouleBackSuper52 from "../images/jeux/lotowin/boules_back/super/52.png";
import bouleBackSuper53 from "../images/jeux/lotowin/boules_back/super/53.png";
import bouleBackSuper54 from "../images/jeux/lotowin/boules_back/super/54.png";
import bouleBackSuper55 from "../images/jeux/lotowin/boules_back/super/55.png";
import bouleBackSuper56 from "../images/jeux/lotowin/boules_back/super/56.png";
import bouleBackSuper57 from "../images/jeux/lotowin/boules_back/super/57.png";
import bouleBackSuper58 from "../images/jeux/lotowin/boules_back/super/58.png";
import bouleBackSuper59 from "../images/jeux/lotowin/boules_back/super/59.png";
import bouleBackSuper60 from "../images/jeux/lotowin/boules_back/super/60.png";
import bouleBackSuper61 from "../images/jeux/lotowin/boules_back/super/61.png";
import bouleBackSuper62 from "../images/jeux/lotowin/boules_back/super/62.png";
import bouleBackSuper63 from "../images/jeux/lotowin/boules_back/super/63.png";
import bouleBackSuper64 from "../images/jeux/lotowin/boules_back/super/64.png";
import bouleBackSuper65 from "../images/jeux/lotowin/boules_back/super/65.png";
import bouleBackSuper66 from "../images/jeux/lotowin/boules_back/super/66.png";
import bouleBackSuper67 from "../images/jeux/lotowin/boules_back/super/67.png";
import bouleBackSuper68 from "../images/jeux/lotowin/boules_back/super/68.png";
import bouleBackSuper69 from "../images/jeux/lotowin/boules_back/super/69.png";
import bouleBackSuper70 from "../images/jeux/lotowin/boules_back/super/70.png";
import bouleBackSuper71 from "../images/jeux/lotowin/boules_back/super/71.png";
import bouleBackSuper72 from "../images/jeux/lotowin/boules_back/super/72.png";
import bouleBackSuper73 from "../images/jeux/lotowin/boules_back/super/73.png";
import bouleBackSuper74 from "../images/jeux/lotowin/boules_back/super/74.png";
import bouleBackSuper75 from "../images/jeux/lotowin/boules_back/super/75.png";
import bouleBackSuper76 from "../images/jeux/lotowin/boules_back/super/76.png";
import bouleBackSuper77 from "../images/jeux/lotowin/boules_back/super/77.png";
import bouleBackSuper78 from "../images/jeux/lotowin/boules_back/super/78.png";
import bouleBackSuper79 from "../images/jeux/lotowin/boules_back/super/79.png";
import bouleBackSuper80 from "../images/jeux/lotowin/boules_back/super/80.png";

// Boules Dans tirage Maxi
import bouleBackMaxi1 from "../images/jeux/lotowin/boules_back/maxi/1.png";
import bouleBackMaxi2 from "../images/jeux/lotowin/boules_back/maxi/2.png";
import bouleBackMaxi3 from "../images/jeux/lotowin/boules_back/maxi/3.png";
import bouleBackMaxi4 from "../images/jeux/lotowin/boules_back/maxi/4.png";
import bouleBackMaxi5 from "../images/jeux/lotowin/boules_back/maxi/5.png";
import bouleBackMaxi6 from "../images/jeux/lotowin/boules_back/maxi/6.png";
import bouleBackMaxi7 from "../images/jeux/lotowin/boules_back/maxi/7.png";
import bouleBackMaxi8 from "../images/jeux/lotowin/boules_back/maxi/8.png";
import bouleBackMaxi9 from "../images/jeux/lotowin/boules_back/maxi/9.png";
import bouleBackMaxi10 from "../images/jeux/lotowin/boules_back/maxi/10.png";
import bouleBackMaxi11 from "../images/jeux/lotowin/boules_back/maxi/11.png";
import bouleBackMaxi12 from "../images/jeux/lotowin/boules_back/maxi/12.png";
import bouleBackMaxi13 from "../images/jeux/lotowin/boules_back/maxi/13.png";
import bouleBackMaxi14 from "../images/jeux/lotowin/boules_back/maxi/14.png";
import bouleBackMaxi15 from "../images/jeux/lotowin/boules_back/maxi/15.png";
import bouleBackMaxi16 from "../images/jeux/lotowin/boules_back/maxi/16.png";
import bouleBackMaxi17 from "../images/jeux/lotowin/boules_back/maxi/17.png";
import bouleBackMaxi18 from "../images/jeux/lotowin/boules_back/maxi/18.png";
import bouleBackMaxi19 from "../images/jeux/lotowin/boules_back/maxi/19.png";
import bouleBackMaxi20 from "../images/jeux/lotowin/boules_back/maxi/20.png";
import bouleBackMaxi21 from "../images/jeux/lotowin/boules_back/maxi/21.png";
import bouleBackMaxi22 from "../images/jeux/lotowin/boules_back/maxi/22.png";
import bouleBackMaxi23 from "../images/jeux/lotowin/boules_back/maxi/23.png";
import bouleBackMaxi24 from "../images/jeux/lotowin/boules_back/maxi/24.png";
import bouleBackMaxi25 from "../images/jeux/lotowin/boules_back/maxi/25.png";
import bouleBackMaxi26 from "../images/jeux/lotowin/boules_back/maxi/26.png";
import bouleBackMaxi27 from "../images/jeux/lotowin/boules_back/maxi/27.png";
import bouleBackMaxi28 from "../images/jeux/lotowin/boules_back/maxi/28.png";
import bouleBackMaxi29 from "../images/jeux/lotowin/boules_back/maxi/29.png";
import bouleBackMaxi30 from "../images/jeux/lotowin/boules_back/maxi/30.png";
import bouleBackMaxi31 from "../images/jeux/lotowin/boules_back/maxi/31.png";
import bouleBackMaxi32 from "../images/jeux/lotowin/boules_back/maxi/32.png";
import bouleBackMaxi33 from "../images/jeux/lotowin/boules_back/maxi/33.png";
import bouleBackMaxi34 from "../images/jeux/lotowin/boules_back/maxi/34.png";
import bouleBackMaxi35 from "../images/jeux/lotowin/boules_back/maxi/35.png";
import bouleBackMaxi36 from "../images/jeux/lotowin/boules_back/maxi/36.png";
import bouleBackMaxi37 from "../images/jeux/lotowin/boules_back/maxi/37.png";
import bouleBackMaxi38 from "../images/jeux/lotowin/boules_back/maxi/38.png";
import bouleBackMaxi39 from "../images/jeux/lotowin/boules_back/maxi/39.png";
import bouleBackMaxi40 from "../images/jeux/lotowin/boules_back/maxi/40.png";
import bouleBackMaxi41 from "../images/jeux/lotowin/boules_back/maxi/41.png";
import bouleBackMaxi42 from "../images/jeux/lotowin/boules_back/maxi/42.png";
import bouleBackMaxi43 from "../images/jeux/lotowin/boules_back/maxi/43.png";
import bouleBackMaxi44 from "../images/jeux/lotowin/boules_back/maxi/44.png";
import bouleBackMaxi45 from "../images/jeux/lotowin/boules_back/maxi/45.png";
import bouleBackMaxi46 from "../images/jeux/lotowin/boules_back/maxi/46.png";
import bouleBackMaxi47 from "../images/jeux/lotowin/boules_back/maxi/47.png";
import bouleBackMaxi48 from "../images/jeux/lotowin/boules_back/maxi/48.png";
import bouleBackMaxi49 from "../images/jeux/lotowin/boules_back/maxi/49.png";
import bouleBackMaxi50 from "../images/jeux/lotowin/boules_back/maxi/50.png";
import bouleBackMaxi51 from "../images/jeux/lotowin/boules_back/maxi/51.png";
import bouleBackMaxi52 from "../images/jeux/lotowin/boules_back/maxi/52.png";
import bouleBackMaxi53 from "../images/jeux/lotowin/boules_back/maxi/53.png";
import bouleBackMaxi54 from "../images/jeux/lotowin/boules_back/maxi/54.png";
import bouleBackMaxi55 from "../images/jeux/lotowin/boules_back/maxi/55.png";
import bouleBackMaxi56 from "../images/jeux/lotowin/boules_back/maxi/56.png";
import bouleBackMaxi57 from "../images/jeux/lotowin/boules_back/maxi/57.png";
import bouleBackMaxi58 from "../images/jeux/lotowin/boules_back/maxi/58.png";
import bouleBackMaxi59 from "../images/jeux/lotowin/boules_back/maxi/59.png";
import bouleBackMaxi60 from "../images/jeux/lotowin/boules_back/maxi/60.png";
import bouleBackMaxi61 from "../images/jeux/lotowin/boules_back/maxi/61.png";
import bouleBackMaxi62 from "../images/jeux/lotowin/boules_back/maxi/62.png";
import bouleBackMaxi63 from "../images/jeux/lotowin/boules_back/maxi/63.png";
import bouleBackMaxi64 from "../images/jeux/lotowin/boules_back/maxi/64.png";
import bouleBackMaxi65 from "../images/jeux/lotowin/boules_back/maxi/65.png";
import bouleBackMaxi66 from "../images/jeux/lotowin/boules_back/maxi/66.png";
import bouleBackMaxi67 from "../images/jeux/lotowin/boules_back/maxi/67.png";
import bouleBackMaxi68 from "../images/jeux/lotowin/boules_back/maxi/68.png";
import bouleBackMaxi69 from "../images/jeux/lotowin/boules_back/maxi/69.png";
import bouleBackMaxi70 from "../images/jeux/lotowin/boules_back/maxi/70.png";
import bouleBackMaxi71 from "../images/jeux/lotowin/boules_back/maxi/71.png";
import bouleBackMaxi72 from "../images/jeux/lotowin/boules_back/maxi/72.png";
import bouleBackMaxi73 from "../images/jeux/lotowin/boules_back/maxi/73.png";
import bouleBackMaxi74 from "../images/jeux/lotowin/boules_back/maxi/74.png";
import bouleBackMaxi75 from "../images/jeux/lotowin/boules_back/maxi/75.png";
import bouleBackMaxi76 from "../images/jeux/lotowin/boules_back/maxi/76.png";
import bouleBackMaxi77 from "../images/jeux/lotowin/boules_back/maxi/77.png";
import bouleBackMaxi78 from "../images/jeux/lotowin/boules_back/maxi/78.png";
import bouleBackMaxi79 from "../images/jeux/lotowin/boules_back/maxi/79.png";
import bouleBackMaxi80 from "../images/jeux/lotowin/boules_back/maxi/80.png";

// Boules Dans tirage Mega
import bouleBackMega1 from "../images/jeux/lotowin/boules_back/mega/1.png";
import bouleBackMega2 from "../images/jeux/lotowin/boules_back/mega/2.png";
import bouleBackMega3 from "../images/jeux/lotowin/boules_back/mega/3.png";
import bouleBackMega4 from "../images/jeux/lotowin/boules_back/mega/4.png";
import bouleBackMega5 from "../images/jeux/lotowin/boules_back/mega/5.png";
import bouleBackMega6 from "../images/jeux/lotowin/boules_back/mega/6.png";
import bouleBackMega7 from "../images/jeux/lotowin/boules_back/mega/7.png";
import bouleBackMega8 from "../images/jeux/lotowin/boules_back/mega/8.png";
import bouleBackMega9 from "../images/jeux/lotowin/boules_back/mega/9.png";
import bouleBackMega10 from "../images/jeux/lotowin/boules_back/mega/10.png";
import bouleBackMega11 from "../images/jeux/lotowin/boules_back/mega/11.png";
import bouleBackMega12 from "../images/jeux/lotowin/boules_back/mega/12.png";
import bouleBackMega13 from "../images/jeux/lotowin/boules_back/mega/13.png";
import bouleBackMega14 from "../images/jeux/lotowin/boules_back/mega/14.png";
import bouleBackMega15 from "../images/jeux/lotowin/boules_back/mega/15.png";
import bouleBackMega16 from "../images/jeux/lotowin/boules_back/mega/16.png";
import bouleBackMega17 from "../images/jeux/lotowin/boules_back/mega/17.png";
import bouleBackMega18 from "../images/jeux/lotowin/boules_back/mega/18.png";
import bouleBackMega19 from "../images/jeux/lotowin/boules_back/mega/19.png";
import bouleBackMega20 from "../images/jeux/lotowin/boules_back/mega/20.png";
import bouleBackMega21 from "../images/jeux/lotowin/boules_back/mega/21.png";
import bouleBackMega22 from "../images/jeux/lotowin/boules_back/mega/22.png";
import bouleBackMega23 from "../images/jeux/lotowin/boules_back/mega/23.png";
import bouleBackMega24 from "../images/jeux/lotowin/boules_back/mega/24.png";
import bouleBackMega25 from "../images/jeux/lotowin/boules_back/mega/25.png";
import bouleBackMega26 from "../images/jeux/lotowin/boules_back/mega/26.png";
import bouleBackMega27 from "../images/jeux/lotowin/boules_back/mega/27.png";
import bouleBackMega28 from "../images/jeux/lotowin/boules_back/mega/28.png";
import bouleBackMega29 from "../images/jeux/lotowin/boules_back/mega/29.png";
import bouleBackMega30 from "../images/jeux/lotowin/boules_back/mega/30.png";
import bouleBackMega31 from "../images/jeux/lotowin/boules_back/mega/31.png";
import bouleBackMega32 from "../images/jeux/lotowin/boules_back/mega/32.png";
import bouleBackMega33 from "../images/jeux/lotowin/boules_back/mega/33.png";
import bouleBackMega34 from "../images/jeux/lotowin/boules_back/mega/34.png";
import bouleBackMega35 from "../images/jeux/lotowin/boules_back/mega/35.png";
import bouleBackMega36 from "../images/jeux/lotowin/boules_back/mega/36.png";
import bouleBackMega37 from "../images/jeux/lotowin/boules_back/mega/37.png";
import bouleBackMega38 from "../images/jeux/lotowin/boules_back/mega/38.png";
import bouleBackMega39 from "../images/jeux/lotowin/boules_back/mega/39.png";
import bouleBackMega40 from "../images/jeux/lotowin/boules_back/mega/40.png";
import bouleBackMega41 from "../images/jeux/lotowin/boules_back/mega/41.png";
import bouleBackMega42 from "../images/jeux/lotowin/boules_back/mega/42.png";
import bouleBackMega43 from "../images/jeux/lotowin/boules_back/mega/43.png";
import bouleBackMega44 from "../images/jeux/lotowin/boules_back/mega/44.png";
import bouleBackMega45 from "../images/jeux/lotowin/boules_back/mega/45.png";
import bouleBackMega46 from "../images/jeux/lotowin/boules_back/mega/46.png";
import bouleBackMega47 from "../images/jeux/lotowin/boules_back/mega/47.png";
import bouleBackMega48 from "../images/jeux/lotowin/boules_back/mega/48.png";
import bouleBackMega49 from "../images/jeux/lotowin/boules_back/mega/49.png";
import bouleBackMega50 from "../images/jeux/lotowin/boules_back/mega/50.png";
import bouleBackMega51 from "../images/jeux/lotowin/boules_back/mega/51.png";
import bouleBackMega52 from "../images/jeux/lotowin/boules_back/mega/52.png";
import bouleBackMega53 from "../images/jeux/lotowin/boules_back/mega/53.png";
import bouleBackMega54 from "../images/jeux/lotowin/boules_back/mega/54.png";
import bouleBackMega55 from "../images/jeux/lotowin/boules_back/mega/55.png";
import bouleBackMega56 from "../images/jeux/lotowin/boules_back/mega/56.png";
import bouleBackMega57 from "../images/jeux/lotowin/boules_back/mega/57.png";
import bouleBackMega58 from "../images/jeux/lotowin/boules_back/mega/58.png";
import bouleBackMega59 from "../images/jeux/lotowin/boules_back/mega/59.png";
import bouleBackMega60 from "../images/jeux/lotowin/boules_back/mega/60.png";
import bouleBackMega61 from "../images/jeux/lotowin/boules_back/mega/61.png";
import bouleBackMega62 from "../images/jeux/lotowin/boules_back/mega/62.png";
import bouleBackMega63 from "../images/jeux/lotowin/boules_back/mega/63.png";
import bouleBackMega64 from "../images/jeux/lotowin/boules_back/mega/64.png";
import bouleBackMega65 from "../images/jeux/lotowin/boules_back/mega/65.png";
import bouleBackMega66 from "../images/jeux/lotowin/boules_back/mega/66.png";
import bouleBackMega67 from "../images/jeux/lotowin/boules_back/mega/67.png";
import bouleBackMega68 from "../images/jeux/lotowin/boules_back/mega/68.png";
import bouleBackMega69 from "../images/jeux/lotowin/boules_back/mega/69.png";
import bouleBackMega70 from "../images/jeux/lotowin/boules_back/mega/70.png";
import bouleBackMega71 from "../images/jeux/lotowin/boules_back/mega/71.png";
import bouleBackMega72 from "../images/jeux/lotowin/boules_back/mega/72.png";
import bouleBackMega73 from "../images/jeux/lotowin/boules_back/mega/73.png";
import bouleBackMega74 from "../images/jeux/lotowin/boules_back/mega/74.png";
import bouleBackMega75 from "../images/jeux/lotowin/boules_back/mega/75.png";
import bouleBackMega76 from "../images/jeux/lotowin/boules_back/mega/76.png";
import bouleBackMega77 from "../images/jeux/lotowin/boules_back/mega/77.png";
import bouleBackMega78 from "../images/jeux/lotowin/boules_back/mega/78.png";
import bouleBackMega79 from "../images/jeux/lotowin/boules_back/mega/79.png";
import bouleBackMega80 from "../images/jeux/lotowin/boules_back/mega/80.png";

// Boules Dans tirage Extra
import bouleBackExtra1 from "../images/jeux/lotowin/boules_back/extra/1.png";
import bouleBackExtra2 from "../images/jeux/lotowin/boules_back/extra/2.png";
import bouleBackExtra3 from "../images/jeux/lotowin/boules_back/extra/3.png";
import bouleBackExtra4 from "../images/jeux/lotowin/boules_back/extra/4.png";
import bouleBackExtra5 from "../images/jeux/lotowin/boules_back/extra/5.png";
import bouleBackExtra6 from "../images/jeux/lotowin/boules_back/extra/6.png";
import bouleBackExtra7 from "../images/jeux/lotowin/boules_back/extra/7.png";
import bouleBackExtra8 from "../images/jeux/lotowin/boules_back/extra/8.png";
import bouleBackExtra9 from "../images/jeux/lotowin/boules_back/extra/9.png";
import bouleBackExtra10 from "../images/jeux/lotowin/boules_back/extra/10.png";
import bouleBackExtra11 from "../images/jeux/lotowin/boules_back/extra/11.png";
import bouleBackExtra12 from "../images/jeux/lotowin/boules_back/extra/12.png";
import bouleBackExtra13 from "../images/jeux/lotowin/boules_back/extra/13.png";
import bouleBackExtra14 from "../images/jeux/lotowin/boules_back/extra/14.png";
import bouleBackExtra15 from "../images/jeux/lotowin/boules_back/extra/15.png";
import bouleBackExtra16 from "../images/jeux/lotowin/boules_back/extra/16.png";
import bouleBackExtra17 from "../images/jeux/lotowin/boules_back/extra/17.png";
import bouleBackExtra18 from "../images/jeux/lotowin/boules_back/extra/18.png";
import bouleBackExtra19 from "../images/jeux/lotowin/boules_back/extra/19.png";
import bouleBackExtra20 from "../images/jeux/lotowin/boules_back/extra/20.png";
import bouleBackExtra21 from "../images/jeux/lotowin/boules_back/extra/21.png";
import bouleBackExtra22 from "../images/jeux/lotowin/boules_back/extra/22.png";
import bouleBackExtra23 from "../images/jeux/lotowin/boules_back/extra/23.png";
import bouleBackExtra24 from "../images/jeux/lotowin/boules_back/extra/24.png";
import bouleBackExtra25 from "../images/jeux/lotowin/boules_back/extra/25.png";
import bouleBackExtra26 from "../images/jeux/lotowin/boules_back/extra/26.png";
import bouleBackExtra27 from "../images/jeux/lotowin/boules_back/extra/27.png";
import bouleBackExtra28 from "../images/jeux/lotowin/boules_back/extra/28.png";
import bouleBackExtra29 from "../images/jeux/lotowin/boules_back/extra/29.png";
import bouleBackExtra30 from "../images/jeux/lotowin/boules_back/extra/30.png";
import bouleBackExtra31 from "../images/jeux/lotowin/boules_back/extra/31.png";
import bouleBackExtra32 from "../images/jeux/lotowin/boules_back/extra/32.png";
import bouleBackExtra33 from "../images/jeux/lotowin/boules_back/extra/33.png";
import bouleBackExtra34 from "../images/jeux/lotowin/boules_back/extra/34.png";
import bouleBackExtra35 from "../images/jeux/lotowin/boules_back/extra/35.png";
import bouleBackExtra36 from "../images/jeux/lotowin/boules_back/extra/36.png";
import bouleBackExtra37 from "../images/jeux/lotowin/boules_back/extra/37.png";
import bouleBackExtra38 from "../images/jeux/lotowin/boules_back/extra/38.png";
import bouleBackExtra39 from "../images/jeux/lotowin/boules_back/extra/39.png";
import bouleBackExtra40 from "../images/jeux/lotowin/boules_back/extra/40.png";
import bouleBackExtra41 from "../images/jeux/lotowin/boules_back/extra/41.png";
import bouleBackExtra42 from "../images/jeux/lotowin/boules_back/extra/42.png";
import bouleBackExtra43 from "../images/jeux/lotowin/boules_back/extra/43.png";
import bouleBackExtra44 from "../images/jeux/lotowin/boules_back/extra/44.png";
import bouleBackExtra45 from "../images/jeux/lotowin/boules_back/extra/45.png";
import bouleBackExtra46 from "../images/jeux/lotowin/boules_back/extra/46.png";
import bouleBackExtra47 from "../images/jeux/lotowin/boules_back/extra/47.png";
import bouleBackExtra48 from "../images/jeux/lotowin/boules_back/extra/48.png";
import bouleBackExtra49 from "../images/jeux/lotowin/boules_back/extra/49.png";
import bouleBackExtra50 from "../images/jeux/lotowin/boules_back/extra/50.png";
import bouleBackExtra51 from "../images/jeux/lotowin/boules_back/extra/51.png";
import bouleBackExtra52 from "../images/jeux/lotowin/boules_back/extra/52.png";
import bouleBackExtra53 from "../images/jeux/lotowin/boules_back/extra/53.png";
import bouleBackExtra54 from "../images/jeux/lotowin/boules_back/extra/54.png";
import bouleBackExtra55 from "../images/jeux/lotowin/boules_back/extra/55.png";
import bouleBackExtra56 from "../images/jeux/lotowin/boules_back/extra/56.png";
import bouleBackExtra57 from "../images/jeux/lotowin/boules_back/extra/57.png";
import bouleBackExtra58 from "../images/jeux/lotowin/boules_back/extra/58.png";
import bouleBackExtra59 from "../images/jeux/lotowin/boules_back/extra/59.png";
import bouleBackExtra60 from "../images/jeux/lotowin/boules_back/extra/60.png";
import bouleBackExtra61 from "../images/jeux/lotowin/boules_back/extra/61.png";
import bouleBackExtra62 from "../images/jeux/lotowin/boules_back/extra/62.png";
import bouleBackExtra63 from "../images/jeux/lotowin/boules_back/extra/63.png";
import bouleBackExtra64 from "../images/jeux/lotowin/boules_back/extra/64.png";
import bouleBackExtra65 from "../images/jeux/lotowin/boules_back/extra/65.png";
import bouleBackExtra66 from "../images/jeux/lotowin/boules_back/extra/66.png";
import bouleBackExtra67 from "../images/jeux/lotowin/boules_back/extra/67.png";
import bouleBackExtra68 from "../images/jeux/lotowin/boules_back/extra/68.png";
import bouleBackExtra69 from "../images/jeux/lotowin/boules_back/extra/69.png";
import bouleBackExtra70 from "../images/jeux/lotowin/boules_back/extra/70.png";
import bouleBackExtra71 from "../images/jeux/lotowin/boules_back/extra/71.png";
import bouleBackExtra72 from "../images/jeux/lotowin/boules_back/extra/72.png";
import bouleBackExtra73 from "../images/jeux/lotowin/boules_back/extra/73.png";
import bouleBackExtra74 from "../images/jeux/lotowin/boules_back/extra/74.png";
import bouleBackExtra75 from "../images/jeux/lotowin/boules_back/extra/75.png";
import bouleBackExtra76 from "../images/jeux/lotowin/boules_back/extra/76.png";
import bouleBackExtra77 from "../images/jeux/lotowin/boules_back/extra/77.png";
import bouleBackExtra78 from "../images/jeux/lotowin/boules_back/extra/78.png";
import bouleBackExtra79 from "../images/jeux/lotowin/boules_back/extra/79.png";
import bouleBackExtra80 from "../images/jeux/lotowin/boules_back/extra/80.png";

export const boulesTirageLW = {
    super: [
        bouleSuper1,
        bouleSuper2,
        bouleSuper3,
        bouleSuper4,
        bouleSuper5, 
        bouleSuper6,
        bouleSuper7,
        bouleSuper8,
        bouleSuper9,
        bouleSuper10,
        bouleSuper11,
        bouleSuper12,
        bouleSuper13,
        bouleSuper14,
        bouleSuper15,
        bouleSuper16,
        bouleSuper17,
        bouleSuper18,
        bouleSuper19,
        bouleSuper20,
        bouleSuper21,
        bouleSuper22,
        bouleSuper23,
        bouleSuper24,
        bouleSuper25,
        bouleSuper26,
        bouleSuper27,
        bouleSuper28,
        bouleSuper29,
        bouleSuper30,
        bouleSuper31,
        bouleSuper32,
        bouleSuper33,
        bouleSuper34,
        bouleSuper35,
        bouleSuper36,
        bouleSuper37,
        bouleSuper38,
        bouleSuper39,
        bouleSuper40,
        bouleSuper41,
        bouleSuper42,
        bouleSuper43,
        bouleSuper44,
        bouleSuper45,
        bouleSuper46,
        bouleSuper47,
        bouleSuper48,
        bouleSuper49,
        bouleSuper50,
        bouleSuper51,
        bouleSuper52,
        bouleSuper53,
        bouleSuper54,
        bouleSuper55,
        bouleSuper56,
        bouleSuper57,
        bouleSuper58,
        bouleSuper59,
        bouleSuper60,
        bouleSuper61,
        bouleSuper62,
        bouleSuper63,
        bouleSuper64,
        bouleSuper65,
        bouleSuper66,
        bouleSuper67,
        bouleSuper68,
        bouleSuper69,
        bouleSuper70,
        bouleSuper71,
        bouleSuper72,
        bouleSuper73,
        bouleSuper74,
        bouleSuper75,
        bouleSuper76,
        bouleSuper77,
        bouleSuper78,
        bouleSuper79,
        bouleSuper80,
    ],
    maxi: [
        bouleMaxi1,
        bouleMaxi2,
        bouleMaxi3,
        bouleMaxi4,
        bouleMaxi5, 
        bouleMaxi6,
        bouleMaxi7,
        bouleMaxi8,
        bouleMaxi9,
        bouleMaxi10,
        bouleMaxi11,
        bouleMaxi12,
        bouleMaxi13,
        bouleMaxi14,
        bouleMaxi15,
        bouleMaxi16,
        bouleMaxi17,
        bouleMaxi18,
        bouleMaxi19,
        bouleMaxi20,
        bouleMaxi21,
        bouleMaxi22,
        bouleMaxi23,
        bouleMaxi24,
        bouleMaxi25,
        bouleMaxi26,
        bouleMaxi27,
        bouleMaxi28,
        bouleMaxi29,
        bouleMaxi30,
        bouleMaxi31,
        bouleMaxi32,
        bouleMaxi33,
        bouleMaxi34,
        bouleMaxi35,
        bouleMaxi36,
        bouleMaxi37,
        bouleMaxi38,
        bouleMaxi39,
        bouleMaxi40,
        bouleMaxi41,
        bouleMaxi42,
        bouleMaxi43,
        bouleMaxi44,
        bouleMaxi45,
        bouleMaxi46,
        bouleMaxi47,
        bouleMaxi48,
        bouleMaxi49,
        bouleMaxi50,
        bouleMaxi51,
        bouleMaxi52,
        bouleMaxi53,
        bouleMaxi54,
        bouleMaxi55,
        bouleMaxi56,
        bouleMaxi57,
        bouleMaxi58,
        bouleMaxi59,
        bouleMaxi60,
        bouleMaxi61,
        bouleMaxi62,
        bouleMaxi63,
        bouleMaxi64,
        bouleMaxi65,
        bouleMaxi66,
        bouleMaxi67,
        bouleMaxi68,
        bouleMaxi69,
        bouleMaxi70,
        bouleMaxi71,
        bouleMaxi72,
        bouleMaxi73,
        bouleMaxi74,
        bouleMaxi75,
        bouleMaxi76,
        bouleMaxi77,
        bouleMaxi78,
        bouleMaxi79,
        bouleMaxi80,
    ],
    mega: [
        bouleMega1,
        bouleMega2,
        bouleMega3,
        bouleMega4,
        bouleMega5, 
        bouleMega6,
        bouleMega7,
        bouleMega8,
        bouleMega9,
        bouleMega10,
        bouleMega11,
        bouleMega12,
        bouleMega13,
        bouleMega14,
        bouleMega15,
        bouleMega16,
        bouleMega17,
        bouleMega18,
        bouleMega19,
        bouleMega20,
        bouleMega21,
        bouleMega22,
        bouleMega23,
        bouleMega24,
        bouleMega25,
        bouleMega26,
        bouleMega27,
        bouleMega28,
        bouleMega29,
        bouleMega30,
        bouleMega31,
        bouleMega32,
        bouleMega33,
        bouleMega34,
        bouleMega35,
        bouleMega36,
        bouleMega37,
        bouleMega38,
        bouleMega39,
        bouleMega40,
        bouleMega41,
        bouleMega42,
        bouleMega43,
        bouleMega44,
        bouleMega45,
        bouleMega46,
        bouleMega47,
        bouleMega48,
        bouleMega49,
        bouleMega50,
        bouleMega51,
        bouleMega52,
        bouleMega53,
        bouleMega54,
        bouleMega55,
        bouleMega56,
        bouleMega57,
        bouleMega58,
        bouleMega59,
        bouleMega60,
        bouleMega61,
        bouleMega62,
        bouleMega63,
        bouleMega64,
        bouleMega65,
        bouleMega66,
        bouleMega67,
        bouleMega68,
        bouleMega69,
        bouleMega70,
        bouleMega71,
        bouleMega72,
        bouleMega73,
        bouleMega74,
        bouleMega75,
        bouleMega76,
        bouleMega77,
        bouleMega78,
        bouleMega79,
        bouleMega80,
    ],
    extra: [
        bouleExtra1,
        bouleExtra2,
        bouleExtra3,
        bouleExtra4,
        bouleExtra5, 
        bouleExtra6,
        bouleExtra7,
        bouleExtra8,
        bouleExtra9,
        bouleExtra10,
        bouleExtra11,
        bouleExtra12,
        bouleExtra13,
        bouleExtra14,
        bouleExtra15,
        bouleExtra16,
        bouleExtra17,
        bouleExtra18,
        bouleExtra19,
        bouleExtra20,
        bouleExtra21,
        bouleExtra22,
        bouleExtra23,
        bouleExtra24,
        bouleExtra25,
        bouleExtra26,
        bouleExtra27,
        bouleExtra28,
        bouleExtra29,
        bouleExtra30,
        bouleExtra31,
        bouleExtra32,
        bouleExtra33,
        bouleExtra34,
        bouleExtra35,
        bouleExtra36,
        bouleExtra37,
        bouleExtra38,
        bouleExtra39,
        bouleExtra40,
        bouleExtra41,
        bouleExtra42,
        bouleExtra43,
        bouleExtra44,
        bouleExtra45,
        bouleExtra46,
        bouleExtra47,
        bouleExtra48,
        bouleExtra49,
        bouleExtra50,
        bouleExtra51,
        bouleExtra52,
        bouleExtra53,
        bouleExtra54,
        bouleExtra55,
        bouleExtra56,
        bouleExtra57,
        bouleExtra58,
        bouleExtra59,
        bouleExtra60,
        bouleExtra61,
        bouleExtra62,
        bouleExtra63,
        bouleExtra64,
        bouleExtra65,
        bouleExtra66,
        bouleExtra67,
        bouleExtra68,
        bouleExtra69,
        bouleExtra70,
        bouleExtra71,
        bouleExtra72,
        bouleExtra73,
        bouleExtra74,
        bouleExtra75,
        bouleExtra76,
        bouleExtra77,
        bouleExtra78,
        bouleExtra79,
        bouleExtra80,
    ],
    superBack: [
        bouleBackSuper1,
        bouleBackSuper2,
        bouleBackSuper3,
        bouleBackSuper4,
        bouleBackSuper5, 
        bouleBackSuper6,
        bouleBackSuper7,
        bouleBackSuper8,
        bouleBackSuper9,
        bouleBackSuper10,
        bouleBackSuper11,
        bouleBackSuper12,
        bouleBackSuper13,
        bouleBackSuper14,
        bouleBackSuper15,
        bouleBackSuper16,
        bouleBackSuper17,
        bouleBackSuper18,
        bouleBackSuper19,
        bouleBackSuper20,
        bouleBackSuper21,
        bouleBackSuper22,
        bouleBackSuper23,
        bouleBackSuper24,
        bouleBackSuper25,
        bouleBackSuper26,
        bouleBackSuper27,
        bouleBackSuper28,
        bouleBackSuper29,
        bouleBackSuper30,
        bouleBackSuper31,
        bouleBackSuper32,
        bouleBackSuper33,
        bouleBackSuper34,
        bouleBackSuper35,
        bouleBackSuper36,
        bouleBackSuper37,
        bouleBackSuper38,
        bouleBackSuper39,
        bouleBackSuper40,
        bouleBackSuper41,
        bouleBackSuper42,
        bouleBackSuper43,
        bouleBackSuper44,
        bouleBackSuper45,
        bouleBackSuper46,
        bouleBackSuper47,
        bouleBackSuper48,
        bouleBackSuper49,
        bouleBackSuper50,
        bouleBackSuper51,
        bouleBackSuper52,
        bouleBackSuper53,
        bouleBackSuper54,
        bouleBackSuper55,
        bouleBackSuper56,
        bouleBackSuper57,
        bouleBackSuper58,
        bouleBackSuper59,
        bouleBackSuper60,
        bouleBackSuper61,
        bouleBackSuper62,
        bouleBackSuper63,
        bouleBackSuper64,
        bouleBackSuper65,
        bouleBackSuper66,
        bouleBackSuper67,
        bouleBackSuper68,
        bouleBackSuper69,
        bouleBackSuper70,
        bouleBackSuper71,
        bouleBackSuper72,
        bouleBackSuper73,
        bouleBackSuper74,
        bouleBackSuper75,
        bouleBackSuper76,
        bouleBackSuper77,
        bouleBackSuper78,
        bouleBackSuper79,
        bouleBackSuper80,
    ],
    maxiBack: [
        bouleBackMaxi1,
        bouleBackMaxi2,
        bouleBackMaxi3,
        bouleBackMaxi4,
        bouleBackMaxi5, 
        bouleBackMaxi6,
        bouleBackMaxi7,
        bouleBackMaxi8,
        bouleBackMaxi9,
        bouleBackMaxi10,
        bouleBackMaxi11,
        bouleBackMaxi12,
        bouleBackMaxi13,
        bouleBackMaxi14,
        bouleBackMaxi15,
        bouleBackMaxi16,
        bouleBackMaxi17,
        bouleBackMaxi18,
        bouleBackMaxi19,
        bouleBackMaxi20,
        bouleBackMaxi21,
        bouleBackMaxi22,
        bouleBackMaxi23,
        bouleBackMaxi24,
        bouleBackMaxi25,
        bouleBackMaxi26,
        bouleBackMaxi27,
        bouleBackMaxi28,
        bouleBackMaxi29,
        bouleBackMaxi30,
        bouleBackMaxi31,
        bouleBackMaxi32,
        bouleBackMaxi33,
        bouleBackMaxi34,
        bouleBackMaxi35,
        bouleBackMaxi36,
        bouleBackMaxi37,
        bouleBackMaxi38,
        bouleBackMaxi39,
        bouleBackMaxi40,
        bouleBackMaxi41,
        bouleBackMaxi42,
        bouleBackMaxi43,
        bouleBackMaxi44,
        bouleBackMaxi45,
        bouleBackMaxi46,
        bouleBackMaxi47,
        bouleBackMaxi48,
        bouleBackMaxi49,
        bouleBackMaxi50,
        bouleBackMaxi51,
        bouleBackMaxi52,
        bouleBackMaxi53,
        bouleBackMaxi54,
        bouleBackMaxi55,
        bouleBackMaxi56,
        bouleBackMaxi57,
        bouleBackMaxi58,
        bouleBackMaxi59,
        bouleBackMaxi60,
        bouleBackMaxi61,
        bouleBackMaxi62,
        bouleBackMaxi63,
        bouleBackMaxi64,
        bouleBackMaxi65,
        bouleBackMaxi66,
        bouleBackMaxi67,
        bouleBackMaxi68,
        bouleBackMaxi69,
        bouleBackMaxi70,
        bouleBackMaxi71,
        bouleBackMaxi72,
        bouleBackMaxi73,
        bouleBackMaxi74,
        bouleBackMaxi75,
        bouleBackMaxi76,
        bouleBackMaxi77,
        bouleBackMaxi78,
        bouleBackMaxi79,
        bouleBackMaxi80,
    ],
    megaBack: [
        bouleBackMega1,
        bouleBackMega2,
        bouleBackMega3,
        bouleBackMega4,
        bouleBackMega5, 
        bouleBackMega6,
        bouleBackMega7,
        bouleBackMega8,
        bouleBackMega9,
        bouleBackMega10,
        bouleBackMega11,
        bouleBackMega12,
        bouleBackMega13,
        bouleBackMega14,
        bouleBackMega15,
        bouleBackMega16,
        bouleBackMega17,
        bouleBackMega18,
        bouleBackMega19,
        bouleBackMega20,
        bouleBackMega21,
        bouleBackMega22,
        bouleBackMega23,
        bouleBackMega24,
        bouleBackMega25,
        bouleBackMega26,
        bouleBackMega27,
        bouleBackMega28,
        bouleBackMega29,
        bouleBackMega30,
        bouleBackMega31,
        bouleBackMega32,
        bouleBackMega33,
        bouleBackMega34,
        bouleBackMega35,
        bouleBackMega36,
        bouleBackMega37,
        bouleBackMega38,
        bouleBackMega39,
        bouleBackMega40,
        bouleBackMega41,
        bouleBackMega42,
        bouleBackMega43,
        bouleBackMega44,
        bouleBackMega45,
        bouleBackMega46,
        bouleBackMega47,
        bouleBackMega48,
        bouleBackMega49,
        bouleBackMega50,
        bouleBackMega51,
        bouleBackMega52,
        bouleBackMega53,
        bouleBackMega54,
        bouleBackMega55,
        bouleBackMega56,
        bouleBackMega57,
        bouleBackMega58,
        bouleBackMega59,
        bouleBackMega60,
        bouleBackMega61,
        bouleBackMega62,
        bouleBackMega63,
        bouleBackMega64,
        bouleBackMega65,
        bouleBackMega66,
        bouleBackMega67,
        bouleBackMega68,
        bouleBackMega69,
        bouleBackMega70,
        bouleBackMega71,
        bouleBackMega72,
        bouleBackMega73,
        bouleBackMega74,
        bouleBackMega75,
        bouleBackMega76,
        bouleBackMega77,
        bouleBackMega78,
        bouleBackMega79,
        bouleBackMega80,
    ],
    extraBack: [
        bouleBackExtra1,
        bouleBackExtra2,
        bouleBackExtra3,
        bouleBackExtra4,
        bouleBackExtra5, 
        bouleBackExtra6,
        bouleBackExtra7,
        bouleBackExtra8,
        bouleBackExtra9,
        bouleBackExtra10,
        bouleBackExtra11,
        bouleBackExtra12,
        bouleBackExtra13,
        bouleBackExtra14,
        bouleBackExtra15,
        bouleBackExtra16,
        bouleBackExtra17,
        bouleBackExtra18,
        bouleBackExtra19,
        bouleBackExtra20,
        bouleBackExtra21,
        bouleBackExtra22,
        bouleBackExtra23,
        bouleBackExtra24,
        bouleBackExtra25,
        bouleBackExtra26,
        bouleBackExtra27,
        bouleBackExtra28,
        bouleBackExtra29,
        bouleBackExtra30,
        bouleBackExtra31,
        bouleBackExtra32,
        bouleBackExtra33,
        bouleBackExtra34,
        bouleBackExtra35,
        bouleBackExtra36,
        bouleBackExtra37,
        bouleBackExtra38,
        bouleBackExtra39,
        bouleBackExtra40,
        bouleBackExtra41,
        bouleBackExtra42,
        bouleBackExtra43,
        bouleBackExtra44,
        bouleBackExtra45,
        bouleBackExtra46,
        bouleBackExtra47,
        bouleBackExtra48,
        bouleBackExtra49,
        bouleBackExtra50,
        bouleBackExtra51,
        bouleBackExtra52,
        bouleBackExtra53,
        bouleBackExtra54,
        bouleBackExtra55,
        bouleBackExtra56,
        bouleBackExtra57,
        bouleBackExtra58,
        bouleBackExtra59,
        bouleBackExtra60,
        bouleBackExtra61,
        bouleBackExtra62,
        bouleBackExtra63,
        bouleBackExtra64,
        bouleBackExtra65,
        bouleBackExtra66,
        bouleBackExtra67,
        bouleBackExtra68,
        bouleBackExtra69,
        bouleBackExtra70,
        bouleBackExtra71,
        bouleBackExtra72,
        bouleBackExtra73,
        bouleBackExtra74,
        bouleBackExtra75,
        bouleBackExtra76,
        bouleBackExtra77,
        bouleBackExtra78,
        bouleBackExtra79,
        bouleBackExtra80,
    ]
}