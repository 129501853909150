import React from "react";
import banniere from "../images/bank_banniere.svg";
import Credit from "../components/Credits";
import { connect } from "react-redux";
import { getJeu, setGains } from "../actions/jeux";
import { setRemoveCredits, verifCredits } from "../actions/profil";
import Alert from "../components/Alert";
import LotoWin from "../components/lotowin/Lotowin";
import { getDomain } from "../helpers";
import Credits from "../components/Credits";
import iconSet from "../selection.json";
import IcomoonReact from "icomoon-react";
import ScratchCard from 'react-scratchcard-v2';
import couronne from "../images/jeux/keryan/couronne.svg";
import onze from "../images/jeux/onze/onze.svg";
import x11 from "../images/jeux/x11/game.png";
import x11Piece from "../images/jeux/x11/x11Piece.png";
import x11Euro from "../images/jeux/x11/x11Euro.png";
import Win from "../components/Win";
import de1 from "../images/jeux/onze/des/1.png";
import de2 from "../images/jeux/onze/des/2.png";
import de3 from "../images/jeux/onze/des/3.png";
import de4 from "../images/jeux/onze/des/4.png";
import de5 from "../images/jeux/onze/des/5.png";
import de6 from "../images/jeux/onze/des/6.png";
import chouetteWithoutColor from "../images/jeux/chouette/chouette_without_color.png";
import chouette from "../images/jeux/chouette/chouette.png";
import bank from "../images/jeux/bank/game.png";
import logo from "../images/logo.svg";
import besace from "../images/jeux/besace/game.svg";
import creditImg from "../images/credits.svg";

const regles = {
    malo: <p className="jeuRegles__text">Grattez la couronne,<br />Si vous découvrez 1 "malo", vous gagnez <strong>100k&nbsp;pts</strong><br />Si vous découvrez 2 "malo", vous gagnez <strong>500k&nbsp;pts</strong><br />Si vous découvrez 3 "malo", vous gagnez {<Credits nb={5} className="jeuRegles__credits"/>}<br />Si vous découvrez 4 "malo", vous gagnez {<Credits nb={15} class="jeuRegles__credits"/>}<br />Si vous découvrez 5 "malo", vous gagnez <strong>100€ de bon d'achats à King Jouets</strong></p>,
    keryan: <p className="jeuRegles__text">Grattez la couronne,<br />Si vous découvrez 1 "keryan", vous gagnez <strong>100k&nbsp;pts</strong><br />Si vous découvrez 2 "keryan", vous gagnez <strong>500k&nbsp;pts</strong><br />Si vous découvrez 3 "keryan", vous gagnez {<Credits nb={5} className="jeuRegles__credits"/>}<br />Si vous découvrez 4 "keryan", vous gagnez {<Credits nb={15} class="jeuRegles__credits"/>}<br />Si vous découvrez 5 "keryan", vous gagnez <strong>100€ de bon d'achats à King Jouets</strong></p>,
    lila: <p className="jeuRegles__text">Grattez la couronne,<br />Si vous découvrez 1 "lila", vous gagnez <strong>100k&nbsp;pts</strong><br />Si vous découvrez 2 "lila", vous gagnez <strong>500k&nbsp;pts</strong><br />Si vous découvrez 3 "lila", vous gagnez {<Credits nb={5} class="jeuRegles__credits"/>}<br />Si vous découvrez 4 "lila", vous gagnez {<Credits nb={15} class="jeuRegles__credits"/>}<br />Si vous découvrez 5 "lila", vous gagnez <strong>100€ de bon d'achats à King Jouets</strong></p>,
    onze: <p className="jeuRegles__text">Grattez les dés, si sur l'une des deux paires de dés, vous avez un total de 11, vous gagnez le gain associé</p>,
    x11: <p className="jeuRegles__text jeuRegles__text--x11">Grattez les symboles <span className="jeuRegles__credits"><img src={x11Euro} alt="Credit, monnaie du site" className="credit profile__credit" /></span> et <span className="jeuRegles__credits"><img src={x11Piece} alt="Credit, monnaie du site" className="credit profile__credit" /></span>.<br />Si vous découvrez l'un des numéros présent sous le signe <span className="jeuRegles__credits"><img src={x11Euro} alt="Credit, monnaie du site" className="credit profile__credit" /></span> sous le symbole <span className="jeuRegles__credits"><img src={x11Piece} alt="Credit, monnaie du site" className="credit profile__credit" /></span>, vous remportez le gain associé.<br />Si vous découvrez X1, X2, X5, X10 ou X11 sous l'un des symboles <span className="jeuRegles__credits"><img src={x11Piece} alt="Credit, monnaie du site" className="credit profile__credit" /></span>, vous remportez le gain associé multiplié par le X.</p>,
    chouette: <p className="jeuRegles__text jeuRegles__text--chouette">Grattez les 3 chouettes, si derrières les chouettes: <br />- Vous découvrez 1 <span className="jeuRegles__credits"><img src={chouetteWithoutColor} alt="Chouette sans couleur" className="credit" /></span>, vous remportez 250k pts<br />- Vous découvrez 2 <span className="jeuRegles__credits"><img src={chouetteWithoutColor} alt="Chouette sans couleur" className="credit" /></span>, vous remportez <Credits nb="5" class="jeuRegles__credits" /><br />- Vous découvrez 3 <span className="jeuRegles__credits"><img src={chouetteWithoutColor} alt="Chouette sans couleur" className="credit" /></span>, vous remportez <strong>un bon d'achat de 50€ à Yves Rocher</strong><br /></p>,
    bank: <p className="jeuRegles__text jeuRegles__text--bank">Grattez les <strong style={{color: "rgb(247, 210, 6)"}}>coffres-fort</strong> ainsi que les <strong style={{color: "rgb(247, 210, 6)"}}>lingots</strong>.<br />Si vous découvrez un numéro identique sur le lingot et dans l'un des deux coffres, vous remportez le gain associé et ainsi, tentez de remporter le fameux <strong style={{color: "rgb(247, 210, 6)"}}>Louis d'Or</strong></p>,
    besace: <p className="jeuRegles__text jeuRegles__text--besace">Grattez la besace et découvrez combien de crédits vous gagnez !! Vous pouvez remporter de 1 à 30 crédits !!</p>
}

class Jeu extends React.Component {
    state = {
        alert: {
            active: true,
            type: 'warning',
            title: 'Attente...',
            msg: "Réception des données du jeu en cours..."
        },
        active: false,
        activeRegle: true,
        activeJeu: false,
        nextCredit: false,
        lot: {
            slug: '',
            chance: '',
            lot: ''
        },
        win: false,
        bigWin: false,
        gain: null,
        nextGain: false,
        contentGame: null,
        activeWin: false,
        nextVerif: false,
        width: 0,
        height: 0
    }

    componentDidMount() { 
        document.title = 'Tof La Win - Les jeux';
        const loc = window.location.pathname;
        const param = loc.split('-')[loc.split('-').length - 1];
        this.props.getJeu({jeu: param});
    }

    componentDidUpdate(prevProps, prevState) {
        console.log(this.state)
        if (this.props.jeu !== undefined && prevProps.jeu != this.props.jeu) {
            if (this.props.jeu.alert != this.state.alert) {
                this.setState({
                    alert: this.props.jeu.alert,
                });
            }
            this.setState({
                width: this.getWidth(),
                height: this.getHeight()
            })
        }

        if (this.props.removecred != undefined && this.props.removecred != prevProps.removecred) {
                if (this.props.removecred.alert != prevProps.removecred.alert) {
                this.setState({alert: this.props.removecred.alert});

                if (this.props.removecred.next == false) {
                    this.setState({
                        active: false,
                        activeRegle: false,
                        activeJeu: false,
                        nextCredit: false,
                        lot: {
                            slug: '',
                            chance: '',
                            lot: ''
                        },
                        win: false,
                        bigWin: false,
                        gain: null,
                        nextGain: false,
                        contentGame: null,
                        activeWin: false,
                        nextVerif: false
                    })
                }
            }
            if (this.props.removecred.next != this.state.nextCredit) {
                this.setState({
                    nextCredit: this.props.removecred.next,
                })
            }
        }
        
        if (this.props.verifcredit != undefined && this.props.verifcredit != prevProps.verifcredit) {
            if (this.props.verifcredit.next != this.state.nextVerif) {
                this.setState({
                    nextVerif: this.props.verifcredit.next,
                })
            }
            if (this.props.verifcredit.alert != prevProps.verifcredit.alert) {
                this.setState({alert: this.props.verifcredit.alert});
                if (this.props.verifcredit.next == false) {
                    this.setState({
                        active: false,
                        activeRegle: false,
                        activeJeu: false,
                        nextCredit: false,
                        lot: {
                            slug: '',
                            chance: '',
                            lot: ''
                        },
                        win: false,
                        bigWin: false,
                        gain: null,
                        nextGain: false,
                        contentGame: null,
                        activeWin: false,
                        nextVerif: false
                    })
                }
            }
        }
        
        if (this.props.setgains != undefined && this.props.setgains != prevProps.setgains) {
                if (this.props.setgains.alert != prevProps.setgains.alert) {
                this.setState({alert: this.props.setgains.alert});
                if (this.props.setgains.next == false) {
                    this.setState({
                        active: false,
                        activeRegle: false,
                        activeJeu: false,
                        nextCredit: false,
                        lot: {
                            slug: '',
                            chance: '',
                            lot: ''
                        },
                        win: false,
                        bigWin: false,
                        gain: null,
                        nextGain: false,
                        contentGame: null,
                        activeWin: false,
                        nextVerif: false
                    })
                }
            }
            if (this.props.setgains.next != this.state.nextGain) {
                this.setState({
                    nextGain: this.props.setgains.next,
                    gain: this.props.setgains.gains,
                })
            }
            if (this.props.setgains.bigWin != this.state.bigWin) {
                this.setState({
                    bigWin: this.props.setgains.bigWin
                })
            }
        }

        if (this.state.nextCredit != prevState.nextCredit && this.state.nextCredit) {
            this.getTheGain();
        }

        if (this.state.nextVerif != prevState.nextVerif && this.state.nextVerif) {
            // Retirer crédits
            let tempType = "credits";
            let tempValue = this.props.jeu.datas.nbcredits;
            if (this.props.jeu.datas.slug == "besace") {
                tempType = "besace";
                tempValue = 1;
            }
            this.props.setRemoveCredits({type: tempType, nbcredits: tempValue});
        }

        if (this.state.nextGain != prevState.nextGain && this.state.nextGain) {
            this.lanceJeu();
        }

        if (this.state.lot.slug != '' && this.state.lot != prevState.lot) {
            this.setGain();
        }

        if (this.state.contentGame != null && prevState.contentGame != this.state.contentGame) {
            this.setState({activeJeu: true})
        }
    }

    handleStartJeu() {
        // Fermer Regle
        this.setState({
            activeRegle: false,
            alert: {
                active: true,
                type: 'warning',
                title: 'Récupération des données du jeu',
                msg: 'Récupération des données du jeu en cours...'
            }
        });

        // Vérifier crédits
        let tempType = "credits";
        let tempValue = this.props.jeu.datas.nbcredits;
        if (this.props.jeu.datas.slug == "besace") {
            tempType = "besace";
            tempValue = 1;
        }
        console.log(this.props.jeu)
        this.props.verifCredits({type: tempType, value: tempValue});
    }

    getTheGain() {
        // Recherche du gain
        if (this.state.nextCredit) {
            // Création du tableau
            let lots = [];
            let probas = JSON.parse(this.props.jeu.datas.chance)
            probas.probas.forEach(proba => {
                for (let i = 0; i < proba.chance; i++) {
                    lots.push(proba);
                }
            })

            let countBred = probas.nbchance - lots.length;
            for (let i = 0; i < countBred; i++) {
                lots.push({
                    slug: 'bred',
                    chance: 0,
                    lot: 'Bredouille'
                })
            }

            // Choix d'une clé aléatoire
            let rand = Math.floor(Math.random() * lots.length);

            // Retour du lot gagnant
            this.setState({
                lot: lots[rand],
            })
        }
    }

    setGain() {
        let lot = this.state.lot;
        
        if (lot.slug == "bred") {
            this.setState({win: false, nextCredit: false, nextGain: true})
        } else {
            this.setState({win: true, nextCredit: false})

            // Récupération du type de gain
            let typeLetter = lot.slug[lot.slug.length - 1];
            let type = "";
            switch (typeLetter) {
                case 'c':
                    type = "credits";
                    break;
                case 'p':
                    type = 'points';
                    break;
                case 'e':
                    type = "groslot";
                    break;
                case 'l':
                    type = "groslot";
                    break;
            }

            // Attribution du gain
            this.props.setGains({
                type: type,
                value: lot.slug.substring(0, lot.slug.length - 1),
                nbcreditsmise: this.props.jeu.datas.nbcredits,
                jeu: this.props.jeu.datas.id
            });
        }
    }

    lanceJeu() {
        // Lancer le jeu
        // Remplir le content game
        if (this.props.jeu.datas.slug == "keryan" || this.props.jeu.datas.slug == "malo" || this.props.jeu.datas.slug == "lila") {
            this.createPresident();
        } else {
            if (this.props.jeu.datas.slug == "onze") {
                this.createOnze();
            } else {
                if (this.props.jeu.datas.slug == "x11") {
                    this.createFoisOnze();
                } else {
                    if (this.props.jeu.datas.slug == "chouette") {
                        this.createChouette();
                    } else {
                        if (this.props.jeu.datas.slug == "bank") {
                            this.createBank();
                        } else {
                            if (this.props.jeu.datas.slug == "besace") {
                                this.createBesace();
                            }
                        }
                    }
                }
            }
        }
    }

    createPresident() {
        let prenoms = [];
        let goodprenom = this.props.jeu.datas.slug;
        switch (goodprenom) {
            case "keryan":
                prenoms = ["Bernadette", "Malo", "Tof", "Lila"];
                break;
            case "malo":
                prenoms = ["Bernadette", "Keryan", "Tof", "Lila"];
                break;
            case "lila":
                prenoms = ["Bernadette", "Malo", "Tof", "Keryan"];
                break;
        }
        let nbAlea = 0;
        // Avoir le nombre de "keryan", "malo" ou "lila"
        if (this.state.lot.slug != "bred") {
            JSON.parse(this.props.jeu.datas.chance).probas.forEach((proba, index) => {
                if (proba.slug == this.state.lot.slug) {
                    nbAlea = JSON.parse(this.props.jeu.datas.chance).probas.length - index;
                }
            })
        }
        let keyAlea = [];
        // Trouver le placement aléatoire des bons prénoms
        let aleaNbs = [0,1,2,3,4,5];
        for (let i = 1; i <= nbAlea; i++) {
            let rand = Math.floor(Math.random() * aleaNbs.length);
            keyAlea.push(aleaNbs[rand]);
            aleaNbs.splice(rand, 1);
        }
        // Placer les prénoms
        let prenomsFinaux = [];
        for (let i = 0; i < 6; i++ ) {
            if (keyAlea.includes(i)) {
                prenomsFinaux.push(goodprenom);
            } else {
                prenomsFinaux.push(prenoms[Math.floor(Math.random() * prenoms.length)]);
            }
        }
        
        // Créer le contenu
        let content = <div className="jeuGame__names">
            {
                prenomsFinaux.map((prenom, index) => {
                    return <span className={`jeuGame__name jeuGame__name--${index} ${prenom == goodprenom ? 'jeuGame__name--yellow' : ''}`}>{prenom}</span>
                })
            }
        </div>

        this.setState({contentGame: content});
    }

    createOnze() {
        // Initialisation des variables
        const gains = ["11€ de tickets", "1111 crédits", "111 crédits", "11 crédits", "1 crédit", "111 111 pts", "11 111 pts"];
        const combiPerdantes = [
            [de1,de1],
            [de1,de2],
            [de1,de3],
            [de1,de4],
            [de1,de5],
            [de1,de6],
            [de2,de1],
            [de2,de2],
            [de2,de3],
            [de2,de4],
            [de2,de5],
            [de2,de6],
            [de3,de1],
            [de3,de2],
            [de3,de3],
            [de3,de4],
            [de3,de5],
            [de3,de6],
            [de4,de1],
            [de4,de2],
            [de4,de3],
            [de4,de4],
            [de4,de5],
            [de4,de6],
            [de5,de1],
            [de5,de2],
            [de5,de3],
            [de5,de4],
            [de5,de5],
            [de6,de1],
            [de6,de2],
            [de6,de3],
            [de6,de4],
            [de6,de6]
        ];
        const combiGagnantes = [[de5, de6], [de6, de5]];
        const combiDesChoix = [];
        const combiGainChoix = [];
        const combiPossibGagnantes = [0,1];

        if (this.state.lot.slug == "bred") {
            // Si le lot est perdant
            for(let i = 0; i < 2; i++) {
                let randDes = Math.floor(Math.random() * combiPerdantes.length);
                let randGain = Math.floor(Math.random() * gains.length);
                combiDesChoix.push(combiPerdantes[randDes]);
                combiGainChoix.push(gains[randGain]);
            }
        } else {
            // Choix du dés gagnant
            let rand = Math.floor(Math.random() * combiPossibGagnantes.length);
            for (let i = 0; i < 2; i++) {
                if (i == combiPossibGagnantes[rand]) {
                    // Dés gagnant
                    let randDes = Math.floor(Math.random() * combiGagnantes.length);
                    combiDesChoix.push(combiGagnantes[randDes]);
                    combiGainChoix.push(this.state.lot.lot);
                } else {
                    // Dés pas gagnant
                    let randDes = Math.floor(Math.random() * combiPerdantes.length);
                    let randGain = Math.floor(Math.random() * gains.length);
                    combiDesChoix.push(combiPerdantes[randDes]);
                    combiGainChoix.push(gains[randGain]);
                }
            }
        }

        // Créer le contenu
        let content = <>
            <div className="jeuGame__des jeuGame__des--1">
                <img src={combiDesChoix[0][0]} alt="Dé du jeu Onze" className="jeuGame__de1" />
                <img src={combiDesChoix[0][1]} alt="Dé du jeu Onze" className="jeuGame__de2" />
            </div>
            <span className="jeuGame__deGain jeuGame__deGain--1">{combiGainChoix[0]}</span>
            <div className="jeuGame__des jeuGame__des--2">
                <img src={combiDesChoix[1][0]} alt="Dé du jeu Onze" className="jeuGame__de1" />
                <img src={combiDesChoix[1][1]} alt="Dé du jeu Onze" className="jeuGame__de2" />
            </div>
            <span className="jeuGame__deGain jeuGame__deGain--2">{combiGainChoix[1]}</span>
        </>

        this.setState({contentGame: content});
    }

    createFoisOnze() {
        // Initialisation
        const numsEurosFinaux = [];
        const numsPiecesFinaux = [];
        const lotsFinaux = [];
        const numsEuros = [];
        const numsPieces = [];
        const lots = {
            "110e": {
                aff: "110€",
                poss: [
                    {
                        x: "X1",
                        aff: "110€"
                    }, {
                        x: "X2",
                        aff: "55€",
                    }, {
                        x: "X10",
                        aff: "11€"
                    }, {
                        x: "X11",
                        aff: "10€"
                    }
                ]
           },
           "100e": {
                aff: "100€",
                poss: [
                    {
                        x: "X1",
                        aff: "100€"
                    }, {
                        x: "X2",
                        aff: "50€",
                    }, {
                        x: "X5",
                        aff: "20€",
                    }, {
                        x: "X10",
                        aff: "10€"
                    }
                ]
            },
            "55e": {
                aff: "55€",
                poss: [
                    {
                        x: "X1",
                        aff: "55€"
                    }, {
                        x: "X5",
                        aff: "11€",
                    }, {
                        x: "X11",
                        aff: "5€"
                    }
                ]
            },
            "50e": {
                aff: "50€",
                poss: [
                    {
                        x: "X1",
                        aff: "50€"
                    }, {
                        x: "X2",
                        aff: "25€",
                    }, {
                        x: "X5",
                        aff: "10€",
                    }, {
                        x: "X10",
                        aff: "5€"
                    }
                ]
            },
            "25e": {
                aff: "25€",
                poss: [
                    {
                        x: "X1",
                        aff: "25€"
                    }, {
                        x: "X5",
                        aff: "5€",
                    }
                ]
            },
            "20e": {
                aff: "20€",
                poss: [
                    {
                        x: "X1",
                        aff: "20€"
                    }, {
                        x: "X2",
                        aff: "10€",
                    }, {
                        x: "X5",
                        aff: "4€",
                    }, {
                        x: "X10",
                        aff: "2€"
                    }
                ]
            },
            "10e": {
                aff: "10€",
                poss: [
                    {
                        x: "X1",
                        aff: "10€"
                    }, {
                        x: "X2",
                        aff: "5€",
                    }, {
                        x: "X5",
                        aff: "2€",
                    }, {
                        x: "X10",
                        aff: "1€"
                    }
                ]
            },
            "5e": {
                aff: "5€",
                poss: [
                    {
                        x: "X1",
                        aff: "5€"
                    }, {
                        x: "X5",
                        aff: "1€",
                    }
                ]
            },
            "1100c": {
                aff: <Credits nb="1100" class="x11credits"/>,
                poss: [
                    {
                        x: "X1",
                        aff: <Credits nb="1100" class="x11credits"/>
                    }, {
                        x: "X2",
                        aff: <Credits nb="550" class="x11credits"/>,
                    }, {
                        x: "X5",
                        aff: <Credits nb="220" class="x11credits"/>,
                    }, {
                        x: "X10",
                        aff: <Credits nb="110" class="x11credits"/>
                    }, {
                        x: "X11",
                        aff: <Credits nb="100" class="x11credits"/>
                    }
                ]
            },
            "1000c": {
                aff: <Credits nb="1000" class="x11credits"/>,
                poss: [
                    {
                        x: "X1",
                        aff: <Credits nb="1000" class="x11credits"/>
                    }, {
                        x: "X2",
                        aff: <Credits nb="500" class="x11credits"/>,
                    }, {
                        x: "X5",
                        aff: <Credits nb="200" class="x11credits"/>,
                    }, {
                        x: "X10",
                        aff: <Credits nb="100" class="x11credits"/>
                    }
                ]
            },
            "550c": {
                aff: <Credits nb="550" class="x11credits"/>,
                poss: [
                    {
                        x: "X1",
                        aff: <Credits nb="550" class="x11credits"/>
                    }, {
                        x: "X2",
                        aff: <Credits nb="275" class="x11credits"/>,
                    }, {
                        x: "X5",
                        aff: <Credits nb="110" class="x11credits"/>,
                    }, {
                        x: "X10",
                        aff: <Credits nb="55" class="x11credits"/>
                    }, {
                        x: "X11",
                        aff: <Credits nb="50" class="x11credits"/>
                    }
                ]
            },
            "500c": {
                aff: <Credits nb="500" class="x11credits"/>,
                poss: [
                    {
                        x: "X1",
                        aff: <Credits nb="500" class="x11credits"/>
                    }, {
                        x: "X2",
                        aff: <Credits nb="250" class="x11credits"/>,
                    }, {
                        x: "X5",
                        aff: <Credits nb="100" class="x11credits"/>,
                    }, {
                        x: "X10",
                        aff: <Credits nb="50" class="x11credits"/>
                    }
                ]
            },
            "250c": {
                aff: <Credits nb="250" class="x11credits"/>,
                poss: [
                    {
                        x: "X1",
                        aff: <Credits nb="250" class="x11credits"/>
                    }, {
                        x: "X2",
                        aff: <Credits nb="125" class="x11credits"/>,
                    }, {
                        x: "X5",
                        aff: <Credits nb="50" class="x11credits"/>,
                    }, {
                        x: "X10",
                        aff: <Credits nb="25" class="x11credits"/>
                    }
                ]
            },
            "220c": {
                aff: <Credits nb="220" class="x11credits"/>,
                poss: [
                    {
                        x: "X1",
                        aff: <Credits nb="220" class="x11credits"/>
                    }, {
                        x: "X2",
                        aff: <Credits nb="110" class="x11credits"/>,
                    }, {
                        x: "X5",
                        aff: <Credits nb="44" class="x11credits"/>,
                    }, {
                        x: "X10",
                        aff: <Credits nb="22" class="x11credits"/>
                    }, {
                        x: "X11",
                        aff: <Credits nb="20" class="x11credits"/>
                    }
                ]
            },
            "200c": {
                aff: <Credits nb="200" class="x11credits"/>,
                poss: [
                    {
                        x: "X1",
                        aff: <Credits nb="200" class="x11credits"/>
                    }, {
                        x: "X2",
                        aff: <Credits nb="100" class="x11credits"/>,
                    }, {
                        x: "X5",
                        aff: <Credits nb="40" class="x11credits"/>,
                    }, {
                        x: "X10",
                        aff: <Credits nb="20" class="x11credits"/>
                    }
                ]
            },
            "110c": {
                aff: <Credits nb="110" class="x11credits"/>,
                poss: [
                    {
                        x: "X1",
                        aff: <Credits nb="110" class="x11credits"/>
                    }, {
                        x: "X2",
                        aff: <Credits nb="55" class="x11credits"/>,
                    }, {
                        x: "X5",
                        aff: <Credits nb="22" class="x11credits"/>,
                    }, {
                        x: "X10",
                        aff: <Credits nb="11" class="x11credits"/>
                    }, {
                        x: "X11",
                        aff: <Credits nb="10" class="x11credits"/>
                    }
                ]
            },
            "100c": {
                aff: <Credits nb="100" class="x11credits"/>,
                poss: [
                    {
                        x: "X1",
                        aff: <Credits nb="100" class="x11credits"/>
                    }, {
                        x: "X2",
                        aff: <Credits nb="50" class="x11credits"/>,
                    }, {
                        x: "X5",
                        aff: <Credits nb="20" class="x11credits"/>,
                    }, {
                        x: "X10",
                        aff: <Credits nb="10" class="x11credits"/>
                    }
                ]
            },
            "55c": {
                aff: <Credits nb="55" class="x11credits"/>,
                poss: [
                    {
                        x: "X1",
                        aff: <Credits nb="55" class="x11credits"/>
                    }, {
                        x: "X5",
                        aff: <Credits nb="11" class="x11credits"/>,
                    }, {
                        x: "X11",
                        aff: <Credits nb="5" class="x11credits"/>
                    }
                ]
            },
            "50c": {
                aff: <Credits nb="50" class="x11credits"/>,
                poss: [
                    {
                        x: "X1",
                        aff: <Credits nb="50" class="x11credits"/>
                    }, {
                        x: "X2",
                        aff: <Credits nb="25" class="x11credits"/>,
                    }, {
                        x: "X5",
                        aff: <Credits nb="10" class="x11credits"/>,
                    }, {
                        x: "X10",
                        aff: <Credits nb="5" class="x11credits"/>
                    }
                ]
            },
            "40c": {
                aff: <Credits nb="40" class="x11credits"/>,
                poss: [
                    {
                        x: "X1",
                        aff: <Credits nb="40" class="x11credits"/>
                    }, {
                        x: "X2",
                        aff: <Credits nb="20" class="x11credits"/>,
                    }, {
                        x: "X10",
                        aff: <Credits nb="4" class="x11credits"/>
                    }
                ]
            },
            "25c": {
                aff: <Credits nb="25" class="x11credits"/>,
                poss: [
                    {
                        x: "X1",
                        aff: <Credits nb="25" class="x11credits"/>
                    }, {
                        x: "X5",
                        aff: <Credits nb="5" class="x11credits"/>,
                    }
                ]
            },
            "20c": {
                aff: <Credits nb="20" class="x11credits"/>,
                poss: [
                    {
                        x: "X1",
                        aff: <Credits nb="20" class="x11credits"/>
                    }, {
                        x: "X2",
                        aff: <Credits nb="10" class="x11credits"/>,
                    }, {
                        x: "X5",
                        aff: <Credits nb="4" class="x11credits"/>,
                    }, {
                        x: "X10",
                        aff: <Credits nb="2" class="x11credits"/>
                    }
                ]
            },
            "10c": {
                aff: <Credits nb="10" class="x11credits"/>,
                poss: [
                    {
                        x: "X1",
                        aff: <Credits nb="10" class="x11credits"/>
                    }, {
                        x: "X2",
                        aff: <Credits nb="5" class="x11credits"/>,
                    }, {
                        x: "X5",
                        aff: <Credits nb="2" class="x11credits"/>,
                    }, {
                        x: "X10",
                        aff: <Credits nb="1" class="x11credits"/>
                    }
                ]
            },
            "5c": {
                aff: <Credits nb="5" class="x11credits"/>,
                poss: [
                    {
                        x: "X1",
                        aff: <Credits nb="5" class="x11credits"/>
                    }, {
                        x: "X5",
                        aff: <Credits nb="1" class="x11credits"/>,
                    }
                ]
            }
        };
        const lotsAff = ["110€","100€","55€","50€","25€","20€","10€","5€",<Credits nb="1100" class="x11credits"/>,<Credits nb="1000" class="x11credits"/>,<Credits nb="550" class="x11credits"/>,<Credits nb="500" class="x11credits"/>,<Credits nb="250" class="x11credits"/>,<Credits nb="220" class="x11credits"/>,<Credits nb="200" class="x11credits"/>,<Credits nb="110" class="x11credits"/>,<Credits nb="100" class="x11credits"/>,<Credits nb="55" class="x11credits"/>,<Credits nb="50" class="x11credits"/>,<Credits nb="40" class="x11credits"/>,<Credits nb="25" class="x11credits"/>,<Credits nb="20" class="x11credits"/>,<Credits nb="10" class="x11credits"/>,<Credits nb="5" class="x11credits"/>];
        const maniereGain = ["x", "num"];
        const placementsPieces = [];
        const placementsEuros = [0,1,2,3,4,5];
        let placementPiece = null;
        let placementEuro = null;

        for (let i = 1; i<=50; i++) {
            numsEuros.push(i);
            numsPieces.push(i);
            if (i <= 30) {
                placementsPieces.push(i);
            }
        }

        let numGagnant = null;
        let xRand = null;
        let numGagnantRand = null;

        // S'il y a un gain
        if (this.state.lot.slug != "bred") {
            // Choix du type de gain (X ou numéro)
            let manGain = maniereGain[Math.floor(Math.random() * maniereGain.length)];

            // Trouver le placement de l'euro (sauf si X)
            if (manGain == "num") {
                placementEuro = placementsEuros[Math.floor(Math.random() * placementsEuros.length)];
            }
            // Trouver le placement de la piece
            placementPiece = placementsPieces[Math.floor(Math.random() * placementsPieces.length)];

            // Si ce n'est pas X, choisir le numéro gagnant
            if (manGain == "num") {
                numGagnantRand = Math.floor(Math.random() * numsEuros.length);
                numGagnant = numsEuros[numGagnantRand];
                numsEuros.splice(numGagnantRand, 1);
                numsPieces.splice(numGagnantRand, 1);
            } else {
                xRand = lots[this.state.lot.slug].poss[Math.floor(Math.random() * lots[this.state.lot.slug].poss.length)];
            }
        }
        
        // Mise en place des euros
        for (let i = 0; i < 6; i++) {
            if (placementEuro != null && i == placementEuro) {
                numsEurosFinaux.push(numGagnant);
            } else {
                let randNumEuro = Math.floor(Math.random() * numsEuros.length);
                numsEurosFinaux.push(numsEuros[randNumEuro]);
                numsEuros.splice(randNumEuro, 1);
                numsPieces.splice(randNumEuro, 1);
            }
        }

        // Mise en place des pieces
        for (let i = 0; i < 30; i++) {
            if (placementPiece != null && i == placementPiece) {
                if (xRand != null) {
                    numsPiecesFinaux.push(xRand.x);
                } else {
                    numsPiecesFinaux.push(numGagnant);
                }
            } else {
                let randNumPiece = Math.floor(Math.random() * numsPieces.length);
                numsPiecesFinaux.push(numsPieces[randNumPiece]);
                numsPieces.splice(randNumPiece, 1);
                numsEuros.splice(randNumPiece, 1);
            }
        }

        // Mise en place des lots
        for (let i = 0; i < 30; i++) {
            if (placementPiece != null && i == placementPiece) {
                if (xRand != null) {
                    lotsFinaux.push(xRand.aff);
                } else {
                    lotsFinaux.push(lots[this.state.lot.slug].aff);
                }
            } else {
                lotsFinaux.push(lotsAff[Math.floor(Math.random() * lotsAff.length)])
            }
        }

        // Affichage
        let content = <>
            <div className="jeuGame__euros">
                {numsEurosFinaux.map((num, index) => {
                    return <span className={`jeuGame__euro jeuGame__euro--${index}`}>{num}</span>
                })}
            </div>
            <div className="jeuGame__pieces">
                {numsPiecesFinaux.map((num, index) => {
                    return <span className={`jeuGame__piece jeuGame__piece--${index}`}>{num}<br />{lotsFinaux[index]}</span>
                })}
            </div>
        </>;

        this.setState({contentGame: content});
    }

    createChouette() {
        let arrChouPlaceGagnante = [];
        let nbChouettes = 0;
        let placesPossibles = [0,1,2];
        let res = [];

        switch (this.state.lot.slug) {
            case "50e":
                nbChouettes = 3;
                break;
            case "5c":
                nbChouettes = 2;
                break;
            case "250000p":
                nbChouettes = 1;
                break;
        }

        // Choix des places
        for (let i = 1; i <= nbChouettes; i++) {
            let rand = Math.floor(Math.random() * placesPossibles.length);
            arrChouPlaceGagnante.push(placesPossibles[rand]);
            placesPossibles.splice(rand, 1);
        }

        // Attribution chouette ou logo
        for (let i = 0; i < 3; i++) {
            if (arrChouPlaceGagnante.includes(i)) {
                res.push(chouetteWithoutColor);
            } else {
                res.push(logo);
            }
        }

        // Affichage
        let content = <>
            <div className="jeuGame__chouettes">
                {
                    res.map((item, index) => {
                        return <img src={item} alt="Chouette" className={`jeuGame__chouette jeuGame__chouette--${index} jeuGame__chouette--logo`} />
                    })
                }
            </div>
        </>;
        this.setState({contentGame: content});
    }

    createBank() {
        // Initialisation
        const numsCoffres = [];
        const numsLingots = [];
        const lotsCoffres = [];
        const placementCoffrePossible = [0,1,2,3,4,5,6,7,8,9,10,11,12,13];
        const placementLingotPossible = [0,1,2,3,4];
        let placementCoffre = null;
        let placementLingot = null;
        let lotsAff = [<>1 Louis<br />d'or</>, <Credits nb="1000" class="jeuRegles__credits"/>, <Credits nb="500" class="jeuRegles__credits"/>, <Credits nb="250" class="jeuRegles__credits"/>, <Credits nb="100" class="jeuRegles__credits"/>, <Credits nb="50" class="jeuRegles__credits"/>, <Credits nb="40" class="jeuRegles__credits"/>, <Credits nb="30" class="jeuRegles__credits"/>, <Credits nb="20" class="jeuRegles__credits"/>, <Credits nb="10" class="jeuRegles__credits"/>, <Credits nb="9" class="jeuRegles__credits"/>, <Credits nb="8" class="jeuRegles__credits"/>, <Credits nb="7" class="jeuRegles__credits"/>, <Credits nb="6" class="jeuRegles__credits"/>, <Credits nb="5" class="jeuRegles__credits"/>];
        let lots = {
            "1l": <>1 Louis<br />d'or</>,
            "1000c": <Credits nb="1000" class="jeuRegles__credits" />,
            "500c": <Credits nb="500" class="jeuRegles__credits" />,
            "250c": <Credits nb="250" class="jeuRegles__credits" />,
            "100c": <Credits nb="100" class="jeuRegles__credits" />,
            "50c": <Credits nb="50" class="jeuRegles__credits" />,
            "40c": <Credits nb="40" class="jeuRegles__credits" />,
            "30c": <Credits nb="30" class="jeuRegles__credits" />,
            "20c": <Credits nb="20" class="jeuRegles__credits" />,
            "10c": <Credits nb="10" class="jeuRegles__credits" />,
            "9c": <Credits nb="9" class="jeuRegles__credits" />,
            "8c": <Credits nb="8" class="jeuRegles__credits" />,
            "7c": <Credits nb="7" class="jeuRegles__credits" />,
            "6c": <Credits nb="6" class="jeuRegles__credits" />,
            "5c": <Credits nb="5" class="jeuRegles__credits" />,
        }
        let numerosCoffres = [];
        let numerosLingots = [];
        for (let i = 1; i<=50; i++) {
            numerosCoffres.push(i);
            numerosLingots.push(i);
        }
        let numWin = null;

        // Si le lot est gagnant
        if (this.state.lot.slug != "bred") {
            // Trouver le numéro gagnant
            let rand = Math.floor(Math.random() * numerosCoffres.length);
            numWin = numerosCoffres[rand];
            numerosCoffres.splice(rand, 1);
            numerosLingots.splice(rand, 1);

            // Choisir la place du lingot et du coffre
            rand = Math.floor(Math.random() * placementCoffrePossible.length);
            placementCoffre = placementCoffrePossible[rand];
            rand = Math.floor(Math.random() * placementLingotPossible.length);
            placementLingot = placementLingotPossible[rand];
        }

        // Trouver les autres numéros
        // Coffres
        for (let i = 0; i < 14; i++) {
            if (placementCoffre != null && i == placementCoffre) {
                numsCoffres.push(numWin);
                lotsCoffres.push(lots[this.state.lot.slug]);
            } else {
                let lot = lotsAff[Math.floor(Math.random() * lotsAff.length)];
                let rand = Math.floor(Math.random() * numerosCoffres.length);
                numsCoffres.push(numerosCoffres[rand]);
                lotsCoffres.push(lot);
                numerosCoffres.splice(rand, 1);
                numerosLingots.splice(rand, 1);
            }
        }
        // Lingots
        for (let i = 0; i < 5; i++) {
            if (placementLingot != null && i == placementLingot) {
                numsLingots.push(numWin);
            } else {
                let rand = Math.floor(Math.random() * numerosLingots.length);
                numsLingots.push(numerosLingots[rand]);
                numerosCoffres.splice(rand, 1);
                numerosLingots.splice(rand, 1);
            }
        }

        // Affichage
        let content = <>
            <div className="jeuGame__lingots">
                {
                    numsLingots.map((item, index) => {
                        return <span className={`jeuGame__lingot jeuGame__lingot--${index} ${placementLingot != null && placementLingot == index ? 'jeuGame__lingot--win' : ''}`}>{item}</span>
                    })
                }
            </div>
            <div className="jeuGame__coffres">
                {
                    numsCoffres.map((item, index) => {
                        return <span className={`jeuGame__coffre jeuGame__coffre--${index} ${placementCoffre != null && placementCoffre == index ? 'jeuGame__coffre--win' : ''}`}>{item}<br /><span className="jeuGame__coffre__lot">{lotsCoffres[index]}</span></span>
                    })
                }
            </div>
        </>;

        this.setState({contentGame: content});
    }

    createBesace() {
        // Initialisation
        let nbCredits = this.state.lot.lot.split(' ')[0];
        let tempArr = [];
        for (let i = 0; i < nbCredits; i++) {
            tempArr.push(i);
        }

        let content = <div class="jeuGame__besace">
            {
                tempArr.map(item => {
                    return <img src={creditImg} alt="Crédit" className={`jeuGame__besace__credit jeuGame__besace__credit--${item}`}/>
                })
            }
        </div>;

        this.setState({contentGame: content});
    }

    endGame() {
        if (this.state.lot.slug == "bred") {
            alert("Vous n'avez rien gagné lors de cette partie. Retentez votre chance !");
            window.location.reload();
        } else {
            if (this.state.bigWin) {
                this.setState({
                    alert: {
                        active: true,
                        type: 'success',
                        title: 'Gros gains !!',
                        msg: "Vous venez de gagner le gros lot de ce jeu ! Félicitation ! Veuillez contacter Matt pour le recevoir en utilisant le code MATOF"
                    }
                })
            } else {
                // Affichage du composant de win
                this.setState({activeWin: true})
            }
        }
    }

    handleClose() {
        window.location.reload();
    }

    getWidth() {
        let tempWidth = window.innerWidth;
        // Si nous sommes sur PC (ou mode paysage)
        if (window.innerWidth > window.innerHeight) {
            if (this.props.jeu.datas.width > this.props.jeu.datas.height) {
                if (this.props.jeu.datas.width > window.innerWidth) {
                    tempWidth = window.innerWidth;
                } else {
                    tempWidth = this.props.jeu.datas.width;
                }
            } else {
                if (this.props.jeu.datas.height > window.innerHeight) {
                    tempWidth = (window.innerHeight / this.props.jeu.datas.height) * this.props.jeu.datas.width;
                } else {
                    tempWidth = this.props.jeu.datas.width;
                }
            }
        }
        return tempWidth;
    }

    getHeight() {
        let tempHeight = (window.innerWidth / this.props.jeu.datas.width) * this.props.jeu.datas.height;
        // Si nous sommes sur PC (ou mode paysage)
        if (window.innerWidth > window.innerHeight) {
            if (this.props.jeu.datas.width > this.props.jeu.datas.height) {
                if (this.props.jeu.datas.width > window.innerWidth) {
                    tempHeight = (window.innerWidth / this.props.jeu.datas.width);
                } else {
                    tempHeight = this.props.jeu.datas.height;
                }
            } else {
                if (this.props.jeu.datas.height > window.innerHeight) {
                    tempHeight = window.innerHeight;
                } else {
                    tempHeight = this.props.jeu.datas.height;
                }
            }
        }
        return tempHeight;
    }

    render() {
        return localStorage.userId == undefined ?
            <h3 className="page__empty">Vous devez vous connecter pour accéder aux jeux</h3> :
            <div className="content">
            {
                this.state.alert.active ?
                    <Alert class={`alert--${this.state.alert.type}`} ttl={this.state.alert.title}>{this.state.alert.msg}</Alert> : ""
            }
            {
                this.props.jeu &&
                this.props.jeu.datas != null &&
                <main className="pagejeu">
                    <div className="pagejeu__banniere" style={{backgroundImage: `url('${this.props.jeu.datas.banniere}')`}}>
                        <Credit nb={this.props.jeu.datas.nbcredits} class="pagejeu__credits"/>
                    </div>
                    <section className="pagejeu__container">
                        <div className="pagejeu__info">
                            <button className="btn" onClick={() => {this.setState({active: true}); document.querySelector('body').classList.add('dontscroll')}}>Jouer</button>
                            <p className="pagejeu__desc">
                                {this.props.jeu.datas.description}
                            </p>
                        </div>
                        <table className="pagejeu__table">
                            <thead className="pagejeu__thead">
                                <tr className="pagejeu__tr">
                                    <th className="pagejeu__th">
                                        Lots
                                    </th>
                                    <th className="pagejeu__th">
                                        Chances sur {this.props.jeu.datas.chance != null ? JSON.parse(this.props.jeu.datas.chance).nbchance : '???'}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="pagejeu__tbody">
                                {
                                    this.props.jeu.datas.chance != null ? 
                                        JSON.parse(this.props.jeu.datas.chance).probas.map(chance => {
                                            return <tr className="pagejeu__tr">
                                                <th className="pagejeu__th">
                                                    {chance.lot}
                                                </th>
                                                <td className="pagejeu__td">
                                                    {chance.chance}
                                                </td>
                                            </tr>
                                        }) : <tr className="pagejeu__tr"><h2 className="page__empty">Les probabilités ne sont pas calculables pour ce jeu</h2></tr>
                                }
                            </tbody>
                        </table>
                    </section>
                    {
                        this.state.active &&
                        (this.props.jeu.datas.slug == "lotowin" ?
                            <LotoWin /> :
                            (
                                <section className="pagejeu__fond">
                                    {
                                        this.state.activeRegle &&
                                        <div className="jeuRegles" style={{width: this.state.width, height: this.state.height, left: ((window.innerWidth - this.state.width) / 2), top: 0}}>
                                            <img src={`${getDomain()}image/jeux/${this.props.jeu.datas.slug}/regles.png`} alt="Fond des règles du jeu" className="jeuRegles__img" />
                                            {regles[this.props.jeu.datas.slug]}
                                            <div class="jeuRegles__btns">
                                                <button class="jeuGame__btnClose" style={{width: 40, height: 40}} onClick={() => window.location.reload()}>
                                                    <IcomoonReact iconSet={iconSet} icon="cross" />
                                                </button>
                                            </div>
                                            <button class="btn jeuRegles__btn" onClick={() => this.handleStartJeu()}>
                                                Jouer<Credits nb={this.props.jeu.datas.nbcredits} class="jeuRegles__credits" />
                                            </button>
                                        </div>
                                    }
                                    <div
                                        className={`jeuGame ${this.state.activeJeu && "jeuGame--active"}`}
                                        style={{width: this.state.width, height: this.state.height, top: 0, left: ((window.innerWidth - this.state.width) / 2)}}
                                    >
                                        <ScratchCard
                                            width={this.state.width}
                                            height={this.state.height}
                                            image={
                                                (this.props.jeu.datas.slug == "lila" || this.props.jeu.datas.slug == "malo" || this.props.jeu.datas.slug == "keryan") ? couronne : (
                                                    this.props.jeu.datas.slug == "onze" ? onze : (
                                                        this.props.jeu.datas.slug == "x11" ? x11 :  (
                                                            this.props.jeu.datas.slug == "chouette" ? chouette : (
                                                                this.props.jeu.datas.slug == "bank" ? bank : (
                                                                    this.props.jeu.datas.slug == "besace" ? besace : ""
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            }
                                            finishPercent={97}
                                            onComplete={() => this.endGame()}
                                        >
                                            <img src={this.props.jeu.datas.fond} alt={`Image de fond du jeu ${this.props.jeu.datas.titre}`} className="jeuGame__img" />
                                            {this.state.contentGame}
                                            <div className="jeuGame__btns">
                                                <button class="jeuGame__btnClose" style={{width: 40, height: 40}}>
                                                    <IcomoonReact iconSet={iconSet} icon="cross" />
                                                </button>
                                            </div>
                                        </ScratchCard>
                                    </div>
                                </section>
                            ))
                    }
                </main>
            }
            {
                this.state.activeWin &&
                <Win lot={this.state.lot.lot} handleClose={() => this.handleClose()} />
            }
            {
                this.state.alert.active &&
                this.state.active &&
                <div className={`alertJeu alertJeu--${this.state.alert.type}`}>
                    <h2 className="alertJeu__ttl">{this.state.alert.title}</h2>
                    <p className="alertJeu__desc">{this.state.alert.msg}</p>
                </div>

            }
        </div>
    }
}

const mapStateToProps = state => {
    return {
        jeu: state.jeu,
        removecred: state.removecred,
        setgains: state.setgains,
        verifcredit: state.verifcredit
    }
};

const mapDispatchToProps = {
    getJeu,
    setRemoveCredits,
    setGains,
    verifCredits
}

export default connect(mapStateToProps, mapDispatchToProps)(Jeu);