import React from "react";
import { setBingoGrille } from "../../actions/bingo"; 
import { connect } from "react-redux";
import Alert from "../Alert";

class BingoModal extends React.Component {
    state = {
        alert: {
            active: false,
            type: '',
            title: '',
            msg: ''
        },
        num1: null,
        num2: null,
        num3: null,
        num4: null,
        num5: null,
        num6: null,
        num7: null,
        num8: null,
        num9: null,
        num10: null,
        num11: null,
    }

    componentDidMount() {
        if (this.props.numeros.length > 0) {
            this.setState({
                num1: this.props.numeros[0],
                num2: this.props.numeros[1],
                num3: this.props.numeros[2],
                num4: this.props.numeros[3],
                num5: this.props.numeros[4],
                num6: this.props.numeros[5],
                num7: this.props.numeros[6],
                num8: this.props.numeros[7],
                num9: this.props.numeros[8],
                num10: this.props.numeros[9],
                num11: this.props.numeros[10],
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.bingosetgrille != undefined &&
            this.props.bingosetgrille != prevProps.bingosetgrille &&
            this.props.bingosetgrille.alert != this.state.alert
        ) {
            this.setState({
                alert: this.props.bingosetgrille.alert
            })
        }
    } 

    handleSave = () => {
        this.setState({
            alert: {
                active: true,
                type: 'warning',
                title: 'Analyse des données',
                msg: "Analyse de vos numéros en cours..."
            }
        })

        this.props.setBingoGrille({
            num1: this.state.num1,
            num2: this.state.num2,
            num3: this.state.num3,
            num4: this.state.num4,
            num5: this.state.num5,
            num6: this.state.num6,
            num7: this.state.num7,
            num8: this.state.num8,
            num9: this.state.num9,
            num10: this.state.num10,
            num11: this.state.num11,
        })
    }

    render() {
        let nums = [];
        for (let i = 1; i<= 11; i++) {
            nums.push(i);
        }

        return <div className="bingo__modal">
            <div className="bingo__modalcontent">
                <h3 className="bingo__modalttl">Choisissez les numéros de votre grille</h3>
                {
                    this.state.alert.active ?
                        <Alert class={`alert--${this.state.alert.type}`} ttl={this.state.alert.title}>{this.state.alert.msg}</Alert> : ""
                }
                <div className="bingo__modalnums">
                    <input
                        type="number"
                        className="bingo__modalnum"
                        placeholder="XX"
                        name={`num1`}
                        id={`num1`}
                        value={this.state.num1}
                        onChange={(el) => this.setState({num1: el.target.value})}
                    />
                    <input
                        type="number"
                        className="bingo__modalnum"
                        placeholder="XX"
                        name={`num2`}
                        id={`num2`}
                        value={this.state.num2}
                        onChange={(el) => this.setState({num2: el.target.value})}
                    />
                    <input
                        type="number"
                        className="bingo__modalnum"
                        placeholder="XX"
                        name={`num3`}
                        id={`num3`}
                        value={this.state.num3}
                        onChange={(el) => this.setState({num3: el.target.value})}
                    />
                    <input
                        type="number"
                        className="bingo__modalnum"
                        placeholder="XX"
                        name={`num4`}
                        id={`num4`}
                        value={this.state.num4}
                        onChange={(el) => this.setState({num4: el.target.value})}
                    />
                    <input
                        type="number"
                        className="bingo__modalnum"
                        placeholder="XX"
                        name={`num5`}
                        id={`num5`}
                        value={this.state.num5}
                        onChange={(el) => this.setState({num5: el.target.value})}
                    />
                    <input
                        type="number"
                        className="bingo__modalnum"
                        placeholder="XX"
                        name={`num6`}
                        id={`num6`}
                        value={this.state.num6}
                        onChange={(el) => this.setState({num6: el.target.value})}
                    />
                    <input
                        type="number"
                        className="bingo__modalnum"
                        placeholder="XX"
                        name={`num7`}
                        id={`num7`}
                        value={this.state.num7}
                        onChange={(el) => this.setState({num7: el.target.value})}
                    />
                    <input
                        type="number"
                        className="bingo__modalnum"
                        placeholder="XX"
                        name={`num8`}
                        id={`num8`}
                        value={this.state.num8}
                        onChange={(el) => this.setState({num8: el.target.value})}
                    />
                    <input
                        type="number"
                        className="bingo__modalnum"
                        placeholder="XX"
                        name={`num9`}
                        id={`num9`}
                        value={this.state.num9}
                        onChange={(el) => this.setState({num9: el.target.value})}
                    />
                    <input
                        type="number"
                        className="bingo__modalnum"
                        placeholder="XX"
                        name={`num10`}
                        id={`num10`}
                        value={this.state.num10}
                        onChange={(el) => this.setState({num10: el.target.value})}
                    />
                    <input
                        type="number"
                        className="bingo__modalnum"
                        placeholder="XX"
                        name={`num11`}
                        id={`num11`}
                        value={this.state.num11}
                        onChange={(el) => this.setState({num11: el.target.value})}
                    />
                </div>
                <div className="bingo__modallistbtn">
                    <button type="button" className="bingo__btn" onClick={() => this.props.handleCloseModal()}>Fermer</button>
                    <button type="button" className="bingo__btn bingo__btn--save" onClick={() => this.handleSave()}>Enregistrer</button>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        bingosetgrille: state.bingosetgrille
    }
}

const mapDispatchToProps = {
    setBingoGrille
}

export default connect(mapStateToProps, mapDispatchToProps)(BingoModal);