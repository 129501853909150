import { FORGOT_PASSWORD, SET_AUTHENTICATION } from "../actions/action-types";

const initialState = {
    alert: {
        active: false,
        type: null,
        title: null,
        msg: null
    },
    isLoggin: false
}

export function AuthenticationReducer (state = initialState, action) {
    switch (action.type) {
        case SET_AUTHENTICATION:
            return {
                alert: action.payload.alert,
                isLoggin: action.payload.isLoggin
            }
        default:
            return state;
    }
}

export function ForgotReducer(state = {
    alert: {
        active: false,
        type: null,
        title: null,
        msg: null
    },
    step: 1
}, action) {
    switch (action.type) {
        case FORGOT_PASSWORD:
            return {
                alert: action.payload.alert,
                step: action.payload.step
            }
        default:
            return state;
    }
}