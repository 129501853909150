import React from "react";
import iconSet from "../../selection.json";
import IcomoonReact from "icomoon-react";
import Credit from "../Credits";
import { getLwShop, achatLwGrille } from "../../actions/lotowin";
import { connect } from "react-redux";

class LWBoutique extends React.Component {
    state = {
        shop: [],
        alert: {
            active: false,
            type: '',
            title: '',
            msg: ''
        },
        isSending: false,
    }

    componentDidMount() {
        this.props.getLwShop();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.shop != undefined &&
            this.props.shop.datas != this.state.shop
        ) {
            this.setState({shop: this.props.shop.datas});
        }

        if (
            this.props.shopAdd != undefined &&
            this.props.shopAdd.alert.active != this.state.alert.active &&
            this.state.isSending
        ) {
            this.setState({
                alert: this.props.shopAdd.alert,
                isSending: false
            });
            setTimeout(() => {
                this.setState({alert: {
                    active: false,
                    type: this.state.alert.type,
                    title: this.state.alert.title,
                    msg: this.state.alert.msg,
                }})
                this.props.researchNb();
            }, 3000);
        }
    } 

    render() {
        return <section className="lw__mod lw__bo">
            <h2 className={`lw__fg__ttl`}>
                La boutique
            </h2>
            <div className="lw__bo__list">
                {
                    this.state.shop.map(produit => <button
                            className={`lw__bo__item lw__bo__item--${produit.type}`}
                            onClick={() => {
                                this.setState({isSending: true});
                                this.props.achatLwGrille({
                                    shopId: produit.id,
                                    type: produit.type
                                })
                            }}
                        >
                        <span className="lw__bo__grille">
                            {produit.nb}
                            <IcomoonReact iconSet={iconSet} icon="table2" />
                            {produit.txt}
                        </span>
                        <Credit nb={produit.cred} class={`lw__bo__credit lw__bo__credit--${produit.type}`} />
                    </button>)
                }
            </div>
            {
                this.state.alert.active ?
                    <div className="lw__ti__mod">
                        <h3 className="lw__ti__modttl">{this.state.alert.msg}</h3>
                    </div>
                    : ""
            }
        </section>
    }
}

const mapStateToProps = state => {
    return {
        shop: state.shop,
        shopAdd: state.shopAdd
    }
}

const mapDispatchToProps = {
    getLwShop,
    achatLwGrille
}

export default connect(mapStateToProps, mapDispatchToProps)(LWBoutique);