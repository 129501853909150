import React from "react";
import { getForum, sendMessage, deleteMessage } from "../../actions/tchat";
import { connect } from "react-redux";
import iconSet from "../../selection.json";
import IcomoonReact from "icomoon-react";
import Message from "./Message";

class Forum extends React.Component {
    state = {
        messages: [],
        message: '',
        nextSend: false,
        isSending: false,
        nextdel: false
    }

    componentDidMount() {
        this.props.handleChangeAlert({
            active: true,
            type: 'warning',
            title: 'Recherche des messages du forum...',
            msg: ''
        });
        this.props.getForum()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.tchforum != undefined) {
            if (this.props.tchforum.alert != prevProps.tchforum.alert) {
                this.props.handleChangeAlert(this.props.tchforum.alert);
            }
            if (this.props.tchforum.datas != this.state.messages) {
                console.log("====")
                this.setState({
                    messages: this.props.tchforum.datas,
                    nextSend: false,
                    nextdel: false,
                    message: ''
                })
            }
        }

        if (this.props.tchdel != undefined) {
            if (this.props.tchdel.alert != prevProps.tchdel.alert) {
                this.props.handleChangeAlert(this.props.tchdel.alert);
            }
            if (this.props.tchdel.next != this.state.nextdel && prevProps.tchdel.next != this.props.tchdel.next) {
                this.setState({
                    nextdel: this.props.tchdel.next
                })
                this.props.getForum();
            }
        }

        if (
            this.props.tchsendmess != undefined &&
            this.state.isSending
        ) {
            if (this.props.tchsendmess.alert != prevProps.tchsendmess.alert) {
                this.props.handleChangeAlert(this.props.tchsendmess.alert);
            }
            if (
                this.props.tchsendmess.next != this.state.nextSend && 
                this.state.nextSend == false
            ) {
                this.setState({nextSend: true, message: '', isSending: false});
                this.props.getForum();
            }
        }
    } 

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.message != '') {
            this.props.handleChangeAlert({
                active: true,
                type: 'warning',
                title: 'Envoi du message en cours...',
                msg: ''
            });
            this.setState({isSending: true});
            this.props.sendMessage({message: this.state.message, salon: null})
        } else {
            alert("Veuillez ne pas envoyer de message vide.");
        }
    }

    handleDeleteMessage(id) {
        this.props.deleteMessage({message: id});
    }

    render() {
        return <main className="tchat__tchzone">
            {
                this.state.messages.length == 0 ?
                    <h3 className="tchat__empty">Aucun message n'a été envoyé dans cette discussion</h3> :
                    <ul className="tchat__zone">
                        {
                            this.state.messages.map((message, index) => {
                                return <Message
                                    key={`forum-${index}`}
                                    message={message}
                                    user={message.profile}
                                    handleDeleteMessage={(id) => this.handleDeleteMessage(id)}
                                />
                            })
                        }
                    </ul>
            }
            <footer class="tchat__footer">
                <textarea placeholder="Votre message..." class="tchat__txtarea" onChange={e => this.setState({message: e.target.value})} value={this.state.message}/>
                <button class="tchat__btnsend" onClick={e => this.handleSubmit(e)}>
                    <IcomoonReact iconSet={iconSet} icon="paperplane" />
                </button>
            </footer>
        </main>
    }
}

const mapStateToProps = state => {
    return {
        tchforum: state.tchforum,
        tchsendmess: state.tchsendmess,
        tchdel: state.tchdel
    }
}

const mapDispatchToProps = {
    getForum,
    sendMessage,
    deleteMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(Forum);