import React from "react";
import iconSet from "../selection.json";
import IcomoonReact from "icomoon-react";
import { Link } from "react-router-dom";
import Fiche from "../components/Fiche";
import Card from "../components/Card";
import Alert from "../components/Alert";
import { connect } from "react-redux";
import { getDatas } from "../actions/home";
import { getDate } from "../helpers";

class Home extends React.Component {
    state = {
        alert: {
            active: true,
            type: 'warning',
            title: 'Attente...',
            msg: 'Réception des données en cours...'
        },
        slides: []
    };

    componentDidMount() { 
        document.title = 'Tof La Win - Youtubeur de grattage et jeux en ligne rémunérateurs !';
        this.props.getDatas();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.datas != this.props.datas) {
            let slides = [];
            if (this.props.datas.datas.anniv != null) {
                slides.push({
                    left: false,
                    center: true,
                    right: false,
                    txt: this.props.datas.datas.anniv
                });
            }
            this.props.datas.datas.annonce.forEach(annonce => {
                slides.push({
                    left: false,
                    center: false,
                    right: true,
                    txt: annonce.titre
                });
            })
            this.setState({
                alert: this.props.datas.alert,
                slides: slides
            });
        }
    } 

    changeSlide(direction) {
        let tempSlides = this.state.slides;
        let index = null;
        let countSlide = 0;
        while (index == null && countSlide < tempSlides.length) {
            if (tempSlides[countSlide].center) {
                index = countSlide;
            }
            countSlide += 1;
        }

        // Si droite
        if (direction == "droite") {
            // Si il y en a à droite
            if (tempSlides[index + 1] != undefined) {
                // Actuel, gauche
                tempSlides[index].center = false;
                tempSlides[index].left = true;
                tempSlides[index].right = false;
                // Futur, center
                tempSlides[index + 1].center = true;
                tempSlides[index + 1].false = false;
                tempSlides[index + 1].right = false;
            // Si il n'y en a pas à droite
            } else {
                // Premier center
                // Tout à droite
                tempSlides.forEach((slide, indexx) => {
                    if (indexx == 0) {
                        tempSlides[indexx].left = false;
                        tempSlides[indexx].center = true;
                        tempSlides[indexx].right = false;
                    } else {
                        tempSlides[indexx].left = false;
                        tempSlides[indexx].center = false;
                        tempSlides[indexx].right = true;
                    }
                })
            }
        // Si gauche
        } else {
            // Si il y en a à gauche
            if (tempSlides[index - 1] != undefined) {
                // Actuel, droite
                tempSlides[index].left = false;
                tempSlides[index].center = false;
                tempSlides[index].right = true;
                // Futur, center
                tempSlides[index - 1].center = true;
                tempSlides[index - 1].right = false;
                tempSlides[index - 1].left = false;
            // Si il n'y en a pas à gauche
            } else {
                // Dernier center
                // Tout à gauche
                tempSlides.forEach((slide, indexx) => {
                    if (indexx == (tempSlides.length - 1)) {
                        tempSlides[indexx].left = false;
                        tempSlides[indexx].center = true;
                        tempSlides[indexx].right = false;
                    } else {
                        tempSlides[indexx].left = true;
                        tempSlides[indexx].center = false;
                        tempSlides[indexx].right = false;
                    }
                })
            }
        }
        this.setState({slides: tempSlides});
    }

    render() {
        return <div className="content content--home">
            {
                this.state.slides.length == 0 ? "" : 
                    <section className="h__banniere">
                        {
                            this.state.slides.map((slide, index) =>
                                <article key={index} className={`h__banniere__slide ${slide.left && "h__banniere__slide--left"} ${slide.center && "h__banniere__slide--active"}`}>
                                    <h3 className="h__banniere__content">{slide.txt}</h3>
                                </article>
                            )
                        }
                        <div className="h__banniere__control">
                            <button className="h__banniere__btn h__banniere__btn--left" onClick={() => this.changeSlide('gauche')}>
                                <IcomoonReact iconSet={iconSet} icon="circle-left" />
                            </button>
                            <button className="h__banniere__btn h__banniere__btn--right" onClick={() => this.changeSlide('droite')}>
                                <IcomoonReact iconSet={iconSet} icon="circle-right" />
                            </button>
                        </div>
                    </section>
            }
            <section className="h__youtube">
                <h3 className="h__youtube__ttl">Retrouvez <strong>Tof La Win</strong> sur YouTube</h3>
                <div className="h__youtube__list">
                    <a href="https://www.youtube.com/c/TofLaWin" className="h__youtube__chaine">
                        <IcomoonReact iconSet={iconSet} icon="youtube" />
                        <span>Tof La Win</span>
                    </a>
                    <a href="https://www.youtube.com/channel/UCVqO9Dk78VHpvOIPEQ2pjtA" className="h__youtube__chaine">
                        <IcomoonReact iconSet={iconSet} icon="youtube" />
                        <span>La Win Family</span>
                    </a>
                    <a href="https://www.youtube.com/channel/UCoWWWOG4cHp3ERsBP3OaDUQ" className="h__youtube__chaine">
                        <IcomoonReact iconSet={iconSet} icon="youtube" />
                        <span>Tof La Win 11.0</span>
                    </a>
                </div>
            </section>
            <section className="h__derniers">
                {
                    this.props.datas.datas?.video.length > 0 ? 
                        <Card
                            class="h__card"
                            title="Dernière vidéo"
                            subttl={this.props.datas.datas.video[0].titre}
                            date={getDate(this.props.datas.datas.video[0].date.date)}
                            linkimg={`/video/${this.props.datas.datas.video[0].id}`}
                            imglink={this.props.datas.datas.video[0].miniature}
                            img={null}
                            desc={this.props.datas.datas.video[0].description}
                            link={null}
                            txtlink={null}
                        /> : ""
                }
                {
                    this.props.datas.datas?.jeu.length > 0 ? 
                        <Card
                            class="h__card"
                            title="Dernier jeu"
                            subttl={this.props.datas.datas.jeu[0].titre}
                            date={null}
                            linkimg={null}
                            imglink={null}
                            desc={this.props.datas.datas.jeu[0].description}
                            img={this.props.datas.datas.jeu[0].miniature}
                            link={`/jeu-${this.props.datas.datas.jeu[0].id}`}
                            txtlink="Jouer"
                        /> : ""
                }
            </section>
            {
                this.props.datas.datas?.analyse != null ?
                <section className="h__chiffres">
                    <h2 className="h__chiffres__ttl">Quelques chiffres à propos de Tof La Win et du grattage</h2>
                    <div className="h__chiffres__list">
                        <Fiche nb={this.props.datas.datas.analyse.nb_tickets} ttl="Tickets grattés" subttl={null} />
                        <Fiche nb={this.props.datas.datas.analyse.mise} ttl="De mise" subttl={null} />
                        <Fiche nb={this.props.datas.datas.analyse.gains} ttl="De gains" subttl={null} />
                    </div>
                    <Link to="" className="h__chiffres__btn btn">Plus de chiffres</Link>
                </section> : ""
            }
            <section className="h_derniers">
                {
                    this.props.datas.datas?.lotof != null ?
                        <Card
                            class="h__card"
                            title="Dernier Lotof"
                            subttl={`${this.props.datas.datas.lotof.jackpot} en Jackpot !!`}
                            date={getDate(this.props.datas.datas.lotof.date.date)}
                            linkimg={null}
                            imglink={null}
                            img='https://toflawin.fr/static/media/lotof_13mai.df56feabcfa28e7434d9.jpg'
                            desc={null}
                            link={"/lotof"}
                            txtlink={"Voir le tirage"}
                        />
                        : ""
                }
                {
                    this.props.datas.datas?.event != null ?
                        <Card
                            class="h__card"
                            title="Prochain évènement"
                            subttl="Aucun évènement de prévu pour l'instant"
                            date={this.props.datas.datas?.event.eventAt}
                            linkimg={null}
                            imglink={null}
                            img={null}
                            desc={this.props.datas.datas?.event.description}
                            link={null}
                            txtlink={this.props.datas.datas?.event.titre}
                        />
                        : <Card
                            class="h__card"
                            title="Prochain évènement"
                            subttl="Aucun évènement de prévu pour l'instant"
                            date={null}
                            linkimg={null}
                            imglink={null}
                            img={null}
                            desc={null}
                            link={null}
                            txtlink={null}
                        />
                }
            </section>
            <Alert
                class="h__info"
                ttl="Informations"
            >
                Si vous avez le moindre soucis ou problèmes en lien avec le site, n'hésitez pas à contacter <strong>Tof La Win</strong> ou <strong>Matt</strong> (le créateur du site). <br />Bonne visite sur le site !
            </Alert>
            {/** Todo sondage */}
        </div>
    }
}

const mapStateToProps = state => {
    return {
        datas: state.getdatas
    }
};

const mapDispatchToProps = {
    getDatas
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);