import { getDomain, config } from "../helpers";
import { FORGOT_PASSWORD, SET_AUTHENTICATION } from "./action-types";
import axios from "axios";

export function signinUser({ email, password }) {
    return function (dispatch) {
        let isLoggin = false;
        let alert = {
            active: true,
            type: 'warning',
            title: 'Attente...',
            msg: "Analyse des données en cours..."
        };

        axios.post(`${getDomain()}api/login_check`, {
            username: email,
            password: password
        }, config)
            .then(temp => {
                let res = temp.data;
                // Réception du token.
                let cred = res['token'];

                // Si token, on appelle le user (findByEmail, userSucces ?)
                if (cred) {
                    axios.post(`${getDomain()}user/success?email=${email}`, {}, config) 
                        .then(temp2 => {
                            let res2 = temp2.data;
                            // Si le user est bon, on le connecte et on enregistre les données

                            if (res2.code == "success") {
                                axios.post(`${getDomain()}user/findByEmail`, {
                                    credentials: res2.credentials,
                                    email: email
                                }, config)
                                    .then(temp3 => {
                                        let res3 = temp3.data;
                                        console.log(res3);
                                        // Récupérer les infos perso du compte
                                        axios.post(`${getDomain()}users/find/${res3.user}`, {
                                            credentials: res2.credentials,
                                        }, config)
                                            .then(temp4 => {
                                                isLoggin = true;
                                                let res4 = temp4.data;
                                                // Enregistrement des données
                                                localStorage.setItem('userId', res4.id);
                                                localStorage.setItem('credentials', res2.credentials);
                                                localStorage.setItem('username', res4.username);
                                                localStorage.setItem('avatar', res4.avatar);
                                                localStorage.setItem('role', res4.role);
                                                localStorage.setItem('isLoggin', true);
                                                alert = {
                                                    active: true,
                                                    type: 'success',
                                                    title: 'Félicitation',
                                                    msg: "Vous êtes connectés"
                                                };
                                                window.location.href = "/";
                                                dispatch({
                                                    type: SET_AUTHENTICATION,
                                                    payload: {
                                                        alert: alert,
                                                        isLoggin: isLoggin
                                                    }
                                                });
                                            })
                                            .catch(error => {
                                                isLoggin = false;
                                                localStorage.setItem('isLoggin', false);
                                                alert = {
                                                    active: true,
                                                    type: 'error',
                                                    title: 'Erreur',
                                                    msg: "Votre identifiant ou votre mot de passe semble incorrect."
                                                };
                                                console.log(error);
                                                dispatch({
                                                    type: SET_AUTHENTICATION,
                                                    payload: {
                                                        alert: alert,
                                                        isLoggin: isLoggin
                                                    }
                                                });
                                                // Todo une erreur est survenue, veuillez tenter de vous reconnecter. Si le problème persiste, contactez Matt
                                            })
                                    })
                                    .catch(error => {
                                        isLoggin = false;
                                        localStorage.setItem('isLoggin', false);
                                        alert = {
                                            active: true,
                                            type: 'error',
                                            title: 'Erreur',
                                            msg: "Votre identifiant ou votre mot de passe semble incorrect."
                                        };
                                        console.log(error);
                                        dispatch({
                                            type: SET_AUTHENTICATION,
                                            payload: {
                                                alert: alert,
                                                isLoggin: isLoggin
                                            }
                                        });
                                        // Todo une erreur est survenue, veuillez tenter de vous reconnecter. Si le problème persiste, contactez Matt
                                    })
                            } else {
                                isLoggin = false;
                                localStorage.setItem('isLoggin', false);
                                alert = {
                                    active: true,
                                    type: 'error',
                                    title: 'Erreur',
                                    msg: "Votre identifiant ou votre mot de passe semble incorrect."
                                };
                                // Todo une erreur est survenue, veuillez tenter de vous reconnecter. Si le problème persiste, contactez Matt

                            
                                dispatch({
                                    type: SET_AUTHENTICATION,
                                    payload: {
                                        alert: alert,
                                        isLoggin: isLoggin
                                    }
                                });
                            }
                        })
                        .catch(error => {
                            isLoggin = false;
                            localStorage.setItem('isLoggin', false);
                            alert = {
                                active: true,
                                type: 'error',
                                title: 'Erreur',
                                msg: "Votre identifiant ou votre mot de passe semble incorrect."
                            };
                            // Todo une erreur est survenue, veuillez tenter de vous reconnecter. Si le problème persiste, contactez Matt
                            console.log(error);

                            
                            dispatch({
                                type: SET_AUTHENTICATION,
                                payload: {
                                    alert: alert,
                                    isLoggin: isLoggin
                                }
                            });
                        })
                }
            })
            .catch(error => {
                // Todo Identifiants incorrects
                console.log(error);
                alert = {
                    active: true,
                    type: 'error',
                    title: 'Erreur',
                    msg: "Votre identifiant ou votre mot de passe semble incorrect."
                };

                dispatch({
                    type: SET_AUTHENTICATION,
                    payload: {
                        alert: alert,
                        isLoggin: isLoggin
                    }
                });
            })
    }
}