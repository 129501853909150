import React from "react";
import iconSet from "../../selection.json";
import IcomoonReact from "icomoon-react";
import { getLwLots } from "../../actions/lotowin";
import { connect } from "react-redux";
import Credit from "../Credits";

const arr1 = [1, 2, 3, 4, 5];
const arr2 = [1, 2, 3];
const types = ["super", "maxi", "mega", "extra"];

class LWLots extends React.Component {
    state = {
        type: 0,
        lots: []
    }

    componentDidMount() 
    {
        this.props.getLwLots();
    }

    componentDidUpdate(prevProps, prevState) {
        console.log(this.props.lots)
        if (
            this.props.lots != undefined &&
            this.props.lots?.lots != this.state.lots
        ) {
            this.setState({lots: this.props.lots?.lots})
        }
    } 

    handleChangeType = (el) => {
        this.setState({type: el});
    }

    render() {
        return <section className="lw__lo lw__mod">
            <h2 className={`lw__fg__ttl`}>
                Les lots à gagner
            </h2>
            <header className="lw__lo__header">
                {
                    types.map((type, index) => {
                        return <button onClick={() => this.handleChangeType(index)} className={`lw__lo__hdbtn lw__lo__hdbtn--${type} ${this.state.type == index && "lw__lo__hdbtn--active"}`}>
                            <IcomoonReact iconSet={iconSet} icon="gift" />
                        </button>
                    })
                }
            </header>
            <ul className="lw__lo__list">
                {
                    this.state.lots[types[this.state.type]]?.map(lot => {
                        return <li className="lw__lo__item">
                            <span className="lw__lo__txt">
                                {lot.nums}/5 numéros + {lot.bonus}/3 bonus
                            </span>
                            <div className="lw__lo__numeros">
                                {
                                    arr1.map((num, index) => {
                                        return <span className={`lw__lo__num lw__lo__num--${types[this.state.type]} ${index < lot.nums ? ("lw__lo__num--" + types[this.state.type] + "--win") : ""}`}>
                                            {num}
                                        </span>
                                    })
                                }
                                {
                                    arr2.map((num, index) => {
                                        return <span className={`lw__lo__num lw__lo__num--${types[this.state.type]}--bonus ${index < lot.bonus ? ("lw__lo__num--" + types[this.state.type] + "--bonus--win") : ""}`}>
                                            {num}
                                        </span>
                                    })
                                }
                            </div>
                            {
                                (lot.lot.txt != "" && lot.lot.txt != null) ?
                                    <span className={`lw__lo__res`}>
                                        {lot.lot.txt}
                                    </span> :
                                    (lot.lot.goodies == "goodies" ?
                                        <span className={`lw__lo__res`}>
                                            1 Goodies Tof La Win
                                        </span> :
                                        (lot.lot.symb == "table2" ?
                                            <span className={`lw__lo__res lw__lo__res--${lot.lot.class}`}>
                                                {lot.lot.value}
                                                <IcomoonReact iconSet={iconSet} icon="table2" />
                                            </span> :
                                            <Credit nb={lot.lot.value} class={`lw__lo__res lw__lo__res--${lot.lot.class}`}/>
                                        ))
                            }
                        </li>
                    })
                }
            </ul>
        </section>
    }
}

const mapStateToProps = state => {
    return {
        lots: state.lwlots,
    }
}

const mapDispatchToProps = {
    getLwLots
}

export default connect(mapStateToProps, mapDispatchToProps)(LWLots);