import React from "react";

class MentLeg extends React.Component {
    componentDidMount() {
        document.title = 'Tof La Win - Mentions légales';
    }
    render() {
        return <main className="page">
        <h2 className="page__ttl">Mentions légales</h2>
        <section className="page__reglement">
            <p className="page__text">
                Merci de lire avec attention les différentes modalités d’utilisation du présent site avant de parcourir ses pages. En vous connectant sur ce site, vous acceptez sans réserves les présentes modalités. Aussi, conformément à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour la confiance dans l’économie numérique, les responsables du présent site internet 
                <a href="https://toflawin.fr" className="page__link">www.toflawin.fr</a>
                 sont :
            </p>
            <br />
            <h3 className="page__subttl">Éditeur du site:</h3>
            <p className="page__text">
                Responsable éditorial: 
                <strong>Matthew GUIMBRETIERE</strong>
            </p>
            <p className="page__text">
                Email: 
                <strong>m.guimbretiere937@gmail.com</strong>
            </p>
            <p className="page__text">
                Site web: 
                <strong><a href="https://toflawin.fr" className="page__link">www.toflawin.fr</a></strong>
            </p>
            <br />
            <h3 className="page__subttl">HÉBERGEUR:</h3>
            <p className="page__text">
                Infomaniak
            </p>
            <p className="page__text">
                Site web : 
                <strong>www.infomaniak.com</strong>
            </p>
            <br />
            <h3 className="page__subttl">CONDITIONS D’UTILISATION:</h3>
            <p className="page__text">
                Ce site (
                <a href="https://toflawin.fr" className="page__link">www.toflawin.fr</a>
                ) est proposé en différents langages web (HTML, HTML5, Javascript, CSS, etc…). Nous vous recommandons de recourir à des navigateurs modernes comme Internet explorer, Safari, Firefox, Google Chrome, etc…
            </p>
            <p className="page__text">
                Matthew met en œuvre tous les moyens dont il dispose, pour assurer une information fiable et une mise à jour fiable de ses sites internet. Toutefois, des erreurs ou omissions peuvent survenir. L’internaute devra donc s’assurer de l’exactitude des informations auprès de Matthew, et signaler toutes modifications du site qu’il jugerait utile. Matthew n’est en aucun cas responsable de l’utilisation faite de ces informations, et de tout préjudice direct ou indirect pouvant en découler.
            </p>
            <br />
            <h3 className="page__subttl">COOKIES:</h3>
            <p className="page__text">
                Le site 
                <a href="https://toflawin.fr" className="page__link">www.toflawin.fr</a>
                 peut-être amené à vous demander l’acceptation des cookies pour des besoins de statistiques et d’affichage. Un cookie est une information déposée sur votre disque dur par le serveur du site que vous visitez. Il contient plusieurs données qui sont stockées sur votre ordinateur dans un simple fichier texte auquel un serveur accède pour lire et enregistrer des informations. Certaines parties de ce site ne peuvent être fonctionnelles sans l’acceptation de cookies.
            </p>
            <br />
            <h3 className="page__subttl">SERVICES FOURNIS:</h3>
            <p className="page__text">
                L’ensemble des activités de la société ainsi que ses informations sont présentées sur notre site 
                <a href="https://toflawin.fr" className="page__link">www.toflawin.fr</a>
                . Matthew s’efforce de fournir sur le site 
                <a href="https://toflawin.fr" className="page__link">www.toflawin.fr</a>
                 des informations aussi précises que possible. les renseignements figurant sur le site 
                <a href="https://toflawin.fr" className="page__link">www.toflawin.fr</a>
                 ne sont pas exhaustifs et les photos non contractuelles. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne. Par ailleurs, tous les informations indiquées sur le site 
                <a href="https://toflawin.fr" className="page__link">www.toflawin.fr</a>
                 sont données à titre indicatif, et sont susceptibles de changer ou d’évoluer sans préavis.
            </p>
            <br />
            <h3 className="page__subttl">LIMITATION CONTRACTUELLES SUR LES DONNÉES:</h3>
            <p className="page__text">
                Les informations contenues sur ce site sont aussi précises que possible et le site remis à jour à différentes périodes de l’année, mais peut toutefois contenir des inexactitudes ou des omissions. Si vous constatez une lacune, erreur ou ce qui parait être un dysfonctionnement, merci de bien vouloir le signaler par email, à l’adresse m.guimbretiere937@gmail.com, en décrivant le problème de la manière la plus précise possible (page posant problème, type d’ordinateur et de navigateur utilisé, …). Tout contenu téléchargé se fait aux risques et périls de l’utilisateur et sous sa seule responsabilité. En conséquence, ne saurait être tenu responsable d’un quelconque dommage subi par l’ordinateur de l’utilisateur ou d’une quelconque perte de données consécutives au téléchargement. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour Les liens hypertextes mis en place dans le cadre du présent site internet en direction d’autres ressources présentes sur le réseau Internet ne sauraient engager la responsabilité de Tof la win ou Matthew.
            </p>
            <br />
            <h3 className="page__subttl">PROPRIÉTÉ INTELLECTUELLE:</h3>
            <p className="page__text">
                Tout le contenu présent sur le site 
                <a href="https://toflawin.fr" className="page__link">www.toflawin.fr</a>
                , incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la société à l’exception des marques, logos ou contenus appartenant à d’autres sociétés partenaires ou auteurs. Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l’accord exprès par écrit de Matthew ou Tof la win. Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle. Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur. En outre, les propriétaires des Contenus copiés pourraient intenter une action en justice à votre encontre.
            </p>
            <br />
            <h3 className="page__subttl">DÉCLARATION À LA CNIL:</h3>
            <p className="page__text">
                Conformément à la loi 78-17 du 6 janvier 1978 (modifiée par la loi 2004-801 du 6 août 2004 relative à la protection des personnes physiques à l’égard des traitements de données à caractère personnel) relative à l’informatique, aux fichiers et aux libertés, ce site n’a pas fait l’objet d’une déclaration auprès de la Commission nationale de l’informatique et des libertés (www.cnil.fr).
            </p>
            <br />
            <h3 className="page__subttl">LITIGES:</h3>
            <p className="page__text">
                Les présentes conditions du site 
                <a href="https://toflawin.fr" className="page__link">www.toflawin.fr</a>
                 sont régies par les lois françaises et toute contestation ou litiges qui pourraient naître de l’interprétation ou de l’exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le siège social de la société. La langue de référence, pour le règlement de contentieux éventuels, est le français.
            </p>
            <br />
            <h3 className="page__subttl">JEUX ET CONCOURS:</h3>
            <p className="page__text">
                Certains jeux et concours présents sur le site permettent de pouvoir gagner des points, des crédits ainsi que des gros lots tel que des bons d'achats, des mugs, des t-shirts... Aucun jeu n'est et ne sera payant avec de l'argent réel. Cependant, la plupart des jeux peuvent demander l'utilisation de crédits (monnaie virtuelle du site). Toute triche et toute tentative de triche peut entraîner un bannissement définitif du compte de l'utilisateur ayant triché, ce qui engendra une perte totale de tout ses gains.
            </p>
            <br />
            <h3 className="page__subttl">DONNÉES PERSONNELLES:</h3>
            <p className="page__text">
                De manière générale, vous n’êtes pas tenu de nous communiquer vos données personnelles lorsque vous visitez notre site Internet 
                <a href="https://toflawin.fr" className="page__link">www.toflawin.fr</a>
                . Cependant, ce principe comporte certaines exceptions. En effet, pour certains services proposés par notre site, vous pouvez être amenés à nous communiquer certaines données telles que : votre nom, votre adresse électronique, et votre numéro de téléphone. Tel est le cas lorsque vous remplissez le formulaire qui vous est proposé en ligne, dans la rubrique « contact ». Dans tous les cas, vous pouvez refuser de fournir vos données personnelles. Dans ce cas, vous ne pourrez pas utiliser les services du site, notamment celui de participer au lotof, au bingo ou au jeux. Enfin, nous pouvons collecter de manière automatique certaines informations vous concernant lors d’une simple navigation sur notre site Internet, notamment : des informations concernant l’utilisation de notre site, comme les zones que vous visitez et les services auxquels vous accédez, votre adresse IP, le type de votre navigateur, vos temps d’accès. De telles informations sont utilisées exclusivement à des fins de statistiques internes, de manière à améliorer la qualité des services qui vous sont proposés. Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.
            </p>
        </section>
    </main>
    
    }
}

export default MentLeg;