import * as React from 'react';
import { connect } from 'react-redux';
import { signinUser } from '../../actions/registration';
import { Link } from "react-router-dom";
import Alert from '../../components/Alert';
import { sendMdpCode, verifMdpCode, setMdpMdp, setNewUser } from '../../actions/login';

class Login extends React.Component {
    state = {
        email: "",
        password: "",
        mdpCode: "",
        mdpEmail: "",
        mdpMdp: "",
        mdpConfirm: "",
        alert: {
            active: false,
            type: null,
            msg: null,
            title: null
        },
        step: 2,
        iPseudo: "",
        iMail: "",
        iMailConf: "",
        iMdp: "",
        iMdpConf: "",
        iAnniv: "",
        iPolConf: false,
        iNews: false,
        nextmdpsendcode: false,
        nextmdpverifcode: false,
        nextmdpnewcode: false,
        nextaddnewuser: false
    }

    componentDidMount() {
        document.title = "Tof La Win - Connexion";
/*
        if (localStorage.getItem('credentials') != undefined && localStorage.getItem('credentials') != '' && localStorage.getItem('credentials') != null) {
            this.props.history.push("/");
        }
        */
    }

    componentDidUpdate(prevProps, prevState) {
        // Réception donnée suite envoi code mdp
        if (
            this.props.mdpsendcode != undefined &&
            this.props.mdpsendcode.next != undefined &&
            this.state.nextmdpsendcode != true &&
            (
                this.props.mdpsendcode.next != this.state.nextmdpsendcode ||
                this.props.mdpsendcode.alert != this.state.alert
            )
        ) {
            if (this.props.mdpsendcode.next) {
                this.setState({
                    alert: this.props.mdpsendcode.alert,
                    nextmdpsendcode: this.props.mdpsendcode.next,
                    step: 4
                });
            } else {
                if (this.props.mdpsendcode.alert.active) {
                    this.setState({
                        alert: this.props.mdpsendcode.alert
                    });
                }
            }
        }

        // Réception donnée suite vérif code mdp
        if (
            this.props.mdpverifcode != undefined &&
            this.props.mdpverifcode.next != undefined &&
            this.state.nextmdpverifcode != true &&
            (
                this.props.mdpverifcode.next != this.state.nextmdpverifcode ||
                this.props.mdpverifcode.alert != this.state.alert
            )
        ) {
            if (this.props.mdpverifcode.next) {
                this.setState({
                    alert: this.props.mdpverifcode.alert,
                    nextmdpverifcode: this.props.mdpverifcode.next,
                    step: 5
                });
            } else {
                if (this.props.mdpverifcode.alert.active) {
                    this.setState({
                        alert: this.props.mdpverifcode.alert
                    });
                }
            }
        }

        // Réception donnée suite modif mdp mdp
        if (
            this.props.mdpnewcode != undefined &&
            this.props.mdpnewcode.next != undefined &&
            this.state.nextmdpnewcode != true &&
            (
                this.props.mdpnewcode.next != this.state.nextmdpnewcode ||
                this.props.mdpnewcode.alert != this.state.alert
            )
        ) {
            if (this.props.mdpnewcode.next) {
                this.setState({
                    alert: this.props.mdpnewcode.alert,
                    nextmdpnewcode: this.props.mdpnewcode.next,
                    mdpCode: "",
                    mdpEmail: "",
                    mdpConfirm: "",
                    mdpMdp: "",
                    step: 2
                });
            } else {
                if (this.props.mdpnewcode.alert.active) {
                    this.setState({
                        alert: this.props.mdpnewcode.alert
                    });
                }
            }
        }

        // Réception donnée suite inscription mdp
        if (
            this.props.addnewuser != undefined &&
            this.props.addnewuser.next != undefined &&
            this.state.nextaddnewuser != true &&
            (
                this.props.addnewuser.next != this.state.nextaddnewuser ||
                this.props.addnewuser.alert != this.state.alert
            )
        ) {
            if (this.props.addnewuser.next) {
                this.setState({
                    alert: this.props.addnewuser.alert,
                    nextaddnewuser: this.props.addnewuser.next,
                    iPseudo: "",
                    iMail: "",
                    iMailConf: "",
                    iMdp: "",
                    iMdpConf: "",
                    iAnniv: "",
                    iPolConf: false,
                    iNews: false,
                    step: 2
                });
            } else {
                if (this.props.addnewuser.alert.active) {
                    this.setState({
                        alert: this.props.addnewuser.alert
                    });
                }
            }
        }
        console.log(this.props.authentication)
        if (
            this.props.authentication != undefined &&
            this.props.authentication != prevProps.authentication &&
            this.props.authentication.alert != this.state.alert
        ) {
            console.log("========")
            console.log(this.props.authentication.alert)
            this.setState({
                alert: this.props.authentication.alert
            })
        }
        
        /*
        if (
            (this.state.stepReg == 1 || this.state.stepReg == 2) &&
            this.props.authentication.alert != this.state.alert &&
            this.props.authentication.alert.active
        ) {
            this.setState({
                alert: this.props.authentication.alert
            })
        }
        if (
            this.state.stepReg == 3 &&
            this.props.forgot.alert != this.state.alert &&
            this.props.forgot.alert.active
        ) {
            this.setState({
                alert: this.props.forgot.alert
            })
        }
        if (
            this.state.stepReg == 3 &&
            this.state.stepmdp == 1 &&
            this.props.forgot.step != this.state.stepmdp
        ) {
            this.setState({
                stepmdp: this.props.forgot.step
            })
        }
        */
    } 

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            alert: {
                active: true,
                type: 'warnin',
                title: 'Attente...',
                msg: "Analyse des données en cours..."
            }
        });
        this.props.signinUser({email: this.state.email, password: this.state.password});
    }

    handleChange = (e) => {
        if (e.target.type == "checkbox") {
            this.setState({
                [e.target.name]: !this.state[e.target.name]
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    }

    handleForgotMdp = (e) => {
        e.preventDefault();

        this.props.sendMdpCode({email: this.state.mdpEmail});
    }

    handleForgotMdpCode = (e) => {
        e.preventDefault();

        this.props.verifMdpCode({ email: this.state.mdpEmail, code: this.state.mdpCode});
    }

    handleForgotMdpSave = (e) => {
        e.preventDefault();

        this.props.setMdpMdp({
            email: this.state.mdpEmail,
            code: this.state.mdpCode,
            mdp: this.state.mdpMdp,
            confMdp: this.state.mdpConfirm
        });
    }

    handleInscription = (e) => {
        e.preventDefault();
        this.setState({
            alert: {
                active: true,
                type: 'warnin',
                title: 'Attente...',
                msg: "Analyse des données en cours..."
            }
        });

        this.props.setNewUser({
            username: this.state.iPseudo,
            email: this.state.iMail,
            confirmEmail: this.state.iMailConf,
            mdp: this.state.iMdp,
            confirmMdp: this.state.iMdpConf,
            anniv: this.state.iAnniv,
            polconf: this.state.iPolConf,
            newsletter: this.state.iNews
        });
    }

    render() {
        return <div className={`login`}>
            {/** ==== Connexion ==== */}
            <div
                className={`
                    login__content
                    ${this.state.step == 1 ? 'login__content--right' : (this.state.step >= 3 ? 'login__content--left' : '')}`}
            >
                <h2 className="login__ttl">Connectez-vous</h2>
                {
                    this.state.alert.active &&
                    <Alert class={`alert--${this.state.alert.type}`} ttl={this.state.alert.title}>{this.state.alert.msg}</Alert>
                }
                <p className="login__help">* champs obligatoires</p>
                <form className="form login__form">
                    <div className="form__group">
                        <label htmlFor="email" className="form__label">Adresse E-mail *</label>
                        <input
                            type="mail"
                            placeholder='exemple@mail.com'
                            id="email"
                            name="email"
                            className="form__input input"
                            onChange={this.handleChange}
                            value={this.state.email}
                        />
                    </div>
                    <div className="form__group">
                        <label htmlFor="password" className="form__label">Mot de passe *</label>
                        <input
                            type="password"
                            placeholder='Mot de passe'
                            id="password"
                            name="password"
                            className="form__input input"
                            onChange={this.handleChange}
                            value={this.state.password}
                        />
                    </div>
                    <button type='submit' className="btn btn--secondary" onClick={this.handleSubmit}>Je me connecte</button>
                </form>
                <div className="login__btns">
                    <button className='btn' onClick={() => this.setState({step: 1})}>S'inscrire</button>
                    <button className="btn btn--link" onClick={() => this.setState({step: 3})}>Mot de passe oublié ?</button>
                </div>
            </div>
            {/** ==== Inscription ==== */}
            <div
                className={`
                    login__content
                    ${this.state.step != 1 ? 'login__content--left' : ''}`}
            >
                <h2 className="login__ttl">Inscrivez-vous</h2>
                {
                    this.state.alert.active &&
                    <Alert class={`alert--${this.state.alert.type}`} ttl={this.state.alert.title}>{this.state.alert.msg}</Alert>
                }
                <p className="login__help">* champs obligatoires</p>
                <form className="form login__form">
                    <div className="form__group">
                        <label htmlFor="iPseudo" className="form__label">Pseudo *</label>
                        <input
                            type="mail"
                            placeholder='exemple@mail.com'
                            id="iPseudo"
                            name="iPseudo"
                            className="form__input input"
                            onChange={this.handleChange}
                            value={this.state.iPseudo}
                        />
                    </div>
                    <div className="form__group">
                        <label htmlFor="iMail" className="form__label">Adresse E-mail *</label>
                        <input
                            type="mail"
                            placeholder='exemple@mail.com'
                            id="iMail"
                            name="iMail"
                            className="form__input input"
                            onChange={this.handleChange}
                            value={this.state.iMail}
                        />
                    </div>
                    <div className="form__group">
                        <label htmlFor="iMailConf" className="form__label">Adresse E-mail *</label>
                        <input
                            type="mail"
                            placeholder='Confirmation mail'
                            id="iMailConf"
                            name="iMailConf"
                            className="form__input input"
                            onChange={this.handleChange}
                            value={this.state.iMailConf}
                        />
                    </div>
                    <div className="form__group">
                        <label htmlFor="iMdp" className="form__label">Mot de passe *</label>
                        <input
                            type="password"
                            placeholder='Mot de passe'
                            id="iMdp"
                            name="iMdp"
                            className="form__input input"
                            onChange={this.handleChange}
                            value={this.state.iMdp}
                        />
                    </div>
                    <div className="form__group">
                        <label htmlFor="iMdpConf" className="form__label">Mot de passe *</label>
                        <input
                            type="password"
                            placeholder='Confirmation mdp'
                            id="iMdpConf"
                            name="iMdpConf"
                            className="form__input input"
                            onChange={this.handleChange}
                            value={this.state.iMdpConf}
                        />
                    </div>
                    <div className="form__group">
                        <label htmlFor="iAnniv" className="form__label">Anniversaire *</label>
                        <input
                            type="date"
                            id="iAnniv"
                            name="iAnniv"
                            className="form__input input"
                            onChange={this.handleChange}
                            value={this.state.iAnniv}
                        />
                    </div>
                    <div className="form__group">
                        <input
                            type="checkbox"
                            name="iPolConf"
                            id="iPolConf"
                            class="form__checkbox"
                            onChange={this.handleChange}
                            value={this.state.iPolConf}
                        />
                        <label for="iPolConf" class="form__help">J'accepte les politiques de confidentialités et les données personnelles</label>
                    </div>
                    <div className="form__group">
                        <input
                            type="checkbox"
                            name="iNews"
                            id="iNews"
                            class="form__checkbox"
                            onChange={this.handleChange}
                            value={this.state.iNews}
                        />
                        <label for="iNews" class="form__help">Je m'abonne à la newsletter</label>
                    </div>
                    <button type='submit' className="btn btn--secondary" onClick={this.handleInscription}>Je m'inscris</button>
                </form>
                <div className="login__btns">
                    <button className='btn' onClick={() => this.setState({step: 2})}>Se connecter</button>
                    <button className="btn btn--link" onClick={() => this.setState({step: 3})}>Mot de passe oublié ?</button>
                </div>
            </div>
            {/** ==== Mot de passe oublié (1/3) ==== */}
            <div
                className={`
                    login__content
                    ${this.state.step <= 2 ? 'login__content--right' : (this.state.step >= 4 ? 'login__content--left' : '')}`}
            >
                <h2 className="login__ttl">Mot de passe oublié</h2>
                {
                    this.state.alert.active &&
                    <Alert class={`alert--${this.state.alert.type}`} ttl={this.state.alert.title}>{this.state.alert.msg}</Alert>
                }
                <p className="login__help">Afin de récupérer votre compte, veuillez indiquer votre adresse mail. Par la suite, un mail vous sera envoyé (l'envoi du mail peut prendre du temps ou le mail peut arriver dans vos spams ou courriers indésirables). Sur ce mail, un code sera écrit, il faudra le rentrer dans la nouvelle zone qui apparaîtra sur ce site, ensuite, si le code est le bon, il vous suffira de modifier votre mot de passe.</p>
                <form className="form login__form">
                    <div className="form__group">
                        <label htmlFor="mdpEmail" className="form__label">Adresse E-mail *</label>
                        <input
                            type="mail"
                            placeholder='exemple@mail.com'
                            id="mdpEmail"
                            name="mdpEmail"
                            className="form__input input"
                            onChange={this.handleChange}
                            value={this.state.mdpEmail}
                        />
                    </div>
                    <button type='submit' className="btn btn--secondary" onClick={this.handleForgotMdp}>Recevoir le code</button>
                </form>
                <div className="login__btns">
                    <button className='btn' onClick={() => this.setState({step: 2})}>Se connecter</button>
                    <button className="btn btn--link" onClick={() => this.setState({step: 1})}>S'inscrire</button>
                </div>
            </div>
            {/** ==== Mot de passe oublié (2/3) ==== */}
            <div
                className={`
                    login__content
                    ${this.state.step <= 3 ? 'login__content--right' : (this.state.step >= 5 ? 'login__content--left' : '')}`}
            >
                <h2 className="login__ttl">Mot de passe oublié</h2>
                {
                    this.state.alert.active &&
                    <Alert class={`alert--${this.state.alert.type}`} ttl={this.state.alert.title}>{this.state.alert.msg}</Alert>
                }
                <p className="login__help">Afin de récupérer votre compte, veuillez indiquer votre adresse mail. Par la suite, un mail vous sera envoyé (l'envoi du mail peut prendre du temps ou le mail peut arriver dans vos spams ou courriers indésirables). Sur ce mail, un code sera écrit, il faudra le rentrer dans la nouvelle zone qui apparaîtra sur ce site, ensuite, si le code est le bon, il vous suffira de modifier votre mot de passe.</p>
                <form className="form login__form">
                    <div className="form__group">
                        <label htmlFor="mdpCode" className="form__label">Code reçu *</label>
                        <input
                            type="text"
                            placeholder='XXXXXX'
                            id="mdpCode"
                            name="mdpCode"
                            className="form__input input"
                            onChange={this.handleChange}
                            value={this.state.mdpCode}
                        />
                    </div>
                    <button type='submit' className="btn btn--secondary" onClick={this.handleForgotMdpCode}>Modifier mon mot de passe</button>
                </form>
                <div className="login__btns">
                    <button className='btn' onClick={() => this.setState({step: 2})}>Se connecter</button>
                    <button className="btn btn--link" onClick={() => this.setState({step: 1})}>S'inscrire</button>
                </div>
            </div>
            {/** ==== Mot de passe oublié (3/3) ==== */}
            <div
                className={`
                    login__content
                    ${this.state.step != 5 ? 'login__content--right' : ''}`}
            >
                <h2 className="login__ttl">Mot de passe oublié</h2>
                {
                    this.state.alert.active &&
                    <Alert class={`alert--${this.state.alert.type}`} ttl={this.state.alert.title}>{this.state.alert.msg}</Alert>
                }
                <p className="login__help">Afin de récupérer votre compte, veuillez indiquer votre adresse mail. Par la suite, un mail vous sera envoyé (l'envoi du mail peut prendre du temps ou le mail peut arriver dans vos spams ou courriers indésirables). Sur ce mail, un code sera écrit, il faudra le rentrer dans la nouvelle zone qui apparaîtra sur ce site, ensuite, si le code est le bon, il vous suffira de modifier votre mot de passe.</p>
                <form className="form login__form">
                    <div className="form__group">
                        <label htmlFor="mdpMdp" className="form__label">Nouveau mot de passe *</label>
                        <input
                            type="password"
                            placeholder='Mot de passe'
                            id="mdpMdp"
                            name="mdpMdp"
                            className="form__input input"
                            onChange={this.handleChange}
                            value={this.state.mdpMdp}
                        />
                    </div>
                    <div className="form__group">
                        <label htmlFor="mdpConfirm" className="form__label">Confirmer mot de passe *</label>
                        <input
                            type="password"
                            placeholder='Confirmation mdp'
                            id="mdpConfirm"
                            name="mdpConfirm"
                            className="form__input input"
                            onChange={this.handleChange}
                            value={this.state.mdpConfirm}
                        />
                    </div>
                    <button type='submit' className="btn btn--secondary" onClick={this.handleForgotMdpSave}>Enregistrer mon mot de passe</button>
                </form>
                <div className="login__btns">
                    <button className='btn' onClick={() => this.setState({step: 2})}>Se connecter</button>
                    <button className="btn btn--link" onClick={() => this.setState({step: 1})}>S'inscrire</button>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        authentication: state.authentication,
        forgot: state.forgot,
        mdpsendcode: state.mdpsendcode,
        mdpverifcode: state.mdpverifcode,
        mdpnewcode: state.mdpnewcode,
        addnewuser: state.addnewuser,
    }
};

const mapDispatchToProps = {
    signinUser,
    sendMdpCode,
    verifMdpCode,
    setMdpMdp,
    setNewUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);